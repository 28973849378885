import React from 'react';
import { getFileUrl } from '../../utils';
import HeaderH1TextItalic from '../../components/common/HeaderH1TextItalic';
import HeaderH1TextNormal from '../../components/common/HeaderH1TextNormal';
import SectionWrapper from '../../components/SectionWrapper';
const CommitmentPromesseSection = ({
  section,
  section3promesses,
  sectionListes,
}) => {
  //

  return section && (
    <div>
      <SectionWrapper hashId={section.hashId}>
        <HeaderH1TextNormal
          text={section.titre_normal}
          isCenter={false}
          shouldBringUp={false}
        />

        <HeaderH1TextItalic
          text={section.titre_italique}
          isCenter={false}
          shouldBringUp={true}
        />
      </SectionWrapper>

      <div className="img-with-text">
        <div className="section-content-wrapper align-text-at-center">
          <img
            className="section-content-img"
            alt=""
            src={getFileUrl(section.desktop_media)}
          />

          <div className="section-content-main align-text-at-center">
            <div className="section-text-content-wrapper">
              <p
                className="section-text-content__p"
                dangerouslySetInnerHTML={{
                  __html: section.description,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>

      <SectionWrapper hashId={section3promesses.hashId}>
        <HeaderH1TextItalic
          text={section3promesses.title}
          isCenter={false}
          shouldBringUp={true}
        />

        <p
          className="subsection-text subsection-text-spe"
          dangerouslySetInnerHTML={{
            __html: section3promesses.description,
          }}
        ></p>
      </SectionWrapper>

      <SectionWrapper
        hashId={sectionListes[0].hashId}
        className="c-accompagnement-with-text"
      >
        <div className="section-content-wrapper align-text-at-center">
          <div className="c-accompagnement-content-main align-text-at-center">
            <div className="c-accompagnement-text-content-wrapper">
              <h3 className="subsection-header">{sectionListes[0].title}</h3>
              <p
                className="subsection-text"
                dangerouslySetInnerHTML={{
                  __html: sectionListes[0].description,
                }}
              ></p>
            </div>
          </div>
          <img
            className="c-accompagnement-content-img"
            alt=""
            src={getFileUrl(sectionListes[0].desktop_media)}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper
        hashId={sectionListes[1].hashId}
        className="depuis-treize-ans-section"
      >
        <div className="section-content-wrapper align-text-at-center">
          <div className="section-content-main align-text-at-center">
            <div className="section-text-content-wrapper">
              <h3 className="subsection-header">{sectionListes[1].title}</h3>
              <p
                className="subsection-text"
                dangerouslySetInnerHTML={{
                  __html: sectionListes[1].description,
                }}
              ></p>
            </div>
          </div>
          <img
            className="depuis-treize-ans-content-img"
            alt=""
            src={getFileUrl(sectionListes[1].desktop_media)}
          />
        </div>
      </SectionWrapper>

      <SectionWrapper
        hashId={sectionListes[2].hashId}
        className="c-accompagnement-with-text"
      >
        <div className="section-content-wrapper align-text-at-center">
          <div className="c-accompagnement-content-main align-text-at-center">
            <div className="c-accompagnement-text-content-wrapper">
              <h3 className="subsection-header">{sectionListes[2].title}</h3>
              <p
                className="subsection-text"
                dangerouslySetInnerHTML={{
                  __html: sectionListes[2].description,
                }}
              ></p>
            </div>
          </div>
          <img
            className="c-accompagnement-content-img"
            alt=""
            src={getFileUrl(sectionListes[2].desktop_media)}
          />
        </div>
      </SectionWrapper>
    </div>
  );
};

export default CommitmentPromesseSection;
