import React from "react";
import "./style.less";

const TitleCollapseTwo = ({normalTitle, italicTitle}) => {
  return (
    <>
      <div className="title-faq2">
        <div>
          <span className="header-h1-text-normal margRight-title">
            {normalTitle}
          </span>

          <span className="header-h1-text-italic colorfaq">{italicTitle}</span>
        </div>
      </div>
    </>
  );
};

export default TitleCollapseTwo;
