import React, { useState } from 'react';
import './style.less';
import Modal from 'react-modal';
import { useCookies } from 'react-cookie';

export default function Parametre({ open, onClose}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [cookies, setCookie] = useCookies();
  const [state, setState] = useState({
    show: open,
  });
  const [parametedCookies, setParametedCookies] = useState(
    cookies._parametreCookies ?? {}
  );
  React.useEffect(() => {
    if (open) setState({ ...state, show: true });
    // 
  }, [open]);

  const { show } = state;
  const handleClose = () => {
    setState({ ...state, show: false });
    onClose && onClose();
  };

  const setCookiesValues = (name, value) => {
    let cookiesValues = { ...parametedCookies };
    cookiesValues[name] = value;
    setParametedCookies(cookiesValues);
  };
  const handleSave = () => {
    setCookie('_parametreCookies', parametedCookies, {
      path: '/',
      maxAge: 30 * 24 * 60 * 60,
    });
    setState({ ...state, show: false });
  };
  return (
    <>
      <Modal isOpen={show} onRequestClose={handleClose}>
        <button id="cookiescript_close-parametre" onClick={handleClose}>
          X
        </button>
        <br />
        <div className=" ads-modal-cookie">
          <div className="cookieText">
            ATTENTION : La performance de notre site repose sur des
            renseignements fournis par des mesures d’audience. En désactivant
            certains cookies, plusieurs fonctionnalités ou conforts
            d’utilisation pourraient être altérées.
          </div>
          <hr />
          <div className="cookieText">
            <h3> Cookies de navigation</h3>
            <p>
              Ces cookies sont nécessaires à l’activité du site. Ils vous
              permettent d'en utiliser les principales fonctionnalités (e. g. :
              l’utilisation du panier d'achat ou l'accès à votre compte). Sans
              ces cookies, vous ne pourriez pas utiliser notre site normalement.
              Ceux-ci ne requièrent pas l’accord préalable de l’internaute pour
              être déposés sur son terminal.
              <div className="custom-control custom-switch">
                <input
                  onChange={(e) => {
                    setCookiesValues('navigation', e.target.checked);
                  }}
                  checked={parametedCookies.navigation}
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitches"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitches"
                >
                  Accepter
                </label>
              </div>
            </p>
          </div>
          <hr />
          <div className="cookieText">
            <h3>Cookies de personnalisation</h3>
            <p>
              Ces cookies nous permettent d’individualiser votre expérience sur
              le site en identifiant les informations relatives à vos centres
              d’intérêt, votre origine de navigation ou encore aux
              fonctionnalités que vous avez sollicitées (e. g. : rappel des
              derniers produits consultés, mémorisation des articles de votre
              panier avant de poursuivre vos achats…).
              <div className="custom-control custom-switch">
                <input
                  onChange={(e) => {
                    setCookiesValues('personnalisation', e.target.checked);
                  }}
                  checked={parametedCookies.personnalisation}
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitches2"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitches2"
                >
                  Accepter
                </label>
              </div>
            </p>
          </div>
          <hr />
          <div className="cookieText">
            <h3>Cookies analytiques</h3>
            <p>
              Ces cookies nous permettent de connaître la nature et les volumes
              d’utilisation et de fréquentation. Il s’agit de mesurer les
              performances réalisées par notre site. Nous sommes ainsi en mesure
              d’améliorer l’intérêt, l’ergonomie et le fonctionnement des
              services proposés (e. g. : les pages les plus souvent consultées,
              les recherches des internautes dans le moteur du site...).
              <div className="custom-control custom-switch">
                <input
                  onChange={(e) => {
                    setCookiesValues('analytique', e.target.checked);
                  }}
                  checked={parametedCookies.analytique}
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitches2"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitches2"
                >
                  Accepter
                </label>
              </div>
            </p>
          </div>
          <hr />
          <div className="cookieText">
            <h3>Cookies publicitaires</h3>
            <p>
              Sur notre site ou à travers des partenaires, ces cookies sont
              destinés à vous présenter des publicités ou à vous adresser des
              informations adaptées à vos centres d'intérêts. Ils sont notamment
              utilisés pour aider à mesurer l'efficacité d'une campagne
              publicitaire.
              <div className="custom-control custom-switch">
                <input
                  onChange={(e) => {
                    setCookiesValues('publicitaire', e.target.checked);
                  }}
                  checked={parametedCookies.publicitaire}
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitches2"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitches2"
                >
                  Accepter
                </label>
              </div>
            </p>
          </div>
          <br />
          <button id="cookiescript_accept" onClick={handleSave}>
            Enregistrer
          </button>
        </div>
      </Modal>
    </>
  );
}
