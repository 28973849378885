import React from 'react';
import { connect } from 'react-redux';
import { buildTempCartApi, getCartUrl } from '../stores/cart/api';
import { getWishlistUrl } from '../stores/wishList/api';
import * as cartActions from '../stores/cart/cartActions';
import * as wishlistActions from '../stores/wishList/actions';
import * as orderActions from '../stores/order/actions';
import useTokenFetch from '../hooks/useTokenFetch';
import useAuthenticated from '../hooks/isAuthenticated';
import * as vitrinAction from '../stores/vitrin/actions';
import { vitrinSelector } from '../stores/vitrin/selector';

const StateDataFetcher = ({ setCart, setWishlist, setProviders, setOrdersHistory, eshopData, setVitrinData, }) => {
  const isAuth = useAuthenticated();
  const CART_URL = getCartUrl();
  const WISHLIST_URL = getWishlistUrl();

  const apiCartData = useTokenFetch(CART_URL, isAuth, {
    refreshInterval: 0,
  });

  const apiWishlistData = useTokenFetch(WISHLIST_URL, isAuth, {
    refreshInterval: 0,
  });
  React.useEffect(() => {
    if (!eshopData)
      setVitrinData({
        endpoints: 'eshop',
        name: ESHOPNAME,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  React.useEffect(() => {
    refreshCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCartData]);

  const getData = async () => {
    if (isAuth) {
      await setProviders();
      setOrdersHistory();
      refreshCart();
      return;
    }

    if (!isAuth) {
      const tempCart = localStorage.getItem('tempCart');

      if (tempCart && tempCart !== null) {
        setTempCart(JSON.parse(tempCart));
      }
      return;
    }
  };

  const refreshCart = () => {
    if (apiCartData && apiCartData !== null) {
      setCart(apiCartData);
    }
  };

  React.useEffect(() => {
    if (apiWishlistData && apiWishlistData !== null) {
      setWishlist(apiWishlistData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiWishlistData]);

  const setTempCart = async (dataTemp) => {
    let data = await buildTempCartApi(dataTemp);
    if (data) await setCart(data);
  };

  return <></>;
};

const Wrapper = connect(
  (state) => ({
    eshopData: vitrinSelector(state, ESHOPNAME),
  }),

  (dispatch) => {
    return {
      setCart: (val) => dispatch(cartActions.setCartContent(val)),
      setWishlist: (val) => dispatch(wishlistActions.setWishlistContent(val)),
      setProviders: (val) => dispatch(orderActions.setShippingProviders(val)),
      setVitrinData: (val) => dispatch(vitrinAction.setVitrinData(val)),
      setOrdersHistory: () => dispatch(orderActions.setOrdersHistoryAction()),
      tempOrderToStore: (val) => dispatch(orderActions.tempOrderToStore(val)),
    };
  }
);

const ESHOPNAME = 'eshop';

export default Wrapper(StateDataFetcher);
