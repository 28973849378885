import React from "react";
import HeaderH1TextItalic from "../HeaderText/HeaderH1TextItalic";
import Img from 'react-cool-img';

import "./imgWithCaptionCard.less";


const ImgWithCaptionCard = ({ image, caption, isSmaller = false }) => {
  return (
    <>
      <div className="img-text-box">
        <Img src={image} />
        <div className="img-text">
          <HeaderH1TextItalic
            text={caption}
            isCenter={true}
            shouldBringUp={true}
            isSmaller={isSmaller}
          />
        </div>
      </div>
    </>
  );
};

export default ImgWithCaptionCard;
