import React from 'react';
import './style.less';

import { NavHashLink } from 'react-router-hash-link';
import HeaderH3TextNormal from '../../HeaderText/HeaderH3TextNormal';
import ArticleBlogCard from '../../ArticleBlogCard/ArticleBlogCard';

import HTMLReactParser from 'html-react-parser';
import { getFileUrl } from '../../../../utils';
import { Col, Row } from 'antd';
import Slider from 'react-slick';
import useWindowSize from '../../../../hooks/useWindowSize';
import { MOBILE_SIZE } from '../../../../config';
import SectionWrapper from '../../../../components/SectionWrapper';

const ArticlesDeBlogSection = ({ data, hashId }) => {
  //

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    arrows: false,
    autoplay: false,
    centerMode: true,
    centerPadding: '16px',
  };

  const displayMobileForma = () => {
    return (
      <Slider {...settings}>
        {data.map((item,index) => (
          <div key={index}>
            <NavHashLink to={`/blogs/${item.slug}/#top`}>
              <ArticleBlogCard
                headerText={`${item.post[0].normal_title} ${item.post[0].italic_title}`}
                image={getFileUrl(item.post[0].mobile_media)}
                footerText={HTMLReactParser(
                  `${data[0].post[0].small_descrition}`
                )}
              />
            </NavHashLink>
          </div>
        ))}
      </Slider>
    );
  };

  const displayWeb = () => (
    <Row
      justify="space-between"
      gutter={[{ xs: 20, sm: 25 }]}
      className="articles-blog-wrapper"
    >
      {data.map((item,index) => (
        <Col key={index} sm={8}>
          <NavHashLink to={`/blogs/${item.slug}/#top`}>
            <ArticleBlogCard
              headerText={`${item.post[0].normal_title} ${item.post[0].italic_title}`}
              image={getFileUrl(item.post[0].mobile_media)}
              footerText={HTMLReactParser(`${item.post[0].small_descrition}`)}
            />
          </NavHashLink>
        </Col>
      ))}
    </Row>
  );

  return (
    <SectionWrapper hashId={hashId}>
      <HeaderH3TextNormal text="ARTICLES DE BLOG" />

      {isMobile ? displayMobileForma() : displayWeb()}
    </SectionWrapper>
  );
};

export default ArticlesDeBlogSection;
