import { BASE_URL } from '../../api/config';
import { getToken, getUsers } from '../../api/utils';
import http from '../../api/http';

export const getWishlistUrl = () => {
  const user = getUsers();

  return user ? `${BASE_URL}/wishlists` : undefined;
};

export const getItemsApi = async (id) => {
  const token = getToken();

  return http
    .get(`/wishlists`, { headers: { Authorization: `Bearer ${token}` } })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const addItemApi = async (id) => {
  const token = getToken();

  return http
    .post(
      `/wishlists/${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const removeItemApi = async (id) => {
  const token = getToken();

  return http
    .delete(`/wishlists/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const removeAllApi = async (id) => {
  const token = getToken();

  return http
    .delete(`/wishlists`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};
