import React from 'react';

import './style.less';
import { Row, Col } from 'antd';
import Pagination from './Pagination';
import styled from 'styled-components';
import Spiner from '../../../components/Spiner';
import { addToWhitelist } from '../../../api/shop';

import ProductCard from '../../../components/shop/ProductCard';

const Products = ({
  category,
  products,
  page,
  filtervalue,
  reloadProductsPage,
}) => {
  //

  const gutter = [
    { sm: 35, xs: 10 },
    { sm: 20, xs: 15 },
  ];

  if (!products) return <Spiner />;

  return (
    <ProductWrapper className="shop-products">
      <Row justify={'flex-start'} gutter={gutter}>
        {products.results.length === 0 ? (
          <div className="empty-list">
            <p>Aucun résultat ne correspond à votre recherche.</p>
          </div>
        ) : (
          products.results.map((item) => (
            <Col key={item.id} xs={12} sm={12} md={8}>
              <ProductCard
                data={item}
                onSelectWhitelist={() => addToWhitelist(item.id)}
                category={category}
              />
            </Col>
          ))
        )}
      </Row>

      <Pagination
        products={products}
        page={page}
        filtervalue={filtervalue}
        reloadProductsPage={reloadProductsPage}
      />
    </ProductWrapper>
  );
};

export default Products;

const ProductWrapper = styled.div`
  width: 100%;
`;
