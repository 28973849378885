import $ from 'jquery';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as Icon } from '../assets/icons/cart.svg';
import styled from 'styled-components';
import Cart from '../components/Cart';

import { cartSelector, openCartSelector } from '../stores/cart/selector';
import * as actions from '../stores/cart/cartActions';

const CartIcon = ({ className, cart, removeFromCart, openCart }) => {
  const [cartToggle, setCartToggle] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    setCartToggle(openCart);
  }, [openCart]);

  React.useEffect(() => {
    setHeight(cartToggle);
  }, [cartToggle]);

  const setHeight = (open) => {
    const ref = document.getElementById('cart');

    if (!ref || ref === null) return;

    if (open) {
      ref.style.height = '100vh';
      ref.style.maxHeight = '1080px';
    } else {
      ref.style.height = '0vh';
    }
  };

  const handleClick = (e) => {
    setCartToggle(!cartToggle);
  };

  const displayBadge = () => {
    const { totalCount, count = 0 } = cart;
    if (count <= 0) return;
    return <Badge className="cart-badge"> {totalCount || count} </Badge>;
  };

  const onCloseCart = () => {
    dispatch(actions.setOpenCart(false));
    setCartToggle(false);
  };

  return (
    <>
      <CartIconWrapper
        onClick={handleClick}
        className={`cartIcon ${className}`}
      >
        <Icon style={{ cursor: 'pointer' }} />
        {cart && displayBadge()}
      </CartIconWrapper>

      <div id="cart-menu">
        <Cart
          onClose={onCloseCart}
          data={cart}
          removeFromCart={removeFromCart}
        />
      </div>
    </>
  );
};

const Badge = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  background: #fff;
  top: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9px;
  left: 9px;

  font-family: GT Eesti Display Trial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
`;

const CartIconWrapper = styled.div`
  margin-right: 10px;
  position: relative;
  display: flex;
  cursor: pointer;
`;

const CartStoreWrapper = connect(
  (state) => ({
    cart: cartSelector(state),
    openCart: openCartSelector(state),
  }),

  (dispatch) => {
    return {
      removeFromCart: (val) => dispatch(actions.removeItemFromCart(val)),
    };
  }
);

export default CartStoreWrapper(CartIcon);
