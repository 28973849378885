import React from 'react';
import { Row, Col } from 'antd';
import { useEffect } from 'react';
import { Form, Checkbox } from 'antd';
import { NavHashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';

import useWindowSize from '../../../hooks/useWindowSize';
import Informations from '../Informations/informations';
import Confirmation from '../Confirmation/confirmation';
import ResumeCommande from '../ResumeCommande/resumeCommande';
import LivraisonField from './LIvraison';
import SupplementInfo from './SupplementInfo';
import { CATEGORIES_LINK } from '../../../Routes/links';
import { userDataSelector } from '../../../stores/auth/selector';
import AdsButton from '../../home/Button/AdsButton';

const Checkout = ({
  handleSubmit,
  cart: originalCart,
  shProviders,
  removeFromCart,
  tempOrder = {},
  buildOrderTotals,
  subscribe
}) => {
  //

  const screen = useWindowSize({ width: window.innerWidth });

  const userData = useSelector(userDataSelector);

  const [state, setState] = React.useState({
    isLoading: true,
    responsivity: 1,
    responsivityValidation: null,
    cart: originalCart,
  });

  const {
    isLoading,
    responsivity,
    responsivityValidation,
    cart,
    promoCode,
    freeShipping = false,
  } = state;

  React.useEffect(() => {
    const { promoCode } = tempOrder;
    if (promoCode) setState((state) => ({ ...state, promoCode }));
  }, [tempOrder]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      responsivityValidation: screen.width,
      isLoading: false,
    }));
  }, [screen.width]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    setState((state) => ({ ...state, cart: originalCart }));

    
  }, [originalCart]);

  React.useEffect(() => {
    const { shippingCost } = form.getFieldsValue();

    form.setFieldsValue({ amount: shippingCost + getCartTotal(cart) });

    
  }, [cart]);

  const onFinish = (value) => {
    setState((state) => ({ ...state, isLoading: true }));
    handleSubmit &&
      handleSubmit({ value, promoCode }, () => {
        setState((state) => ({ ...state, isLoading: false }));
      },subscribe);
  };

  const addressLivraison = (
    <Form.Item shouldUpdate>
      {({ getFieldValue }) => {
        //
        const otherAddress = getFieldValue('otherAddress');

        if (!otherAddress) return;

        return (
          <Informations
            responsivity={responsivity}
            collections={['shipping', 'address']}
            subtitle="Veuillez entrer vos informations de l'adresse de livraison"
            rules={rules.shipping.address(otherAddress)}
          />
        );
      }}
    </Form.Item>
  );

  const getCartTotal = (cart) => {
    let { total_sales, total_prices } = cart;

    if (total_sales === undefined) total_sales = total_prices;

    if (total_sales !== undefined && total_sales !== null) return total_sales;
    return 0;
  };

  const getInitValue = () => {
    // provider settings
    let provider;

    if (shProviders && shProviders.length > 0)
      provider = (tempOrder.shipping || {}).provider || shProviders[0].id;

    const freeShipping = (tempOrder.shipping || {}).freeShipping;

    const shippingCost = freeShipping ? 0 : getProviderPrice(provider);
    const amount = getCartTotal(cart) + shippingCost;
    const user = userData || {};

    let details = { ...tempOrder };

    if (!tempOrder.billing && user !== null) {
      details = {
        ...details,
        shipping: { address: user.shippingAddress, provider },
      };

      details = { ...details, billing: user.billingAddress };
    }

    const value = getInitialValue({
      provider,
      shippingCost,
      ...details,
      amount,
    });

    return value;
  };

  const getProviderPrice = (id) => {
    if (freeShipping) return 0;

    const item = shProviders.find((item) => item.id === id);
    return (item || {}).price || 0;
  };

  const buildTotalWithProvider = (id) => {
    return getCartTotal(cart) + getProviderPrice(id);
  };

  const onValuesChange = (value) => {
    if (!value.shipping) return;
    const provider = value.shipping.provider;
    if (!provider) return;

    form.setFieldsValue({ amount: buildTotalWithProvider(provider) });
    form.setFieldsValue({ shippingCost: getProviderPrice(provider) });
  };

  const onApplyPromo = async (promoCode) => {
    //

    setState((state) => ({ ...state, isLoading: true }));
    const shipping = form.getFieldValue('shipping') || {};

    if (!shipping.provider) return;

    const newCart = await buildOrderTotals({ promoCode, shipping });

    const { freeShipping, amount, shippingCost } = newCart;

    setState((state) => ({
      ...state,
      cart: newCart,
      promoCode,
      isLoading: false,
      freeShipping,
    }));

    form.setFieldsValue({ freeShipping, amount, shippingCost });

    if (!newCart || (newCart && !newCart.promo)) {
      return { error: true };
    }

    return { sucess: true };
  };

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      initialValues={getInitValue()}
      size="large"
      onFinish={onFinish}
      className="form"
      layout="vertical"
    >
      <Row gutter={30}>
        <Col md={14} xs={24} className="col14">
          <Informations
            responsivity={responsivity}
            collections={['billing']}
            title="Informations"
            rules={rules.billing}
            subtitle="Veuillez entrer vos informations de facturation"
          />
          {responsivity === 1 && (
            <Form.Item name="otherAddress" valuePropName="checked" noStyle>
              <Checkbox>Expédiers vers une autre adresse ?</Checkbox>
            </Form.Item>
          )}
          {addressLivraison}

          <div className={responsivity === 1 ? 'liv' : 'liv--display-none'}>
            <LivraisonField rules={rules} shProviders={shProviders} />
            <SupplementInfo rules={rules} />
          </div>

          <div
            className={
              isLoading === false && responsivityValidation > 768
                ? 'conf'
                : 'conf--display-none'
            }
          >
            <Confirmation rules={rules} />
          </div>
        </Col>

        <Col md={10} xs={24}>
          <div className={responsivity === 3 ? 'resu' : 'resu--display-none'}>
            {cart && (
              <ResumeCommande
                cart={cart}
                removeFromCart={removeFromCart}
                shProviders={shProviders}
                onApplyPromo={onApplyPromo}
                promoCode={promoCode}
              />
            )}
          </div>

          <div
            className={
              isLoading === false &&
              responsivityValidation <= 768 &&
              responsivity === 1
                ? 'conf'
                : 'conf--display-none--big'
            }
          >
            <Confirmation rules={rules} />
          </div>
        </Col>
      </Row>

      <div
        className={
          responsivityValidation > 768
            ? 'validation'
            : 'validation--display-none'
        }
      >
        <Row>
          <Col lg={7} className="validation__button">
            <AdsButton
              className="validation__command"
              text="PAYER"
              htmlType="submit"
              loading={isLoading}
              isSubmitButton
            />
          </Col>

          <Col lg={5} className="validation__button">
            <button className="validation__back">REVENIR AU PANIER</button>
          </Col>
        </Row>
      </div>

      <div
        className={
          responsivityValidation !== null &&
          isLoading === false &&
          responsivityValidation > 768
            ? 'validation--display-none'
            : 'validation'
        }
      >
        {responsivity === 1 && (
          <Row>
            <Col xs={12} className="validation__button">
              <NavHashLink to={`${CATEGORIES_LINK}#top`}>
                <button className="validation__back--small">
                  RETOUR AUX ACHATS
                </button>
              </NavHashLink>
            </Col>
            <Col xs={12} className="validation__button">
              <AdsButton
                type="submit"
                className="validation__command"
                text="PAYER"
                loading={isLoading}
                isSubmitButton
              />
            </Col>
          </Row>
        )}
      </div>
    </Form>
  );
};

export default Checkout;

const getInitialValue = ({
  amount = 1500,
  provider = 33,
  shippingCost,
  billing,
  shipping,
}) => ({
  billing,
  shipping:
    shipping ||
    {
      // provider,
    },
  amount,
  shippingCost,
  method: 'stripe',
});

const requiredRule = (message) => [
  {
    required: true,
    message,
  },
  {
    min: 2,
    message,
  },
];

const informationRules = {
  lastName: requiredRule('Entrez votre nom!'),
  firstName: requiredRule('Entrez votre prénom!'),
  email: requiredRule('Veuillez entrer un e-mail'),
  address: requiredRule('Veuillez entrer une adresse'),
  city: requiredRule('Veuillez entrer une ville'),
  country: requiredRule('Veuillez sélectionner un pays'),
  postalCode: requiredRule('Veuillez saisir un code postalCode'),
  phoneNumber: [...requiredRule('Entrez votre numéro de téléphone')],
};

const rules = {
  billing: informationRules,
  shipping: {
    provider: requiredRule('Vous devez sélectionner un transporteur'),
    address: (otherAddress) => {
      return otherAddress ? informationRules : {};
    },
  },
  details: [],
  terms: [
    {
      validator: (_, value) =>
        value
          ? Promise.resolve()
          : Promise.reject(
              new Error(
                'Vous devez accepter les termes et conditions de notre politique de confidentialité'
              )
            ),
    },
  ],
};
