import './style.less';

import { getFileUrl } from '../../../utils';
import { MOBILE_SIZE } from '../../../config';
import { CATEGORIES_SLUG } from '../../../Routes/links';
import AdsButton from '../../home/Button/AdsButton';
import { NavHashLink } from 'react-router-hash-link';
import useWindowSize from '../../../hooks/useWindowSize';

const CategoriesMosaic = ({ data, title, btnText }) => {
  // responsivity controles
  const screen = useWindowSize({ width: window.innerWidth });

  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  // repeat function of the categories cards
  const mosaic = () => {
    return data.map((item) => {

      return (
        <div className="mosaic-card" key={item._id}>
          <NavHashLink to={`${CATEGORIES_SLUG}/${item.slug}`}>
            <div className="image-wrapper">
              <img
                src={
                  isMobile
                    ? getFileUrl(item.featuredImage, { small: true })
                    : getFileUrl(item.featuredImage)
                }
                alt={(item.featuredImage || {}).name}
              />
            </div>
            <h4 className="mosaic-card-title">{item.title}</h4>
            <div className="more">En savoir plus</div>
          </NavHashLink>
        </div>
      );
    });
  };

  // render
  return (
    <div id="categories-mosaic">
      <h2 className="mosaic-title">{title}</h2>
      <div className="mosaic-wrapper">{data ? mosaic() : ''}</div>
      <div className="btn-section">
        <AdsButton
          text={btnText}
          href={
            `${CATEGORIES_SLUG}/` +
            process.env.REACT_APP_CATEGORY_ALL_PRODUCT_SLUG
          }
          className="mosaic-btn"
        />
      </div>
    </div>
  );
};

export default CategoriesMosaic;
