import React from 'react';
import { Row, Col } from 'antd';

import './confirmation.less';

import { NavHashLink } from 'react-router-hash-link';
import { Form, Checkbox } from 'antd';
import { CONDITION_LINK } from '../../../Routes/links';

const Confirmation = ({ rules }) => {
  return (
    <div id="confirmation" className="confirmation">
      <div className="confirmation__section">
        <h2 className="section__title">Confirmation</h2>
        <p className="section__subtitle">
          Nous arrivons à la fin. Quelques clics et votre commande est prête !
        </p>

        <Row>
          <Col lg={21}>
            <div className="confirmation__radio">
              <Form.Item
                name="newsletter"
                valuePropName="checked"
                rules={rules.newsletter}
                noStyle
              >
                <Checkbox>
                  Inscrivez-vous à la newsletter pour bénéficier d'un accès
                  exclusif et privilégié aux soldes, aux nouveaux arrivages et
                  aux promotions.
                </Checkbox>
              </Form.Item>
            </div>

            <div className="confirmation__radio">
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={rules.terms}
                className="terms-field"
              >
                <Checkbox>
                  En validant votre commande, vous acceptez les&nbsp;
                  <span className="terms-condition">
                    <NavHashLink to={`${CONDITION_LINK}#top`}>
                      termes et conditions de notre politique de
                      confidentialité.
                    </NavHashLink>
                  </span>
                </Checkbox>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Confirmation;
