import './style.less';

import Img from 'react-cool-img';
import { Row, Col } from 'antd';

import HeaderH3TextNormal from '../../HeaderText/HeaderH3TextNormal';
import { getFileUrl } from '../../../../utils';
import SectionWrapper from '../../../../components/SectionWrapper';

const PartenairesSection = ({ section, hashId }) => {
  //

  return (
    <SectionWrapper hashId={hashId} className="partner-section container">
      <HeaderH3TextNormal text="ILS/ELLES NOUS FONT CONFIANCE" />

      <Row
        justify="center"
        gutter={{ sm: 50, xs: 30 }}
        className="partenaires-logo-wrapper"
      >
        {section.map((partner) => {
          return (
            <Col
              xs={12}
              sm={8}
              // span={section.length % 2 === 0 ? 12 : 8}
              className="partner-wrapper"
              key={partner._id}
            >
              <div className="logo-wrapper">
                <Img className="logo" src={getFileUrl(partner.logo)} />
                <p className="partner-name">{partner.description}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </SectionWrapper>
  );
};

export default PartenairesSection;
