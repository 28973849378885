import React,{useEffect,useState} from 'react';
import './productCardV3.less';
import { notification } from 'antd';
import { ShoppingOutlined, CloseOutlined } from '@ant-design/icons';
import { getCartArgs, showCardPrice } from '../../../components/Cart/utils';
import { getFileUrl } from '../../../utils';
import { useHistory } from 'react-router-dom';
import useProductSoldOut from '../../../stores/product/hooks/useProductSoldOut';
import axios from 'axios';

const ProductCardV3 = ({ data, addToCart, removeFromList }) => {
  const [appointmentLink,setAppointmentLink] = useState('')
  useEffect(()=>{
    async function getRdvLink(){
      var link = (await axios.get(process.env.REACT_APP_BACKOFFICE_URL+"/configuration"))?.data.prendreRdv
      setAppointmentLink(link)
    }
    getRdvLink()
  })
  useEffect(()=>{},[appointmentLink])
  const { image, name, price, id, slug, sale } = data;
  let history = useHistory();

  const { isSoldOut, soldOutMessage } = useProductSoldOut(data);

  const openCartNotification = () => {
    const cartArgs = getCartArgs({ image, name, price });
    notification.open(cartArgs);
  };

  const handleAddToCArt = async (e) => {
    e.stopPropagation();
    const done = await addToCart({ id, qte: 1 });
    if (done) openCartNotification();
  };

  const handleDelete = () => {
    removeFromList(id);
  };

  const onClick = () => {
    history.push(`/produits/${slug}`);
  };

  return (
    <div id="product-card3" className="product-card3">
      <div className="product-card-img-wrapper3">
        <img
          onClick={() => onClick()}
          src={getFileUrl(image, { small: true })}
          alt=""
        />

        <div className="product-card-icons3">
          {!isSoldOut && <ShoppingOutlined onClick={handleAddToCArt} />}
          <CloseOutlined onClick={handleDelete} />
        </div>
        {isSoldOut ? (
          soldOutMessage
        ) : (
          <p className="product-card-trybtn3">
            <a href={appointmentLink} target={"_blank"} rel="noreferrer">
              ESSAYER EN INSTITUT
            </a>
          </p>
        )}
      </div>
      <div className="product-card-detail3">
        <p className="product-card-text3">{name}</p>
        <p className="product-card-amount3">{showCardPrice(price, sale)}</p>
      </div>
    </div>
  );
};

export default ProductCardV3;
