import './style.less';

import HTMLReactParser from 'html-react-parser';
import BannerSection from '../../home/HomeSections/BannerSection';

const CategoriesBanner = ({ data = {} }) => {
  // destructuring banner data
  const { description, titre_normal, titre_italique } = data;

  // render
  return (
    <div id="categories-banner">
      <BannerSection
        normalHeaderText={titre_normal}
        italicHeadertext={titre_italique}
        banner={[data]}
      />
      <div className="caption">{HTMLReactParser(`${description}`)}</div>
    </div>
  );
};

export default CategoriesBanner;
