import React from 'react';
import { Row, Col } from 'antd';
import Img from 'react-cool-img';
import { NavHashLink } from 'react-router-hash-link';

import './style.less';
import EventDetailCard from './card.js';
import Rester from '../Confiance/Rester.js';
import HeaderH1TextItalic from '../home/HeaderText/HeaderH1TextItalic.jsx';
import HeaderH3TextNormal from '../home/HeaderText/HeaderH3TextNormal.jsx';
import { vitrinSelector } from "../../stores/vitrin/selector";
import * as actions from "../../stores/vitrin/actions";
import { connect } from "react-redux";
import Spiner from "../../components/Spiner";
import HTMLReactParser from "html-react-parser";
import { getFileUrl } from '../../utils';

const EventDetail = ({match, data, confianceData,  setData}) => {

  if (!data)
  setData({
    endpoints: "evenements",
    name: NAME,
  });

  if (!confianceData)
  setData({
    endpoints: "elles-nous-font-confiance",
    name: SECOND_PAGE_NAME,
  });

  if (!data || !confianceData) return <Spiner />;

  const { slug } = match.params;
  const preActiveEvent = data.filter((event) => event.slug === slug);
  const activeEvent = preActiveEvent[0];
  const otherEvents = data.filter((event) => event.slug !== slug);

  return (
    <div id="EventDetail" className="event-detail container">
      <div className="titleSection">
        <HeaderH1TextItalic
          text="Évènements"
          isCenter={true}
          shouldBringUp={true}
          isRedColor={true}
        />
      </div>

      <EventDetailCard activeEvent={activeEvent} otherEvents={otherEvents} />

      <Row gutter={30} className="sub-event-row">
        <Col sm={12} xs={24} className="sub-event-col">
          <div className="sub-event-text">
          {HTMLReactParser(`${confianceData.sectionVideo.leftDescription}`)}
          </div>
        </Col>
        <Col sm={12} xs={24} className="sub-event-col">
          <div className="sub-event-text">
            {HTMLReactParser(`${confianceData.sectionVideo.rigthDescription}`)}
          </div>
        </Col>
      </Row>

      <h2 className="event-cards-title">
        <HeaderH3TextNormal text="Nos autres évènements" />
      </h2>

      <div className="event-cards">
        <Row gutter={30}>
          {otherEvents.slice(1, 4).map((event, index) => {
            return (
              <Col sm={8} className="event-card" key={index}>
                <h3 className="event-card__title">{HTMLReactParser(`${event.description}`)}</h3>
                <NavHashLink
                  to={`/elles-nous-font-confiance/evenements/${event.slug}#EventDetail`}
                >
                  <div className="event-card__image-wrapper">
                    <Img
                      className="event-card__img"
                      src={getFileUrl(event.mobile_media)}
                      alt=""
                    />
                  </div>
                </NavHashLink>
              </Col>
            );
          })}
        </Row>
      </div>

      <Rester data={confianceData.sectionStayWithUs} />
    </div>
  );
}

const NAME="evenements"
const SECOND_PAGE_NAME="elles-nous-font-confiance"
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state,NAME),
    confianceData: vitrinSelector(state,SECOND_PAGE_NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(EventDetail);
