import React from 'react';

import { Space } from 'antd';
import { ReactComponent as FacebookLogo } from '../assets/icons/facebook.svg';

import { ReactComponent as InstagramLogo } from '../assets/icons/instagram.svg';

import { FaYoutube } from 'react-icons/fa';

const SocialRow = ({
  size = 66,
  fbkUrl,
  youtubeUrl,
  insta_url,
}) => {
  return (
    <div className="socialrow">
      <Space size={size}>
        <a
          target="_blank"
          href={insta_url || 'https://www.instagram.com/lartisandusublime/'}
          rel="noreferrer"
        >
          <InstagramLogo />
        </a>

        <a
          target="_blank"
          href={fbkUrl || 'https://www.facebook.com/lartisandusublime/'}
          rel="noreferrer"
        >
          <FacebookLogo />
        </a>

        <a
          target="_blank"
          href={youtubeUrl || 'https://www.youtube.com/@Soyezsublime'}
          rel="noreferrer"
        >
          <FaYoutube style={{ fontSize: '28px', color: '#333333' }} />
        </a>
      </Space>
    </div>
  );
};

export default SocialRow;
