import './style.less';
import React from 'react';
import Maps from './Maps';
import ContactForm from './Form';
import { postContact } from './api';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Row, Col, notification } from 'antd';
import { vitrinSelector } from '../../stores/vitrin/selector';
import * as actions from '../../stores/vitrin/actions';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import { getFileUrl } from '../../utils';
import BannerSection from '../home/HomeSections/BannerSection';
import AdsButton from '../home/Button/AdsButton';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';

const Contact = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'page-contact',
    name: NAME,
  });

  useHashScrooler({ location, data });

  const [isSubmited, setIsSubmited] = React.useState(false);

  const handleSubmit = (data, next,subscribe) => {
    //

    // const formdata = new FormData();
    // for (const key in data) {
    //   formdata.append(key, data[key]);
    // }
    postContact(data, (response) => {
      const { error } = response;
      if (!error) {
        handleSuccess();
        if(data.newsletter && data.email){
          subscribe({EMAIL: data.email})
        }
        next && next();
        setIsSubmited(true);
      }
    });
  };

  const handleSuccess = () => {
    setIsSubmited(true);
    notification['success']({
      message: 'Demande envoyée',
      description:
        'Votre demande à été envoyée avec succès. Nous vous répondrons dans les meilleurs délais',
      onClick: () => {},
    });
  };
  if (!data) return <Spiner />;
  const successMessage = (
    <>
      <p
        className="success-message"
        dangerouslySetInnerHTML={{
          __html: data.message_apres_soumission.description,
        }}
      ></p>

      <AdsButton
        text="Nouvelle demande"
        className="submit-button contact-buton"
        id="adz-reset-button"
        isSubmitButton={false}
        onClick={() => setIsSubmited(false)}
        block
      />
    </>
  );

  return (
    <div id="contact">
      <div className=" container wrapper ">
        <BannerSection
          normalHeaderText={data.banniere.titre_normal}
          italicHeadertext={data.banniere.titre_italique}
          banner={[data.banniere]}
        />

        <div className=" form-section">
          <div className="form-title-wrapper">
            <h1 className="form-title">{data.form_title_normal}</h1>
            <h1 className="form-title-italick">{data.form_title_italic}</h1>
          </div>

          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <MailchimpSubscribe
                id="NewsLetter"
                url={process.env.REACT_APP_MAILCHIMP_URL}
                render={({ subscribe ,status,message }) => {
                  return isSubmited ? ( successMessage ) : (
                    <>
                      <ContactForm
                        handleSubmit={handleSubmit}
                        hashId={data.formHashId}
                        subscribe={subscribe}
                      />
                    </>
                  )
                }}
              />
            </Col>
            <Col lg={12} md={12} sm={0} xs={0}>
              {data.maps && <Maps imgsrc={getFileUrl(data.maps)} />}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const NAME = 'contact';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Contact);
