import React from "react";
import "./sectionDescWithImg.less";
import AdsButton from "../Button/AdsButton";
import Img from "react-cool-img";
import { MOBILE_SIZE } from "../../../config";
import useWindowSize from "../../../hooks/useWindowSize";
import HTMLReactParser from "html-react-parser";

const SectionDescWithImg = ({
  image,
  texts,
  isTextCenter,
  href,
  customImgStyleOnMobile,
  buttonText = "EN SAVOIR PLUS"
}) => {
  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;
  const getMainClasses = () => {
    let res = "section-content-main";
    if (isTextCenter) {
      res = res + " align-text-at-center";
    } else {
      res = res + " align-text-at-end";
    }
    return res;
  };
  const styleObject =
    isMobile && customImgStyleOnMobile ? customImgStyleOnMobile : {};
  return (
    <div className="section-content-wrapper">
      <Img
        className="section-content-img"
        style={styleObject}
        alt=""
        src={image}
      />
      <div className={getMainClasses()}>
        <div className="section-text-content-wrapper">
          <div key={texts} className="ads-sample-text">
                {HTMLReactParser(`${texts || ""}`)}
              </div>
          <AdsButton text={buttonText} href={href} />
        </div>
      </div>
    </div>
  );
};

export default SectionDescWithImg;
