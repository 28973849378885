import http from './http';
import { notification } from 'antd';

/** Move this from here please!!!!! It's builshit */
const openNotification = (text) => {
  const args = {
    message: '',
    description: text,
    duration: 5,
  };
  notification.open(args);
};

export function fetchProducts(params) {
  const { page, _q, _sort, ...rest } = params;

  let value = {
    ...rest,
    _start: page * 9,
    _limit: 9,
    _q: _q !== '' ? _q : undefined,
    _sort,
  };

  value = JSON.parse(JSON.stringify(value));

  return http
    .get('/products', {
      params: value,
    })
    .then((data) => {
      return data;
    });
}

export function fetchFilters(user) {
  return http.get('/filters').then((data) => {
    return data;
  });
}

export function addToCart(product, qte) {
  if (!localStorage.getItem('user')) return Promise.reject();
  if (!qte) qte = 1;
  return http
    .post(
      '/cart/' + JSON.parse(localStorage.getItem('user')).id,
      {
        product,
        qte,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    )
    .then((data) => {
      openNotification('Produit ajouté au panier');
      return data;
    })
    .catch(() => {});
}

export function addToWhitelist(product) {
  if (!localStorage.getItem('user')) return Promise.reject();
  return http
    .post(
      '/wishlists/' + product,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    )
    .then((data) => {
      return data;
    })
    .catch(() => {});
}

export function fetchWhitelist(product) {
  return http
    .get('/wishlist', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    .then((data) => {
      return data;
    })
    .catch(() => {});
}
