import React from 'react';
import HTMLReactParser from 'html-react-parser';
import SectionWrapper from '../../components/SectionWrapper';

const CreatorShortMenuCard = ({ title, items, hashId }) => {
  return (
    <SectionWrapper hashId={hashId} className="short-menu-card-wrapper">
      <h2 className="short-menu-card-title">{title}</h2>
      <div className="short-menu-card-content">
        {HTMLReactParser(`${items}`)}
      </div>
    </SectionWrapper>
  );
};

export default CreatorShortMenuCard;
