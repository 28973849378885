export const columns = [
  {
    title: 'Vos produits',
    dataIndex: 'image',
    key: 'image',
    width: '18%',
  },
  {
    title: 'Nom',
    dataIndex: 'product',
    key: 'product',
    width: '39%',
  },
  {
    title: 'Prix unitaire',
    dataIndex: 'price',
    key: 'age',
    width: '18%',
  },
  {
    title: 'Qte',
    dataIndex: 'qte',
    key: 'qte',
    width: '5%',
  },
  {
    title: 'Prix',
    dataIndex: 'priceUnit',
    key: 'priceUnit',
    width: '20%',
  },
];
