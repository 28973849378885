import axios from 'axios';
import { BASE_URL } from './config';

//axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}

const http = axios.create({
  baseURL: BASE_URL,
});

http.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default http;
