import React , {useState,useRef,useEffect} from 'react';
import { Dropdown, Popover, Button, Radio } from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import ProductFilter from './Filter';

const FilterSearch = ({
  allFilters,
  filterFromUrl,
  filtervalue,
  _sort,
  _q,
  triggerSearch
}) => {
  //
  
  const input = useRef()
  const [_filter,setFilter] = useState()
  const [isTriOpen,setIsTriOpen] = useState(false)

  useEffect(()=>{
    setFilter(filtervalue)
  },[])


  const onApplyFilter = (vals) =>{
    submitForm(null,vals,true) 
  }

  const getSortValue = (val) => {
    if (!val || val === null || val === 'best') return 'best';
    const m = val.substring(5);
    return m;
  };

  const radioStyle = {
    display: 'block',
  };

  const onTriChanged = (e) => {
    const { value } = e.target;
    submitForm(null,{ _sort: value !== 'best' ? `sale:${value}` : undefined }) 
  };

  const menuTri = (
    <div className="shop-filters shop-tri">
      <Radio.Group
        value={getSortValue(_sort)}
        onChange={(e) =>{onTriChanged(e);setIsTriOpen(!isTriOpen)}}
      >
        <Radio style={radioStyle} value={'best'}>
          <span className="shop-filters-text">Recommander</span>
        </Radio>
        <Radio style={radioStyle} value={'asc'}>
          <span className="shop-filters-text">Prix: par ordre croissant</span>
        </Radio>
        <Radio style={radioStyle} value={'desc'}>
          <span className="shop-filters-text">Prix: par ordre décroissant</span>
        </Radio>
      </Radio.Group>
    </div>
  );

  const submitForm = (e,params={},reset=false)=>{
    e?.preventDefault()
    let filter = reset ? {_sort: _filter._sort,_q: _filter._q} : JSON.parse(JSON.stringify(_filter));
    let { value } = input.current;
    if (value?.trim() === '') value = undefined;
    filter['_q'] = value;
    filter = {...filter,...params}
    setFilter(filter);
    triggerSearch(filter)
  }
  return (
    <form onSubmit={submitForm} className="shop-input" style={{}}>
      <div>
        <input
          ref={input}
          className="shop-input-search form-input"
          placeholder="Rechercher"
        />
        <SearchOutlined className="shop-input-searchicon" />
      </div>

      <div classme="shop-input-filter">
        <ProductFilter
          data={allFilters}
          filterFromUrl={filterFromUrl}
          applyFilter={onApplyFilter}
          clearFilter={()=>{submitForm(null,{},true)}}
        >
          <Button>
            FILTRE <CaretDownOutlined />
          </Button>
        </ProductFilter>
        <Popover 
          open={isTriOpen} 
          onOpenChange={(visible)=>{setIsTriOpen(visible)}} 
          placement="bottom" 
          content={menuTri}
          trigger={'click'}>
          <Button onClick={()=>{setIsTriOpen(!isTriOpen)}}>
            TRIER PAR <CaretDownOutlined />
          </Button>
        </Popover>
      </div>
    </form>
  );
};

export default FilterSearch;
