import React from 'react';
import { connect } from 'react-redux';
import Content from './Content';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { cartSelector } from '../../stores/cart/selector';
import {
  providersSelector,
  tempOrderSelector,
} from '../../stores/order/selector';
import * as cartActions from '../../stores/cart/cartActions';
import * as orderActions from '../../stores/order/actions';
import CheckoutBreadcrumb from './CheckoutBreadcrumb';
import './style.less';

const stripePromise = loadStripe('pk_test_dXaZgDmJ3Ip82Xj8wqSZxUzC00gAlDgrSI');

const Checkout = ({
  cart,
  shProviders,
  removeFromCart,
  clearCart,
  submitOrder,
  tempOrder,
  setTempOrder,
  buildOrderTotals,
  tempOrderToStore,
  setShippingProviders,
  location,
}) => {
  //

  React.useEffect(() => {
    localStorage.removeItem('newOrder');
    if (!tempOrder) tempOrderToStore();
    if (!shProviders) setShippingProviders();

    
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div id="checkout" className="container checkout">
        <div className="checkout-breadcrumb">
          <CheckoutBreadcrumb location={location} />
        </div>

        <div>
          <Content
            cart={cart}
            shProviders={shProviders}
            removeFromCart={removeFromCart}
            clearCart={clearCart}
            submitOrder={submitOrder}
            tempOrder={tempOrder}
            setTempOrder={setTempOrder}
            buildOrderTotals={buildOrderTotals}
          />
        </div>
      </div>
    </Elements>
  );
};

const Wrapper = connect(
  (state) => ({
    cart: cartSelector(state),
    shProviders: providersSelector(state),
    tempOrder: tempOrderSelector(state),
  }),

  (dispatch) => {
    return {
      removeFromCart: (val) => dispatch(cartActions.removeItemFromCart(val)),
      clearCart: (val) => dispatch(cartActions.clearCart()),
      submitOrder: (val) => dispatch(orderActions.submitOrder(val)),
      setTempOrder: (val) => dispatch(orderActions.setTempOrder(val)),
      tempOrderToStore: (val) => dispatch(orderActions.tempOrderToStore()),
      buildOrderTotals: (val) => dispatch(orderActions.buildOrderTotals(val)),
      setShippingProviders: (val) =>
        dispatch(orderActions.setShippingProviders()),
    };
  }
);

export default Wrapper(Checkout);
