import {
  getShippingProviderApi,
  submitOrderApi,
  orderTotalsApi,
  fetchOrdersApi,
} from './api';
import * as types from './types';

export const setShippingProviders = () => async (dispatch) => {
  const data = await getShippingProviderApi();

  if (data) {
    dispatch({
      type: types.SET_PROVIDERS,
      payload: data,
    });
  }
};

export const setTempOrder = (tempOrder) => async (dispatch) => {
  if (tempOrder) {
    localStorage.setItem('tempOrder', JSON.stringify(tempOrder));

    dispatch({
      type: types.SET_TEMP_ORDER,
      payload: tempOrder,
    });
  }
};

export const tempOrderToStore = () => async (dispatch) => {
  const tempOrder = localStorage.getItem('tempOrder');

  if (!tempOrder || tempOrder === null || tempOrder === 'undefined') return;

  const value = JSON.parse(tempOrder);

  dispatch({
    type: types.SET_TEMP_ORDER,
    payload: value,
  });

  return value;
};

export const submitOrder = (value) => async (dispatch) => {
  const data = await submitOrderApi(value);
  if(data.error) return {error: data.message}
  if (data) {
    dispatch({
      type: types.SET_ORDERS_HISTORY,
      payload: data.orders || [],
    });
    return data.newOrder;
  }

  return false;
};

export const buildOrderTotals =
  ({ shipping, promoCode }) =>
  async (dispatch) => {

    const data = await orderTotalsApi({ shipping, promoCode });

    if (data) {
      return data;
    }

    return undefined;
  };

export const setOrdersHistoryAction = () => async (dispatch) => {
  const data = await fetchOrdersApi();

  if (data && data !== null) {
    dispatch({
      type: types.SET_ORDERS_HISTORY,
      payload: data.content,
    });

    return data;
  }

  return false;
};
