import './style.less';

import Img from 'react-cool-img';
import { NavHashLink } from 'react-router-hash-link';

import { getFileUrl, isValidValue } from '../../../utils';
import QuantitySetting from '../../QuantitySetting';
import { CATEGORIES_SLUG } from '../../../Routes/links';
import { getProductInfos } from '../utils';
import VariationDetails from '../VariationDetails';

const CartItemCard = ({ data, onClickCart, handleDelete }) => {
  // Destructuring data & actions
  const { product, qte, variation, _id, variation_slug } = data;

  // Desturing product
  const {
    price,
    sale = price,
    slug,
    name,
    image,
    categories,
    stock,
  } = getProductInfos(data.product, variation);

  let displayPrice = sale == null ? price : sale;
  let displayTotalPrice = Math.round(displayPrice * qte);

  const getImge = () => {
    if (variation && isValidValue(variation.image)) return variation.image;
    return image;
  };

  return (
    <div className="cart__card">
      <div className="card-col card-image-wrapper">
        <Img
          className="card-image"
          src={getFileUrl(getImge(), {
            small: true,
          })}
          onClick={() => onClickCart(slug)}
        />
      </div>
      <div className="card-col card-info">
        <ul className="info-list">
          <li className="info-item info-item--main">{name}</li>
          <li className="info-item--categories info-item--medium">
            {isValidValue(categories) && categories.length > 0 && (
              <NavHashLink to={`${CATEGORIES_SLUG}/${categories[0].slug}`}>
                {categories[0].title}
              </NavHashLink>
            )}
          </li>

          {variation && <VariationDetails variation={variation} />}

          <li>
            <QuantitySetting
              product={product}
              qte={qte}
              variation={variation}
              stock={stock}
            />
          </li>
        </ul>
      </div>
      <div className="card-col card-price">
        <ul className="price-list">
          <li className="price-item--unit info-item--main">
            {sale == null ? price : sale} €
          </li>

          {/* {variation && { displayVariation }} */}

          <div>
            <li className="price-item--total info-item--medium">
              Total: <span>{displayTotalPrice}€</span>
            </li>
            <li className="price-item--remove">
              <button
                onClick={() =>
                  handleDelete({
                    id: _id,
                    variation_slug,
                    product_id: product.id,
                  })
                }
              >
                Retirer du panier
              </button>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CartItemCard;
