import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Img from 'react-cool-img';
import { connect, useSelector } from 'react-redux';
import { Col } from 'antd';
import { useDispatch } from 'react-redux';
import { NavHashLink } from 'react-router-hash-link';

import {
  ShoppingOutlined,
  HeartOutlined,
  HeartFilled,
} from '@ant-design/icons';

import './style.less';
import * as actions from '../../../stores/cart/cartActions';
import { MODALPAGES } from '../../../stores/app/reducer';
import { openModal } from '../../../stores/app/actions';
import { isAuthSelector } from '../../../stores/auth/selector';
import * as wishlistActions from '../../../stores/wishList/actions';

import {
  addCartNotification,
  addWishNotification,
  removeWishNotification,
} from './notifications';
import { getFileUrl } from '../../../utils';
import { showCardPrice } from '../../../components/Cart/utils';
import useProductSoldOut from '../../../stores/product/hooks/useProductSoldOut';

const ProductCardV2 = ({ prod, addToCart, addToWishlist, category }) => {
  const isAuth = useSelector(isAuthSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const { isSoldOut, soldOutMessage } = useProductSoldOut();

  const openProduct = (product) => {
    history.push(`/produits/${product.slug}#top`);
    // window.open(`/produits/${product.slug}#top`, '_self');
  };

  const [toggleFav, setToggleFav] = useState(false);

  const handleAddToCArt = async (e) => {
    e.stopPropagation();
    const done = await addToCart({ id: prod.id, qte: 1, window });
    if (done) addCartNotification();
  };

  const handleAddWishList = (e) => {
    e.stopPropagation();

    if (!isAuth) return dispatch(openModal({ type: MODALPAGES.SIGNIN }));

    const done = addToWishlist({ id: prod.id, window });
    if (done) {
      onClickWish();
      setToggleFav(!toggleFav);
    }
  };

  const onClickWish = () => {
    if (!toggleFav) addWishNotification();
    else removeWishNotification();
    setToggleFav(!toggleFav);
  };

  return (
    <Col sm={8} xs={24} className="product__card">
      <div className="card__wrapper" onClick={() => openProduct(prod)}>
        <div className="card__header">
          <div className="product-card-icons">
            {!toggleFav ? (
              <HeartOutlined onClick={handleAddWishList} />
            ) : (
              <HeartFilled
                style={{ color: '#B22222' }}
                onClick={handleAddWishList}
              />
            )}

            {!isSoldOut && <ShoppingOutlined onClick={handleAddToCArt} />}
          </div>
        </div>
        <div className="card__content">
          <NavHashLink
            to={{ pathname: `/produits/${prod.slug}`, state: { category } }}
          >
            <Img className="content__image" src={getFileUrl(prod.image)} />
          </NavHashLink>
        </div>
        <div className="card__footer">
          {isSoldOut && soldOutMessage}
          <p className="card__detail-name">{prod.name}</p>
          <p className="card__detail-price">
            {showCardPrice(prod.price, prod.sale)}
          </p>
        </div>
      </div>
    </Col>
  );
};

const Wrapper = connect(
  (state) => ({}),

  (dispatch) => {
    return {
      addToCart: (val) => dispatch(actions.addItemToCart(val)),
      addToWishlist: (data) =>
        dispatch(wishlistActions.addItemToWishlist(data)),
    };
  }
);

export default Wrapper(ProductCardV2);
