import Home from '../pages/home';
import Contact from '../pages/contact';
import History from '../pages/history';
import Checkout from '../pages/Checkout';
import Helthcare from '../pages/Helthcare';
import Prestation from '../pages/Prestation';
import Engagement from '../pages/engagement';
import Team from '../pages/team';
import TheCreator from '../pages/TheCreator/index';
import Hairmatch from '../pages/Hairmatch/index';
import Loyality from '../pages/Loyality/index';
import Appointment from '../pages/Appointment/index';
import UserProfile from '../pages/userProfile';
import Manufacturing from '../pages/Manufacturing';
import Confiance from '../pages/Confiance';
import EventDetail from '../pages/EventDetail/index';
import Conditions from '../pages/Conditions';
import Produits from '../pages/eshop/shop/';
import Blog from '../pages/Blog';
import OrderDetail from '../pages/OrderDetail';
import BlogDetail from '../pages/Blog/Detail';
import SingleProduct from '../pages/eshop/SingleProduct';
import Availability from '../pages/Appointment/Availabilities';
import WishList from '../pages/WishList';
import CheckoutPayments from '../pages/Checkout/Payments';
import OurCollection from '../pages/OurCollection';
import Categories from '../pages/Categories';
import NewPassword from '../pages/userProfile/userDetails/newPassword';

export const componentsMapped = {
  Home: Home,
  Categories: Categories,
  Produits: Produits,
  WishList: WishList,
  Appointment: Appointment,
  Availability: Availability,
  Engagement: Engagement,
  History: History,
  Team: Team,
  TheCreator: TheCreator,
  Prestation: Prestation,
  OurCollection: OurCollection,
  Hairmatch: Hairmatch,
  Loyality: Loyality,
  Manufacturing: Manufacturing,
  Helthcare: Helthcare,
  Confiance: Confiance,
  EventDetail: EventDetail,
  Blog: Blog,
  BlogDetail: BlogDetail,
  Contact: Contact,
  Conditions: Conditions,
  NewPassword: NewPassword,
  SingleProduct: SingleProduct,
  UserProfile: UserProfile,
  OrderDetail: OrderDetail,
  CheckoutPayments: CheckoutPayments,
  Checkout: Checkout,
};
