import React, { useState } from 'react';
import { Row, Col, Form, Input, notification, Button } from 'antd';

const ChangeProfile = ({ user, onSubmitUserChange }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);

    onSubmitUserChange &&
      onSubmitUserChange(
        {
          username: values.username,
          email: values.email,
          phone: values.phone,
        },
        callback
      );
  };

  const callback = (result) => {
    if (result.error) {
      return openNotification("Une erreur s'est produite !");
    }

    openNotification('Modificattion effectuée avec succès !');
    setIsLoading(false);
  };

  const getInitialValue = () => {
    if (!user) return {};
    else
      return {
        remember: true,
        email: user.email,
        username: user.username,
        phone: user.phone,
      };
  };

  return (
    <div id="changePassword" className="changePassword">
      {user && (
        <Form
          layout="vertical"
          initialValues={getInitialValue()}
          onFinish={onFinish}
        >
          <Row>
            <Col lg={8} md={12} sm={24} xs={24} className="">
              <Form.Item
                label="Username"
                name="username"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} className="">
              <Form.Item
                label="Téléphone"
                name="phone"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} className="">
              <Form.Item
                name="email"
                label="E-mail"
                hasFeedback
                rules={[
                  {
                    type: 'email',
                    message: 'Adresse mail non valide',
                  },
                  {
                    required: true,
                    message: 'Veuillez renseigner ce champ !',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className="switch-password__submit">
            <Button
              isLoading={isLoading}
              htmlType="submit"
              className="details__button"
            >
              APPLIQUER
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default ChangeProfile;

const openNotification = (text) => {
  const args = {
    message: '',
    description: text,
    duration: 5,
  };
  notification.open(args);
};
