import React from 'react';
import { getProductInfos } from '../../../components/Cart/utils';

const useProductSoldOut = (
  data,
  message = 'Rupture de stock',
  currentVariation
) => {
  const [state, setState] = React.useState({
    stock: 1,
    isSoldOut: false,
    manageStock: true,
  });

  React.useEffect(() => {
    if (data) {
      const { stock, isSoldOut, manageStock, ...infos } = getProductInfos(
        data,
        currentVariation
      );

      setState((state) => ({
        ...state,
        stock,
        manageStock,
        isSoldOut,
        ...infos,
      }));
    }

    // 
  }, [data, currentVariation]);

  const soldOutMessage = <p className="product-card-sold-out">{message}</p>;

  return { ...state, soldOutMessage };
};

export default useProductSoldOut;
