import http from '../../api/http';

export const postContact = async (data, next) => {

  return http
    .post('/contacts', data)
    .then((data) => {
      if (!data.error) next({ success: true });
      else next({ error: true });
    })
    .catch((error) => {
      console.log({ error });
      next({ error: true });
    });

  //
};
