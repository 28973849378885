import './style.less';
import { Row, Col } from 'antd';
import { CATEGORIES_LINK } from '../../Routes/links';
import { NavHashLink } from 'react-router-hash-link';

export default function NoCart({ onClose }) {
  return (
    <Row className="no-cart" type="flex">
      <Col span={24} className="no-cart__message">
        Votre panier est vide pour le moment. Souhaitez-vous continuer vos
        achâts?
      </Col>

      <Col span={24} className="no-cart__div-button">
        <NavHashLink to={`${CATEGORIES_LINK}#top`}>
          <button
            onClick={onClose}
            className="recap__button-continue no-cart__button-continue"
          >
            CONTINUER LES ACHATS
          </button>
        </NavHashLink>
      </Col>
    </Row>
  );
}
