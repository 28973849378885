import * as types from './types';

const INITIAL_STATE = {
  openPopup: false,
};

const cartReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SET_ALERT_DEMANDS:
      return { ...state, alertDemands: payload };

    default:
      return {
        ...state,
      };
  }
};

export default cartReducer;

export const key = 'products';
