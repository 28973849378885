import React from 'react';
import './style.less';
import HeaderH1TextNormal from '../../HeaderText/HeaderH1TextNormal';
import HeaderH1TextItalic from '../../HeaderText/HeaderH1TextItalic';
import SectionDescWithImg from '../../SectionDescWithImg/SectionDescWithImg';

import { getFileUrl } from '../../../../utils';
import SectionWrapper from '../../../../components/SectionWrapper';

const NotreMissionSection = ({ section }) => {
  const { hashId } = section || {};

  return section && (
    <SectionWrapper hashId={hashId}>
      <div className="max-width-800-div notre-mission-header">
        <HeaderH1TextNormal
          text={section.titre_normal}
          isCenter={false}
          shouldBringUp={false}
        />

        <HeaderH1TextItalic
          text={section.titre_italique}
          isCenter={false}
          shouldBringUp={true}
          isRedColor={true}
          isSmaller={true}
        />
      </div>
      <div className="notre-mission-section-wrapper">
        <SectionDescWithImg
          image={getFileUrl(section.desktop_media)}
          buttonText={section.titre_bouton}
          texts={section.description}
          href={section.lien_bouton + '#top'}
        />
      </div>
    </SectionWrapper>
  );
};

export default NotreMissionSection;
