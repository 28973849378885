import * as types from './type';

const INITIAL_STATE = {
  openPopup: false,
};

const wishlistReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_WISHLIST:
      return { ...state, data: payload };
    case types.SET_WISHLIST:
      return { ...state, data: payload };

    case types.SET_WISHLIST_CONTENT:
      return { ...state, data: payload };

    case types.RESET_WISHLIST_CONTENT:
      return { ...state, data: [] };

    case types.CLEAR_WISHLIST:
      return { ...state, data: undefined };

    default:
      return {
        ...state,
      };
  }
};

export default wishlistReducer;

export const key = 'wishlist';
