import * as links from '../Routes/links';

const nativetMenu = [
  {
    id: 'acceuil',
    title: 'Accueil',
    link: links.HOME_LINK,
    componentKey: 'Home',
    exact: true,
  },
  {
    id: 'e-shop',
    title: 'E-shop',
    link: links.CATEGORIES_LINK,
    componentKey: 'Categories',
    exact: true,
    paramsLink: {
      link: links.CATEGORIES_DETAILS_LINK,
      componentKey: 'Produits',
    },
  },

  {
    id: 'favoris',
    title: 'Favoris',
    link: links.FAVORI_LINK,
    componentKey: 'WishList',
    exact: true,
    protected: true,
  },

  {
    id: 'rdv',
    title: 'Prendre Rdv',
    link: links.APPOINTMENT_LINK,
    componentKey: 'Appointment',
    exact: true,
    paramsLink: {
      link: links.APPOINTMENT_AVAILABILITY_LINK,
      componentKey: 'Availability',
    },
  },

  {
    id: 'notre-institut',
    title: 'Notre Institut',
    link: links.INSTI_LINK,
    // componentKey: ,
    submenus: [
      {
        id: 'engagement',
        title: 'Nos engagements',
        link: links.ENGAGEMENT_LINK,
        componentKey: 'Engagement',
      },
      {
        id: 'histores',
        title: 'Notre histoire',
        slug: links.HISTOIRE_SLUG,
        link: links.HISTOIRE_LINK,
        componentKey: 'History',
      },

      // {
      //   id: 'engagements',
      //   title: 'Nos engagements',
      //   link: links.ENGAGEMENT_SLUG,
      // },
      {
        id: 'equipe',
        title: 'L’Équipe',
        link: links.EQUIPE_LINK,
        componentKey: 'Team',
      },
      {
        id: 'creatrice',
        title: 'La créatrice',
        link: links.CREATRICE_LINK,
        componentKey: 'TheCreator',
      },
    ],
  },
  {
    id: 'nos-creations',
    title: 'Nos Créations',
    link: links.NOS_CREATION_LINK,
    // componentId: CREATION_SLUG,
    submenus: [
      {
        id: 'prestations',
        title: 'Nos prestations',
        link: links.PRESTATION_LINK,
        slug: links.PRESTATION_SLUG,
        componentKey: 'Prestation',
      },

      {
        id: 'collection',
        title: 'Nouvelle collection',
        link: links.Our_COLLECTION_LINK,
        componentKey: 'OurCollection',
      },
      {
        id: 'innovation',
        title: 'Innovation Hairlinematch',
        link: links.INNOVATION_LINK,
        componentKey: 'Hairmatch',
      },
      {
        id: 'fidelite',
        title: 'Fidélité récompensée',
        link: links.FIDELITE_LINK,
        componentKey: 'Loyality',
      },
      {
        id: 'manufacturing',
        title: 'Tarifs et Délais de Fabrication',
        link: links.MANUFACTURING_LINK,
        componentKey: 'Manufacturing',
      },
    ],
  },

  {
    id: 'prise-en-charge',
    title: 'Prise en charge médicale',
    link: links.PRISE_EN_CHARGE_LINK,
    componentKey: 'Helthcare',
    exact: true,
  },
  {
    id: 'confiance',
    title: 'Elles nous font confiance',
    link: links.CONFIANCE_LINK,
    componentKey: 'Confiance',
    exact: true,
    paramsLink: {
      link: links.EVENT_DETAILS_LINK,
      componentKey: 'EventDetail',
    },
  },
  {
    id: 'blog',
    title: 'Blog',
    link: links.BLOG_LINK,
    componentKey: 'Blog',
    exact: true,
    paramsLink: {
      link: links.BLOG_DETAILS_LINK,
      componentKey: 'BlogDetail',
    },
  },
  {
    id: 'contact',
    title: 'Contact',
    link: links.CONTACT_LINK,
    componentKey: 'Contact',
    exact: true,
  },
  // {
  //   id: 'offres',
  //   title: "NOS OFFRES D'EMPLOI",
  //   link: links.OFFRES_LINK,
  //   componentId: OFFRE_COMPONENT_SLUG,
  //   exact: true,
  //   paramsLink: {
  //     link: links.OFFRES_DETAILS_LINK,
  //     componentId: OFFRE_DETAILS_COMPONENT_SLUG,
  //   },
  // },
];

export const buildMenuConfig = (defaultMenu = nativetMenu) => {
  const outMenuLinks = [
    {
      id: 'condition',
      link: links.CONDITION_LINK,
      componentKey: 'Conditions',
      exact: true,
    },
    {
      id: 'newpassword',
      link: links.NEW_PASSWORD_LINK,
      componentKey: 'NewPassword',
      exact: true,
    },
    {
      id: 'newpassword',
      link: links.NEW_PASSWORD_LINK,
      componentKey: 'NewPassword',
      exact: true,
    },
    {
      id: 'socialconnect',
      link: links.SOCIAL_CONNECT_LINK,
      componentKey: 'Home',
      exact: true,
    },

    {
      id: 'produits',
      link: links.PRODUCTS_LINK,
      componentKey: 'Produits',
      exact: true,
      paramsLink: {
        link: links.PRODUCT_DETAIL_LINK,
        componentKey: 'SingleProduct',
      },
    },
  ];

  const privatesRoutes = [
    {
      id: 'user-profile',
      link: links.USER_PROFILE_LINK,
      componentKey: 'UserProfile',
      exact: true,
      protected: true,
    },

    {
      id: 'order_confirm',
      link: links.ORDER_CONFIRM_LINK,
      componentKey: 'OrderDetail',
      protected: true,
    },

    {
      id: 'paiments',
      link: links.PAYMENT_LINK,
      componentKey: 'CheckoutPayments',
      exact: true,
      protected: true,
    },
    {
      id: 'orderDetails',
      link: links.ORDER_DETAIL_LINK,
      componentKey: 'OrderDetail',
      exact: true,
    },

    {
      id: 'checkout',
      link: links.CHECKOUT_LINK,
      componentKey: 'Checkout',
      exact: true,
      protected: true,
    },

    ...defaultMenu.filter((item) => item.protected),
  ];

  // filter from menu
  let submenus = [];

  let populatedMenu = defaultMenu.filter((item) => item.submenus);
  for (let index = 0; index < populatedMenu.length; index++) {
    submenus = [...submenus, ...populatedMenu[index].submenus];
  }

  let menus = defaultMenu.filter((item) => !item.submenus);

  menus = menus.filter((item) => !item.protected);

  let paramsLinks = menus
    .filter((item) => item.paramsLink)
    .map((item) => item.paramsLink);

  paramsLinks = [
    ...paramsLinks,
    ...outMenuLinks
      .filter((item) => item.paramsLink)
      .map((item) => item.paramsLink),
  ];

  const outLinkedMenu = defaultMenu.filter((item) => item.outLinkedMenu);

  return {
    defaultMenu,
    outMenuLinks,
    menus,
    submenus,
    paramsLinks,
    privatesRoutes,
    outLinkedMenu,
  };
};
