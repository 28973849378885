import React from 'react';
import { Col, Layout, Row } from 'antd';
import { ReactComponent as FooterImage } from '../../assets/photos/footer.svg';
import { NavHashLink } from 'react-router-hash-link';
import Img from 'react-cool-img';

import styled from 'styled-components';
import * as links from '../../Routes/links';
import SocialRow from '../../components/SocialRow';
import Cookies from '../../components/cookie';
import './style.less';

import { mentiOnText } from './config';
import { getFileUrl, isValidValue } from '../../utils';
import DangeriousHtml from '../../components/DangeriousHtml';

const FooterLg = ({ data }) => {
  //

  const {
    logo,
    columns = [],
    fbk_url,
    twiter_url,
    youtube_url,
    insta_url,
    address,
  } = data;
  const displayFotterSection = (section, second = false) => (
    <div className="footer-item">
      <span className={`item-header ${second ? 'second' : ''}`}>
        {section.title}
      </span>

      {(section.items || section.submenus).map((item, index) =>
        displayMenuItem(item, index)
      )}
    </div>
  );

  const displayMenuItem = (item, index) =>
    item.isExternalLink ? (
      <SimpleLink key={index} href={item.link} className="menu-item">
        {item.title}
      </SimpleLink>
    ) : (
      <HashLink title={item.title} link={item.link} key={index} />
    );

  return (
    <Layout.Footer className="app-footer">
      <div
        style={{
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <FooterImage />
      </div>

      <div className="container">
        <Row gutter={[50, 0]}>
          <Col flex="0.7">
            <a href={links.HOME_LINK}>
              <Img
                className="app-logo"
                src={getFileUrl(logo)}
                alt="L'Artisan du sublime"
              />
            </a>
          </Col>

          <Col flex="1">
            {isValidValue(address) && (
              <div className="address-description">
                <DangeriousHtml value={address} />
              </div>
            )}

            <div className="socialrow">
              <SocialRow
                fbkUrl={fbk_url}
                twiterUrl={twiter_url}
                youtubeUrl={youtube_url}
                insta_url={insta_url}
              />
            </div>

            <HashLink
              title={mentiOnText}
              className="condition"
              link={links.CONDITION_LINK}
            />
          </Col>

          {isValidValue(columns) &&
            columns.map((item, index) => (
              <Col flex="1" key={index}>
                {displayFotterSection(item)}
              </Col>
            ))}
        </Row>
      </div>

      <Cookies />
    </Layout.Footer>
  );
};

export default FooterLg;

const CHashLink = styled(NavHashLink)`
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 239.5%;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #333333;
  letter-spacing: 0.03em;
  display: block;
  &:hover {
    color: #863b49;
  }
`;

const SimpleLink = styled.a`
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 239.5%;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #333333;
  letter-spacing: 0.03em;
  display: block;
  &:hover {
    color: #863b49;
  }
`;

const HashLink = ({ link, title }) => (
  <CHashLink to={`${link}#top`}> {title} </CHashLink>
);
