import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import './style.less';
import { isValidValue } from '../../utils';

const SearchField = ({ visible, setVisible, pathname }) => {
  const onClick = () => setVisible(!visible);

  const className = visible ? '-visible' : '-hidden';
  const [value, setValue] = React.useState('');
  React.useEffect(() => {
    setValue('');
    // 
  }, [pathname]);

  React.useEffect(() => {
    const element = document.getElementById('search-input');
    if (visible && isValidValue(element)) {
      element.focus();
      element.select();
    }
    // 
  }, [visible]);

  

  return (
    <div className="search-wrapper">
      <Input
        id="search-input"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        allowClear
        size="small"
        placeholder="Rechercher"
        className={'search-input' + className}
      />
      <CloseOutlined
        className={'search-icon search-icon' + className}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      />
    </div>
  );
};

export default SearchField;
