import React from 'react';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from './stores/app/actions';

import { CookiesProvider } from 'react-cookie';
import './assets/fonts/style.css';
import './assets/fonts/VisbyCF/style.css';
import './styles/index.less';
import { CloseOutlined } from '@ant-design/icons';

import { Layout } from 'antd';
import Routes from './Routes';
import Header from './Layout/Header';
import Footer from './Layout/footer';
import { BrowserRouter } from 'react-router-dom';
import { Modal } from 'antd';
import Signup from './pages/eshop/signup';
import Signin from './pages/eshop/signin';
import { SWRConfig } from 'swr';

import {
  setAuthFromLocaldata,
  setLinkedAuthAction,
} from './stores/auth/actions';

import StateDataFetcher from './components/StateDataFetcher';
import { nextedAuthSelector } from './stores/auth/selector';
import { MODALPAGES } from './stores/app/reducer';
import { typeModal } from './stores/app/selector';


const { Content } = Layout;

const App = () => {
  const dispatch = useDispatch();
  const showLoginModal = useSelector(typeModal);
  const { mustAuth, nextAuthedLink } = useSelector(nextedAuthSelector);

  React.useEffect(() => {
    if (mustAuth)
      dispatch(openModal({ type: MODALPAGES.SIGNIN, nextUrl: nextAuthedLink }));
  }, [mustAuth]);

  React.useEffect(() => {
    dispatch(setAuthFromLocaldata());
  }, []);

  const handleCloseModal = async () => {
    dispatch(openModal({ type: MODALPAGES.NONE }));
    dispatch(setLinkedAuthAction());
  };
  return (
    <>
      <Layout style={{ overflowX: 'hidden', ...style }}>
        <BrowserRouter>
          <SWRConfig value={config}>
            <CookiesProvider>
              <StateDataFetcher />
              <Header />

              <Content>
                <Modal
                  closable={false}
                  width={'70%'}
                  footer={null}
                  open={showLoginModal}
                  wrapClassName="bg-opacity"
                >
                  <Button
                    className="modal-closebtn"
                    icon={<CloseOutlined />}
                    onClick={handleCloseModal}
                  />
                  {showLoginModal === MODALPAGES.SIGINUP ? (
                    <Signup open={true} />
                  ) : (
                    <Signin open={true} />
                  )}
                </Modal>

                <Routes />
              </Content>
              <Footer />
            </CookiesProvider>
          </SWRConfig>
        </BrowserRouter>
      </Layout>
    </>
  );
};

export default React.memo(App);

const style = {
  background:
    'linear-gradient(0deg, rgba(247,242,233,1) 500px, rgba(255,255,255,1) 900px, rgba(255,255,255,1) 97%, rgba(247,242,233,1) 100%)',
};

const fetcher = async (url) => {
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

const config = {
  refreshInterval: 0,
  fetcher,
};
