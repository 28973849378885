import * as types from './types';

const INITIAL_STATE = {};

const cartReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SET_VITRIN_DATA:
      return { ...state,  ...payload  };

    default:
      return {
        ...state,
      };
  }
};

export default cartReducer;

export const key = 'vitrin';
