import { Button } from 'antd';
import React from 'react';

const PromoField = ({ onApply, promoCode = '' }) => {
  const [value, setValue] = React.useState(promoCode);
  const [state, setState] = React.useState({ loading: false });
  const { loading, isError } = state;

  React.useEffect(() => {
    setValue(promoCode);
    if (promoCode) onApply(promoCode);
    // 
  }, [promoCode]);

  const handleSubmit = async (e) => {
    // e.stopPropagation();
    setState({ ...state, loading: true });

    if (!onApply) return;
    const result = await onApply(value);
    const isError = result.error === true;
    setState({ ...state, loading: false, isError });
  };

  const onChange = (e) => {
    setValue(e.target.value);
    setState({ ...state, isError: false });
  };

  const disable = !value || value.lenght === 0;

  return (
    <div className="command-recap__promo">
      <div className="input-row">
        <input
          onChange={onChange}
          value={value}
          type="text"
          className="promo-code"
          placeholder="Appliquer le code promo"
          defaultValue={promoCode}
        />

        {isError && (
          <div className="signin-error">
            <p className="signin-error__text mobile-align-center">
              Code promo non valide
            </p>
          </div>
        )}
      </div>

      <div>
        <Button
          disabled={disable}
          className="apply-button"
          onClick={handleSubmit}
          loading={loading}
        >
          <span className="command-recap__apply command-recap__span ">
            Appliquer
          </span>
        </Button>
      </div>
    </div>
  );
};

export default PromoField;
