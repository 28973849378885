import moment from 'moment';
import { BASE_URL } from './api/config';
moment.locale('fr', {
  months:
    'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
      '_'
    ),
  monthsShort:
    'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact: true,
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
  calendar: {
    sameDay: '[Aujourd’hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans',
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

const removeNullField = (val = {}) => {
  const result = { ...val };

  for (const [key, value] of Object.entries(val)) {
    if (value === null) delete result[key];
  }
  return result;
};

const checkBreackPoint = (point, authorized = [], screens = {}) => {
  const breacks = { ...screens };
  const val = screens[point];
  delete breacks[point];

  for (let index = 0; index < authorized.length; index++) {
    const element = authorized[index];
    delete breacks[element];
  }

  if (!val) return false;

  const otherBreack = Object.values(breacks).filter((item) => item);

  if (otherBreack.length === 0) return true;
  return false;
};

const getDateCalendar = (date, format = 'MMMM YYYY') => {
  // return moment(date).locale('fr').format();
  var localLocale = moment(date);
  localLocale.locale('fr');

  return localLocale.format(format);
};

const getFileUrl = (file, params = {}) => {
  if (!file || file === null) return '';
  const { xs = false, small = false, md = false, lg = true } = params;

  if (file.formats) {
    if (xs && file.formats.thumbnail) {
      return `${BASE_URL}${(file.formats.thumbnail || {}).url}`;
    }
    if (small && file.formats.small) {
      return `${BASE_URL}${(file.formats.small || {}).url}`;
    }

    if (md && file.formats.medium) {
      return `${BASE_URL}${(file.formats.medium || {}).url}`;
    }

    if (lg && file.formats.large) {
      return `${BASE_URL}${(file.formats.large || {}).url}`;
    }
  }

  return `${BASE_URL}${(file || {}).url}`;
};

// const getFileUrlThumbnail = (file) => {
//   if (!file) return '';
//   else return `${BASE_URL}${(file.formats.thumbnail || {}).url}`;
// };

const buildImagesUrl = async (imgs = [], small = false) => {
  if (!imgs || imgs === null) return [];

  return imgs.map((item) => getFileUrl(item, { small }));
};

const buildImageItemUrl = (img = {}, defaultImage = '') => {
  return img ? getFileUrl(img) : defaultImage;
};

const getNavHashTo = (win, link = win.location.pathname) => {
  //

  var a = document.createElement('a');
  a.href = link;
  const frontBaseUrl = win.location.origin;

  const isExternal = a.origin !== frontBaseUrl;
  const navTo = { to: { pathname: link.replace(frontBaseUrl + '/', '') } };
  return isExternal ? { ...navTo, target: '_blank' } : navTo;
};

const isValidValue = (val) => {
  return val !== undefined && val !== null;
};

export {
  removeNullField,
  checkBreackPoint,
  getDateCalendar,
  buildImagesUrl,
  buildImageItemUrl,
  isValidValue,
  getFileUrl,
  // getFileUrlThumbnail,
  getNavHashTo,
};
