import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Spiner = ({ className, ...props }) => {
  return (
    <ContainerDiv className={`container ${className}`} {...props}>
      <Spin indicator={antIcon} size="large" />
    </ContainerDiv>
  );
};

const antIcon = <LoadingOutlined style={{ fontSize: 70 }} spin />;

export default Spiner;

export { antIcon as icon };

const ContainerDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
