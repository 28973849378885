import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './style.less';
import {
  CATEGORIES_DETAILS_LINK,
  CATEGORIES_SLUG,
  PRODUCTS_LINK,
} from '../../../Routes/links';

import { getAllProducts, getCategories, getFeaturedSelections, } from '../../../stores/shop/actions';

import BannerSection from '../../home/HomeSections/BannerSection';

import queryString from 'query-string';
import Products from './Products';
import FilterSearch from './FilterSearch';
import { fetchCatgory, fetchFilters } from '../../../stores/shop/api';
import { MOBILE_SIZE } from '../../../config';
import Spiner from '../../../components/Spiner';
import FeaturedSelection from './FeaturedSelection';
import useVitrinFetcher2 from '../../../stores/vitrin/hooks2';
import { isValidValue } from '../../../utils';
import useWindowSize from '../../../hooks/useWindowSize';
import axios from 'axios';

const Shop = React.memo(({
  allProducts, location, getAllProducts, match, getFeaturedSelections,
}) => {

  const [appointmentLink,setAppointmentLink] = useState('')
  const [filters,setFilters] = useState([])
  
  useEffect(()=>{
    async function getRdvLink(){
      var link = (await axios.get(process.env.REACT_APP_BACKOFFICE_URL+"/configuration"))?.data.prendreRdv
      setAppointmentLink(link)
      fetchFilters().then((filters=>{
        setFilters(filters)
      }))
    }
    getRdvLink()
  },[])
  useEffect(()=>{},[appointmentLink])

  const screen = useWindowSize({ width: window.innerWidth });
  const { slug } = match.params || {};
  const history = useHistory();
  const [category, setCategory] = React.useState();
  const isMobile = screen.width < MOBILE_SIZE
  const [state, setState] = React.useState({
    showFilter: false,
    isLoading: true,
    radioGroup: {},
    page: 0,
    products: allProducts,
    filtervalue: {},
    openCategories: true,
    queryObject: {},
  });

  const { 
    showFilter, products, filtervalue, _sort, filterFromUrl, openCategories, page, isLoading, isSimpleFetcher, queryObject, selectionsFeatured, _q, 
  } = state;

  const eshopData = useVitrinFetcher2({
    endpoints: 'eshop',
    name: NAME,
  });

  useVitrinFetcher2({
    endpoints: 'featured-selections',
    name: FEATURED_SELECTION_NAME,
  });

  React.useEffect(() => {
    setParamsFromUrl();
  }, [location, eshopData, isMobile]);

  React.useEffect(() => {
    fetchCategory();
    setTimeout(() => scroolToUp(), 2000);
  }, [match.params.slug]);

  

  const triggerSearch = (filter)=> {
    reloadProductsPage(filter,0)
  }
  const setParamsFromUrl = async () => {
    scroolToUp();
    setState((state) => ({ ...state, isLoading: true }));
    const params = queryString.parse(location.search);
    const newState = await fetchProducts(params);
    
    const { _q, filtervalue, page } = newState;

    const isSimpleFetcher = verifySimpleFetcher({
      _q,
      filtervalue,
      page,
    });

    let selections = selectionsFeatured;

    if (isSimpleFetcher) {
      selections = await getFeaturedSelections(queryObject);
    }

    setState((state) => ({
      ...state,
      ...newState,
      isSimpleFetcher,
      isLoading: false,
      selectionsFeatured: selections,
    }));
  };

  const scroolToUp = () => {
    const tagId = isMobile ? 'essayer-line-mobile' : 'essayer-line';
    document.getElementById(tagId) && document.body.scroll({
      top:document.getElementById(tagId).offsetTop,
      behavior: 'smooth'
    })
  };
  const getLink = (direc = slug) => {
    const { path } = match;
    if (path === CATEGORIES_DETAILS_LINK) return CATEGORIES_SLUG + '/' + direc;
    return PRODUCTS_LINK;
  };

  const reloadProductsPage = (filter, page) => {
    const search = queryString.stringify({ ...filter, page: page + 1 });
    history.push({ pathname: getLink(), search }, { products }); 
  };

  const getPerPageFromBo = () => {
    let _limit = isMobile ? 10: 9;
    if (isValidValue(eshopData) && isValidValue(eshopData.pagination)) {
      const { per_page_mobile, per_page_web } = eshopData.pagination;
      _limit = isMobile ? per_page_mobile : per_page_web;
    }
    return _limit;
  };

  const fetchProducts = async ({ _q, _sort, filter, page = 1, ...rest },{ signal } = {}) => {
    const pageVal = parseInt(page);
    const vraiPage = pageVal > 0 ? pageVal - 1 : pageVal;
    const categoryFilter = slug ? { category: slug } : {};
    let sort = { _sort: _sort };
    try {
      const queryObject = {
        _q,
        ...sort,
        filter,
        page: vraiPage,
        ...categoryFilter,
        ...rest,
        _limit: getPerPageFromBo(),
      };
      
      var res = await getAllProducts(queryObject, isMobile,{ signal });
      
      const newCOnfig = {
        ...state,
        products: res,
        _sort,
        _q,
        filtervalue: { _sort, _q, ...rest },
        filterFromUrl: { ...rest },
        page: vraiPage,
        isLoading: false,
        queryObject,
      };
      return newCOnfig;
    } catch (error) {
      console.log({ error });
      return {};
    }
  };

  const verifySimpleFetcher = ({ _q, filtervalue = {}, page }) => {
    const { _sort, ...rest } = filtervalue;
    return !_q && !_sort && Object.keys(rest).length === 0 && page <= 0;
  };

  const fetchCategory = async () => {
    let value;
    if (slug) {
      value = await fetchCatgory(slug);
      setCategory(value);
    }
  };

  const hide = () => {
    if (showFilter) {
      setState({ ...state, showFilter: false });
    }
  };

  const setOpenCat = () =>
    setState((state) => ({ ...state, openCategories: !openCategories }));

  const displayBanner = () => {
    const { banner } = eshopData || {};

    if (!banner || banner === null) return;

    return (
      <div className="container">
        <BannerSection
          normalHeaderText={banner.titre_normal}
          italicHeadertext={banner.titre_italique}
          banner={[banner]}
        />
      </div>
    );
  };

  const { rdvButton, rdvMessage } = eshopData || {};
  
  return (
    <div
      onClick={(e) => {
        hide();
      }}
      id="shop"
    >
      {displayBanner()}

      <div id="essayer-line-mobile"></div>
      <div id="essayer-line" style={{ marginTop: '50px' }}></div>

      {rdvMessage && (
        <div className="shop-band container">
          <span className="shop-band-text">{rdvMessage}</span>
          <a
            rel="noreferrer"
            target={"_blank"}
            href={appointmentLink}
            className="shop-band-text-more"
          >
            {rdvButton || 'En savoir plus'}
          </a>
        </div>
      )}

      <div className="container" id="search">
        <FilterSearch
          _sort={_sort}
          _q={_q}
          allFilters={filters}
          filtervalue={filtervalue}
          filterFromUrl={filterFromUrl}
          triggerSearch={triggerSearch}
        />

        <div className="shop-wrapper">
          {isSimpleFetcher && (
            <FeaturedSelection
              isSimpleFetcher={isSimpleFetcher}
              queryObject={queryObject}
              category={category}
            />
          )}

          {isLoading ? (
            <Spiner />
          ) : (
            <Products
              products={products}
              openCat={openCategories}
              setOpenCat={setOpenCat}
              reloadProductsPage={reloadProductsPage}
              isMobile={isMobile}
              page={page}
              filtervalue={filtervalue}
              category={category}
            />
          )}
        </div>
      </div>
    </div>
  );
});

const NAME = 'eshop';
const FEATURED_SELECTION_NAME = 'featuredSelection';
const mapStateToProps = (state) => {
  return {
    allProducts: state.shop.catalog,
    categories: state.shop.categories,
  };
};

export default connect(mapStateToProps, {
  getAllProducts,
  getCategories,
  getFeaturedSelections,
})(Shop);
