import './style.less';
import { Row, Col } from 'antd';
import { NavHashLink } from 'react-router-hash-link';
import { getFileUrl } from '../../utils';
import ArticleBlogCard from '../home/ArticleBlogCard/ArticleBlogCard';

const Related = ({ others }) => {
  return (
    <>
      <Row className="BlogImage" gutter={16}>
        {others.map((item, index) => (
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            key={index}
            className="space-content"
          >
            <NavHashLink to={`/blogs/${item.slug}/#top`}>
              <ArticleBlogCard
                headerText={`${item.post[0].normal_title} ${item.post[0].italic_title}`}
                image={getFileUrl(item.post[0].mobile_media)}
              />
            </NavHashLink>
          </Col>
        ))}
      </Row>
    </>
  );
};
export default Related;
