import React from 'react';
import openNotificationWithIcon from '../../../components/notification';
import ResumeCommande from '../ResumeCommande/resumeCommande';
import { useHistory } from 'react-router-dom';
import PaymentFields from './Fields';
import './style.less';
import { Row, Col, Form } from 'antd';

import { ORDER_CONFIRM_SLUG } from '../../../Routes/links';

import queryString from 'query-string';
import { pushTRansactionOnTagManager } from '../../../stores/tagLayerUtils';

const PaymentContent = ({
  cart,
  removeFromCart,
  submitOrder,
  clearCart,
  location,
  tempOrder,
}) => {
  //

  const checkoutUrl = window.location.href;
  const history = useHistory();

  const [order, setOrder] = React.useState(tempOrder);

  const handleSuccessNotification = () => {
    openNotificationWithIcon({
      type: 'success',
      description: 'Votre commande a été valider avec success',
      message: '',
      // icon: <NotificationIcon />,
    });
  };

  const handleErrorNotification = (errorMessage) => {
    openNotificationWithIcon({
      type: 'error',
      description: "",
      message: errorMessage,
      // icon: <NotificationIcon />,
    });
  };

  const postOrderCallBack = (response) => {
    if (!response || response.error !== undefined)
      return handleErrorNotification(response.error);

    handleSuccessNotification();
    pushTRansactionOnTagManager(window, response);
    clearCart && clearCart();
    localStorage.removeItem('newOrder');
    localStorage.removeItem('tempOrder');
    localStorage.setItem('user', JSON.stringify(response.user));
    history.push(ORDER_CONFIRM_SLUG + '/' + response.id + '#top');
  };

  const submitAlmaPaid = async (order, tokenId) => {
    let data = { ...order, method: 'alma', token: tokenId };
    if (!submitOrder) return;

    const response = await submitOrder(data);

    postOrderCallBack(response);
  };

  const initializeOrder = async () => {
    const params = queryString.parse(location.search);

    if (params && params.pid) submitAlmaPaid(order, params.pid);
    else setOrder(order);
  };

  React.useEffect(() => {
    initializeOrder();
    
  }, []);

  const gutter = [{ lg: 70, md: 50, sm: 30 }, 50];

  return (
    <Row gutter={gutter}>
      <Col md={14} xs={24}>
        <Form.Item name="method" noStyle>
          <PaymentFields
            order={order}
            checkoutUrl={checkoutUrl}
            submitOrder={submitOrder}
            clearCart={clearCart}
            showSuccess={handleSuccessNotification}
            postOrderCallBack={postOrderCallBack}
          />
        </Form.Item>
      </Col>

      <Col md={10} xs={24}>
        {cart && (
          <ResumeCommande
            cart={order}
            removeFromCart={removeFromCart}
            order={order}
            canSetContent={false}
          />
        )}
      </Col>
    </Row>
  );
};

export default PaymentContent;
