import React from 'react';
import Img from 'react-cool-img';
import { Row, Col } from 'antd';
import rightArrow from '../../../assets/images/right-arrow.svg';

import WishLogo_ from '../../../assets/icons/heart_onclick.svg';
import WishLogo from '../../../assets/icons/heart.svg';
import { useDispatch, useSelector } from 'react-redux';

import { addWishNotification, removeWishNotification } from './notifications';
import { openModal } from '../../../stores/app/actions';
import { isAuthSelector } from '../../../stores/auth/selector';

import ImagesPreviewer from '../../../components/ImagesPreviewer';

import { getFileUrl, isValidValue } from '../../../utils';
import { MODALPAGES } from '../../../stores/app/reducer';
import useWindowSize from '../../../hooks/useWindowSize';

const ImagesCarroussel = ({ product, addToWishlist, forcedImage }) => {
  //

  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < 768;

  const [state, setState] = React.useState({
    currentPosition: 1,
    slides: [],
    openPriviewer: false,
  });

  React.useEffect(() => {
    if (forcedImage)
      setState((state) => ({ ...state, imageForced: forcedImage }));
  }, [forcedImage]);

  const {
    currentSlide,
    currentPosition,
    onWish,
    slides,
    openPriviewer,
    imageForced,
  } = state;

  React.useEffect(() => {
    if (product) {
      const { GalleryImgs = [], image } = product;
      let slides = [];

      const index = GalleryImgs.findIndex((item) => item?.name === image?.name);

      if (index === -1) slides = [image];

      if (GalleryImgs && GalleryImgs !== null)
        slides = [...slides, ...GalleryImgs];

      let currentSlide = slides[currentPosition - 1]; // variable index value we can reference later

      setState({
        ...state,
        slides,
        currentSlide,
        imageForced: false,
      });
    }
    // 
  }, [currentPosition, product]);

  const handleAddToWish = (e) => {
    e.stopPropagation();

    if (!isAuth) return dispatch(openModal({ type: MODALPAGES.SIGNIN }));

    const done = addToWishlist({ id: product.id });
    if (done) {
      onClickWish('');
    }
  };

  const onClickWish = () => {
    if (!onWish) addWishNotification();
    else removeWishNotification();
    setState({ ...state, onWish: !onWish });
  };

  const arrowRightClick = () => {
    if (currentPosition < slides.length && currentPosition < 8) {
      setState({ ...state, currentPosition: currentPosition + 1 });
    } else {
      setState({
        ...state,
        currentPosition: 1,
      });
    }
  };

  const arrowLeftClick = () => {
    if (currentPosition > 1) {
      setState({ ...state, currentPosition: currentPosition - 1 });
    } else {
      if (slides.length > 8) {
        setState({
          ...state,
          currentPosition: currentPosition + 7,
        });
      } else
        setState({
          ...state,
          currentPosition: slides.length,
        });
    }
  };

  const onImageClick = (i) => {
    setState({
      ...state,
      currentPosition: i + 1,
    });
  };

  const setOpenPriviewer = (val) => {
    setState((state) => ({ ...state, openPriviewer: val }));
  };

  const getCurrentImage = () => {
    if (imageForced) return imageForced;
    return currentSlide;
  };

  const imgBreackpoint = isMobile ? { small: true } : { md: true };

  return (
    <div className="product-section-img1">
      <div className="product-section-img-icons">
        <Img
          className="img-big"
          src={getFileUrl(getCurrentImage(), imgBreackpoint)}
          alt="Image vue de droite"
          onClick={() => setOpenPriviewer(true)}
        />

        <ImagesPreviewer
          open={openPriviewer}
          setOPen={(val) => setOpenPriviewer(val)}
          images={slides}
        />

        <div className="icons">
          <button className="img-heart" onClick={handleAddToWish}>
            <Img src={onWish ? WishLogo_ : WishLogo} alt="Like" />
          </button>
          <button className="img-right-arrow" onClick={arrowRightClick}>
            <Img src={rightArrow} alt="Flèche droite" />
          </button>
          <button className="img-left-arrow" onClick={arrowLeftClick}>
            <Img src={rightArrow} alt="Flèche gauche" />
          </button>
        </div>
      </div>
      <div className="product-section-img-views">
        <Row gutter={[10, 10]} className="row-front">
          {slides.slice(0, 8).map(
            (image, index) =>
              isValidValue(image) && (
                <Col key={image.id} span={6} className="front">
                  <Img
                    src={getFileUrl(image, { xs: true })}
                    alt="Image vue de gauche"
                    onClick={() => onImageClick(index)}
                  />
                </Col>
              )
          )}
        </Row>
      </div>
    </div>
  );
};

export default ImagesCarroussel;
