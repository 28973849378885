import { getVitrinDataApi } from './api';
import * as types from './types';

export const setVitrinData =
  ({ endpoints, name }) =>
  async (dispatch) => {
    const data = await getVitrinDataApi(endpoints);

    dispatch({
      type: types.SET_VITRIN_DATA,
      payload: { [name]: data },
    });
    return data
  };
