import React from 'react';
import moment from 'moment';

const CalendarColumn = ({ data }) => {
  //

  return (
    <div>
      <div className="calendar-header-date">
        <span>{data.litteral}</span>
      </div>

      <div className="calendar-header-hours">
        {data.disponibility.map((item) => (
          <span key={item.start} className="k-hour">
            {moment(item.start).format('hh:mm')}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CalendarColumn;
