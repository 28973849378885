import React from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

const { SubMenu } = Menu;

const AppMenuList = ({ menus = [], onClose }) => {
  //

  let { pathname } = useLocation();

  const [current, setCurrent] = React.useState(pathname);

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const displaySubMenu = (item) => {
    const { submenus = [] } = item;

    return (
      <SubMenu
        className="menu-item"
        key={item.id}
        title={item.title}
        onClick={() => onClose()}
      >
        {submenus
          .filter((item) => !item.outLinkedMenu && item.outMenus !== true)
          .map((sub) => (
            <Menu.Item key={sub.link} className="submenu-item">
              <CustomLink link={sub.link} title={sub.title} />
            </Menu.Item>
          ))}
      </SubMenu>
    );
  };

  const displayMenu = (item) => (
    <Menu.Item key={item.link} onClick={() => onClose()} className="menu-item">
      <CustomLink link={item.link} title={item.title} />
    </Menu.Item>
  );

  return (
    <div>
      <Menu
        onClick={handleClick}
        className="app-menu-list"
        selectedKeys={[current]}
        mode="inline"
      >
        {menus
          .filter((item) => !item.outLinkedMenu && item.outMenus !== true)
          .map((item) =>
            item.submenus ? displaySubMenu(item) : displayMenu(item)
          )}
      </Menu>
    </div>
  );
};

export default AppMenuList;

const CustomLink = ({ title, link, ...props }) => (
  <NavHashLink to={`${link}#top`} {...props}>
    {title}
  </NavHashLink>
);
