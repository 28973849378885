import { UPDATE_PASS, REQUEST_TOKEN } from './types';

const initialState = {
  current: 0,
  email: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TOKEN:
      return {
        current: (state.current += action.value),
      };

    case UPDATE_PASS:
      return {
        current: (state.current -= action.value),
      };

    // case 'ADD_TASK':
    //   const newState = { ...state }
    //   return Object.assign(newState, { items: [...state.items, action.value] });
    // case 'DELETE_LAST_TASK':
    //   const newState = { ...state }

    default:
      return state;
  }
}
