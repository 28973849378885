import { Button } from 'antd';
import React from 'react';
import './adsButton.less';

import { NavHashLink } from 'react-router-hash-link';

const AdsButton = ({
  onClick,
  text="AJOUTER AU PANIER",
  disabled = false,
  customBtnStyle = {},
  children,
  href = '#',
  sm = false,
  loading = false,
  isSubmitButton = false,
  htmlType = isSubmitButton ? 'submit' : 'default',
}) => {
  //

  const styleSm = {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '12px',
    width: '100%',
    paddingBottom: '12px',
  };

  const sizeStyle = sm === true ? styleSm : {};
  return isSubmitButton || onClick ? (
    <Button
      htmlType={htmlType}
      onClick={onClick}
      className="base-ads-btn"
      disabled={disabled}
      style={{ ...customBtnStyle, ...sizeStyle }}
      loading={loading}
    >
      {text}
    </Button>
  ) : (
    <NavHashLink to={href || "#"}>
      <Button
        htmlType={htmlType}
        onClick={onClick}
        className="base-ads-btn"
        disabled={disabled}
        style={{ ...customBtnStyle, ...sizeStyle }}
        loading={loading}
      >
        {text}
      </Button>
    </NavHashLink>
  );
};

export default AdsButton;
