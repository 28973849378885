import './style.less';

import BannerSection from '../home/HomeSections/BannerSection';
import SublimeLogo from '../../assets/images/loyality/sublime-logo.png';

import { MOBILE_SIZE } from '../../config';
import useWindowSize from '../../hooks/useWindowSize';
import SublimeSignature from '../home/SublimeSignature/SublimeSignature';
import AdsButton from '../home/Button/AdsButton';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import HTMLReactParser from 'html-react-parser';
import * as actions from '../../stores/vitrin/actions';
import { vitrinSelector } from '../../stores/vitrin/selector';
import { getFileUrl, isValidValue } from '../../utils';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';

const Loyality = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'fidelite-recompensee',
    name: NAME,
  });

  useHashScrooler({ location, data });

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  const tableDesktop = (avantages) => {
    if (!isValidValue(avantages)) return <></>;

    const { columnsButtons } = avantages;

    // modify what's coming from the back-office to create customizable table cells
    const preAdvList = avantages.description;
    const advList = preAdvList.replace(
      /<img src=\"/g,
      '<img src="' + process.env.REACT_APP_BACKOFFICE_URL
    );
    const preDiv = advList.replace(
      /<p style=\"text-align:center;\"><span class=\"text-big\">/g,
      '<div class=card-title><p style="text-align:center;"><span class="text-big">'
    );
    const postDiv = preDiv.replaceAll('</figure><td>', '</figure></div></td>');
    return (
      <>
        <div className="table-desktop">{HTMLReactParser(`${postDiv}`)}</div>
        {isValidValue(columnsButtons) && (
          <div className="buttons">
            {columnsButtons.map((item) => (
              <div className="inscrire-button-wrapper" key={item.id}>
                <AdsButton
                  text={item.label}
                  customBtnStyle={{ borderColor: item.color || '#E9CEB5' }}
                  href={item.Slug}
                />
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const tableMobile = (avantagesMobile) => {
    if (!isValidValue(avantagesMobile)) return;
    return (
      <div className="table-mobile">
        {avantagesMobile.map((item) => {
          const preList = item.description;
          const advList = preList.replace(
            /<img src=\"/g,
            '<img src="' + process.env.REACT_APP_BACKOFFICE_URL
          );
          const preDiv = advList.replace(
            /<p style=\"text-align:center;\">/g,
            '<div class=card-title><p style="text-align:center;">'
          );
          const postDiv = preDiv.replaceAll(
            '</figure><td>',
            '</figure></div></td>'
          );

          return (
            <SectionWrapper
              hashId={item.hashId}
              key={item.id}
              className="cardwrapper"
            >
              <div className="member-card">{HTMLReactParser(`${postDiv}`)}</div>
              <div className="inscrire-button-wrapper">
                <AdsButton
                  text={item.buttons?.label}
                  customBtnStyle={{ borderColor: item.buttons?.color }}
                  href={item.buttons?.Slug}
                />
              </div>
            </SectionWrapper>
          );
        })}
      </div>
    );
  };

  if (!data) return <Spiner />;

  return (
    <div id="loyality" className="container">
      <div className="banner-section">
        <BannerSection
          normalHeaderText={data.banniere?.titre_normal}
          italicHeadertext={data.banniere?.titre_italique}
          banner={[data.banniere]}
        />
      </div>

      <div className="fidelite-recompense-section">
        <SectionWrapper
          hashId={data.hashId}
          className="fidelite-header-section"
        >
          {data.title}
        </SectionWrapper>

        <div className="fidelite-intro-section">
          {HTMLReactParser(`${data.description}`)}
        </div>

        <div className="sublime-signature-wrapper">
          <SublimeSignature
            signature_img={SublimeLogo}
            widthValue={isMobile ? 84 : 244}
            heightValue={isMobile ? 84 : 244}
          />
        </div>
      </div>
      {isMobile
        ? tableMobile(data.avantagesMobile)
        : tableDesktop(data.avantages)}

      <SectionWrapper
        hashId={data.sectionParrainage.hashId}
        className="parrainage-section"
      >
        <div className="fidelite-header-section">
          {data.sectionParrainage.sectionDescription.sectionTitle}
        </div>
        <div className="fidelite-intro-section">
          {HTMLReactParser(
            `${data.sectionParrainage.sectionDescription.sectionDescription}`
          )}
        </div>
      </SectionWrapper>

      <div className="parrainage-content-section">
        <div className="parrainage-content-row1-col1">
          {data.sectionParrainage.descriptionLeft.title}
        </div>
        <div className="parrainage-content-row1-col2">
          <img
            src={getFileUrl(data.sectionParrainage.desktop_media[0])}
            alt=""
          />
        </div>

        <div className="parrainage-content-row2-col1 parrainage-text">
          {HTMLReactParser(
            `${data.sectionParrainage.descriptionLeft.description}`
          )}
        </div>
        <div className="parrainage-content-row2-col2 parrainage-text">
          {HTMLReactParser(
            `${data.sectionParrainage.descriptionRight.description}`
          )}
        </div>

        <div className="linked-path-group">
          <img alt='' src={getFileUrl(data.sectionParrainage.mobile_media[0])} />
        </div>
      </div>
    </div>
  );
};

const NAME = 'fidelite-recompensee';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Loyality);
