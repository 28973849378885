import * as links from '../../Routes/links';

export const genearalData = {
  title: 'GENERAL',
  displayOnNextColumn: true,
  items: [
    {
      title: 'Accueil',
      link: '/',
    },
    {
      title: 'E-shop',
      link: links.CATEGORIES_LINK,
      isExternalLink: true,
    },
    {
      title: 'Prendre RDV',
      link: links.APPOINTMENT_LINK,
    },
    {
      title: 'Prise en charge médicale',
      link: links.PRISE_EN_CHARGE_LINK,
    },
    {
      title: 'Blog',
      link: links.BLOG_LINK,
    },
    {
      title: 'Elles nous font confiance',
      link: links.CONFIANCE_LINK,
    },
    {
      title: 'Contact',
      link: links.CONTACT_LINK,
    },
  ],
};

export const institutData = {
  title: 'Notre Institut',

  items: [
    {
      title: 'Nos engagements',
      link: links.ENGAGEMENT_LINK,
    },
    {
      title: 'Notre histoire',
      link: links.HISTOIRE_LINK,
    },

    {
      title: 'L’Équipe',
      link: links.EQUIPE_LINK,
    },
    {
      title: 'La créatrice',
      link: links.CREATRICE_LINK,
    },
  ],
};

// export const confianceData = {
//   title: 'Ils nous font confiance',
//   displayOnNextColumn: true,
//   items: [
//     {
//       title: 'Nos créations',
//       link: links.NOS_CREATION_LINK,
//     },
//     {
//       title: 'Évènements ',
//       link: links.ENGAGEMENT_LINK,
//     },
//   ],
// };

export const creationsData = {
  title: 'Nos Créations',
  displayOnNextColumn: true,
  items: [
    {
      title: 'Nos prestations',
      link: links.PRESTATION_LINK,
    },

    {
      title: 'Nouvelle collection',
      link: links.Our_COLLECTION_LINK,
    },
    {
      title: 'Innovation Hairlinematch',
      link: links.INNOVATION_LINK,
    },
    {
      title: 'Fidélité récompensée',
      link: links.FIDELITE_LINK,
    },
    {
      title: 'Tarifs et Délais de Fabrication',
      link: links.MANUFACTURING_LINK,
    },
  ],
};

export const eneableLink = [
  'Contact',
  'Nos prestations',
  'Notre histoire',
  'Accueil',
  'E-shop',
  'Prendre RDV',
  'Prise en charge médicale',
  'Nos engagements',
  'L’Équipe',
  'La créatrice',
  'Innovation Hairlinematch',
  'Fidélité récompensée',
  'Tarifs et Délais de Fabrication',
  'Blog',
  'Elles nous font confiance',
  'Nouvelle collection',
];

export const mentiOnText = (
  <p>
    <a href="/mentions-legales"> Mentions légales</a>
    <br />
    <a href="/mentions-legales"> Politique de confidentialité</a>
  </p>
);
