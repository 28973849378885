import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { NavHashLink } from 'react-router-hash-link';

import StripePaymentField from './StripePaymentField';
import AlmaPaymentField from './AlmaPaymentField';

import { Radio } from 'antd';
import './style.less';
import { CHECKOUT_LINK } from '../../../Routes/links';
import Spiner from '../../../components/Spiner';

const { Panel } = Collapse;

const PaymentFields = ({
  submitOrder,
  order,
  clearCart,
  showSuccess,
  postOrderCallBack,
}) => {
  //

  const checkoutUrl = window.location.href;

  const [current, setCurrent] = React.useState('stripe');

  const [loading, setLoading] = React.useState(false);

  const handleCHange = async (key) => {
    setCurrent(key);
    setLoading(true);

    if (key !== 'stripe') callback(key);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const noAlma = order.amount > 2000 || order.amount < 200;

  function callback(key) {
    const node = document.getElementById(`div-${key}`);
    if (node !== null) node.onclick();
  }

  const loader = <Spiner style={{ height: '25vh' }} />;

  return (
    <Wrapper>
      <div className="checkout-section" id="payment-section">
        <h2 className="section__title">Méthode de paiement</h2>
        <h2 className="section__subtitle">
          Veuillez choisir votre mode de paiement
        </h2>

        <Collapse
          defaultActiveKey={'stripe'}
          accordion
          onChange={handleCHange}
          activeKey={current}
          expandIcon={({ isActive }) => <Radio checked={isActive} />}
          // className="payments-carroussel"
          className="payment-collapse"
          bordered={false}
        >
          <Panel
            header={<span className="title"> Payer en une fois</span>}
            key={'stripe'}
            className="payment-collapse-panel"
          >
            {loading && current === 'alma3' ? (
              loader
            ) : (
              <div className="stripe-payment-panel">
                <StripePaymentField
                  submitOrder={submitOrder}
                  order={order}
                  clearCart={clearCart}
                  isOnly={noAlma}
                  showSuccess={showSuccess}
                  postOrderCallBack={postOrderCallBack}
                />
              </div>
            )}
          </Panel>

          {!noAlma && (
            <>
              <Panel
                header="Payer en deux fois"
                key={'alma2'}
                className="payment-collapse-panel"
              >
                <div id="alma-payment2"></div>
                {loading && current === 'alma2' && loader}
              </Panel>

              <Panel
                header="Payer en trois fois"
                key={'alma3'}
                className="payment-collapse-panel"
              >
                <div id="alma-payment3"></div>
                {loading && current === 'alma3' && loader}
              </Panel>

              <Panel
                header="Payer en quatre fois"
                key={'alma4'}
                className="payment-collapse-panel"
              >
                <div id="alma-payment4"></div>
                {loading && current === 'alma4' && loader}
              </Panel>
            </>
          )}
        </Collapse>

        <AlmaPaymentField return_url={checkoutUrl} order={order} />

        <div className="payment__button">
          <NavHashLink to={CHECKOUT_LINK}>
            <button className="payment__back">
              MODIFIER MES INFORMATIONS DE LIVRAISON
            </button>
          </NavHashLink>
        </div>
      </div>
    </Wrapper>
  );
};

export default PaymentFields;

const Wrapper = styled.div`
  input,
  label {
    display: block;
    font-family: Visby CF;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #151515;
  }

  input {
    width: 100%;
    font-size: 16px;
    padding: 6px 20px;
    margin-top: 5px;
  }

  label {
    font-size: 16px;
    margin-top: 15px;
  }
`;
