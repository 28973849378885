import React from 'react';
import { Row, Col } from 'antd';
import FavItem from './Item';
import Spiner from '../../../components/Spiner';
import { useSelector } from 'react-redux';
import iconDetails from '../../../assets/icons/details.png';
import { wishlistSelector } from '../../../stores/wishList/selector';

import './favs.less';

const Favs = ({
  addToCart,
  switchPassword,
  toggleNavbar,
  removeItem,
  navToggle,
  logOut,
}) => {
  const wishlist = useSelector(wishlistSelector);

  if (!wishlist) {
    return <Spiner />;
  }

  return (
    <div id="favs" className="favs">
      <Row>
        <Col lg={12} md={9} sm={11} xs={21}>
          <h2 className="favs__title">Favoris</h2>
        </Col>

        <Col sm={0} xs={3} className="navbar-toggle">
          <button
            onClick={() => toggleNavbar()}
            className="navbar-toggle__button"
          >
            <img src={iconDetails} alt="details.png" />
          </button>
        </Col>

        <Col lg={12} md={15} sm={13} className="favs__buttons">
          <button
            onClick={() => switchPassword()}
            className="favs__button favs__reset-password"
          >
            MODIFIER LE MOT DE PASSE
          </button>
          <button className="favs__button favs__logout" onClick={logOut}>
            DECONNEXION
          </button>
        </Col>
      </Row>

      <Row className="favs__recap">
        {wishlist.content && wishlist.content.length > 0 ? (
          wishlist.content.map((item, index) => {
            return (
              <Col key={item.id} lg={8} sm={12} xs={12}>
                <FavItem item={item} />
              </Col>
            );
          })
        ) : (
          <div className="nodata">
            <p className="nodata__text">
              Votre liste de favoris est vide pour le moment.
            </p>
          </div>
        )}
      </Row>
    </div>
  );
};

// export default Favs;

export default Favs;
