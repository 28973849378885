import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vitrinSelector } from './selector';
import { setVitrinData } from './actions';

const useVitrinFetcher2 = ({ endpoints, name }) => {
  //
  const dispatch = useDispatch();

  const data = useSelector((state) => vitrinSelector(state, name));

  React.useEffect(() => {
    if (!data) dispatch(setVitrinData({ endpoints, name }));
    // 
  }, [data]);
  return data;
};

export default useVitrinFetcher2;
