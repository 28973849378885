import React from 'react';
import Slider from 'react-slick';
import CalendarColumn from './CalendarColumn';
import { ReactComponent as RightDownArrow } from '../../../assets/icons/rightDownArrow.svg';
import { ReactComponent as LeftDownArrow } from '../../../assets/icons/leftDownArrow.svg';

const Calendar = ({ datas = [], className = '' }) => {
  let slider;
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
  };

  const moveNext = () => {
    slider.slickNext();
  };
  const movePrev = () => {
    slider.slickPrev();
  };

  return (
    // <ROw className={`calendar ${className}`}>
    <div className={`calendar ${className}`}>
      <div className="separator" />

      <div className="calendar-header-arrow">
        <LeftDownArrow onClick={movePrev} />
      </div>

      <Slider ref={(c) => (slider = c)} {...settings}>
        {datas.map((item, index) => (
          <CalendarColumn key={index} data={item} />
        ))}
      </Slider>

      <div className="calendar-header-arrow">
        <RightDownArrow onClick={moveNext} />
      </div>
    </div>
  );
};

export default Calendar;
