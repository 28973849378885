import React from 'react';
import FeaturedRow from './FeaturedRow';

import { getFeaturedSelections } from '../../../../stores/shop/actions';
import { connect } from 'react-redux';

const FeaturedSelection = ({
  getFeaturedSelections,
  queryObject,
  category,
  isSimpleFetcher,
}) => {
  const [datas, setDatas] = React.useState();

  React.useEffect(() => {
    initialize();
    // 
  }, [isSimpleFetcher]);

  const initialize = async () => {
    const value = await getFeaturedSelections(queryObject);
    setDatas(value);
  };

  if (!datas) return <> </>;

  return (
    <>
      {datas.map((item) => (
        <FeaturedRow key={item.id} data={item} category={category} />
      ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  getFeaturedSelections,
})(FeaturedSelection);
