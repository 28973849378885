import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import Input from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';

// const primaryCOlor = '#E5E5E5';

const PhoneField = ({
  value = '',
  onChange,
  placeholder = '',
  className = '',
}) => {
  const [state, setState] = React.useState({
    fieldValue: value,
    showTooltip: false,
  });

  const { fieldValue, showTooltip } = state;

  const handleChange = (number) => {
    validateNumber(number);
  };

  const validateNumber = (number) => {
    const val = formatPhoneNumberIntl(number);
    let showTooltip = false;

    if (isValidPhoneNumber(val)) {
      onChange(val);
      showTooltip = false;
    } else {
      onChange('');
      showTooltip = true;
    }

    setState({ ...state, fieldValue: val, showTooltip });
  };

  const handleFocus = () => {
    const val = formatPhoneNumberIntl(fieldValue);
    if (!isValidPhoneNumber(val)) {
      setState({ ...state, showTooltip: true });
    }
  };

  const handleMouseOut = () => {
    setState({ ...state, showTooltip: false });
  };

  return (
    <Tooltip
      placement="topLeft"
      title="Indicatif + numéro"
      // color={primaryCOlor}
      visible={showTooltip}
    >
      <InputContainer>
        <CInput
          international
          defaultCountry="FR"
          placeholder={placeholder}
          value={fieldValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleMouseOut}
          className={'form-input phone-input ' + className}
        />
      </InputContainer>
    </Tooltip>
  );
};

export default PhoneField;

const InputContainer = styled.span`
  width: 100%;

  box-sizing: border-box;
  border-radius: 0px;

  outline: none;
  display: inline-flex;
  align-items: center;
`;

const CInput = styled(Input)`
  width: 100%;
  padding: 0;
  margin: 0;
  background: transparent;

  border: none;
  border-radius: 0px;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;

  display: inline-block;

  transition: all 0.3s;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
`;
