import React from 'react';
import styled from 'styled-components';

const PageTitle = ({ caption = defaultCaption, className = '' }) => {
  return <Title className={className}>{caption}</Title>;
};

export default PageTitle;

const Title = styled.h2`
  font-family: Frunchy;
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  line-height: 90%;
  margin: 70px 0px 40px 0px;
  max-width: 83%;

  @media (max-width: 766px) {
    font-size: 30px;
    line-height: 27px;
    width: 100%;
    padding-left: 10%;
  }
`;
const defaultCaption = "Prendre rendez-vous chez L'ARTISAN DU SUBLIME pour";
