import React from 'react';
import { Affix } from 'antd';
import MobileHeader from './MobileHeader';

const RenderMobileHeader = ({defaultMenu,pushRoute,pathname})=>(
    <Affix className="affix-header" offsetTop={0}>
      <div className="header-root">
        <MobileHeader
          menus={defaultMenu}
          pushRoute={pushRoute}
          pathname={pathname}
        />
      </div>
    </Affix>
  );

export default React.memo(RenderMobileHeader)