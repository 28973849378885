import * as types from './types';

const INITIAL_STATE = {
  mustAuth: false,
  nextAuthedLink: '',
  typeModal: false,
};

const authReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SET_AUTH:
      return {
        ...state,
        token: payload.token,
        user: payload.user,
        isAuth: payload.token && payload.token !== null,
      };

    case types.LOG_OUT:
      return {
        ...state,
        token: undefined,
        user: undefined,
        isAuth: false,
      };

    case types.SET_USER_DATA:
      return {
        ...state,
        user: payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default authReducer;

export const key = 'auth';

export const MODALPAGES = Object.freeze({
  SIGINUP: 'SIGNUP',
  SIGNIN: 'login',
  NONE: 'none',
});
