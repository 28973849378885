import { SET_PRODUCTS, SET_FILTERS, SET_CATEGORIES } from './types';

import { fetchFilters, fetchCatgories, fetchProductsAndSelection } from './api';

export const getAllProducts =
  (filter, isMobile) => async (dispatch, getState) => {
    //

    try {
      let data = await fetchProductsAndSelection({ ...filter });

      dispatch({
        type: SET_PRODUCTS,
        payload: data,
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  };

export const getFeaturedSelections = (filter) => async (dispatch) => {
  //

  try {
    let data = await fetchProductsAndSelection(filter, '/featured-selections');

    // dispatch({
    //   type: SET_PRODUCTS,
    //   payload: data,
    // });
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllFilters = () => async (dispatch) => {
  try {
    let data = await fetchFilters();

    dispatch({
      type: SET_FILTERS,
      payload: data,
    });
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getCategories = () => async (dispatch) => {
  try {
    let data = await fetchCatgories();

    dispatch({
      type: SET_CATEGORIES,
      payload: data,
    });
    return data;
  } catch (err) {
    console.log(err);
  }
};
