import { connect, useSelector } from 'react-redux';
import React, { Fragment } from 'react';

import { useParams } from 'react-router-dom';

import { Row, Col } from 'antd';

import AdsButton from '../../home/Button/AdsButton';
import * as actions from '../../../stores/cart/cartActions';

import HeaderH3TextNormal from '../../home/HeaderText/HeaderH3TextNormal';
import {
  APPOINTMENT_LINK,
  CATEGORIES_SLUG,
  MANUFACTURING_LINK,
} from '../../../Routes/links';

import './style.less';

import * as wishlistActions from '../../../stores/wishList/actions';

import Spiner from '../../../components/Spiner';
import ProductCardV2 from './productCardV2';
import ImagesCarroussel from './ImagesCarroussel';
import useFetch from '../../../hooks/useFetch';
import { getProductBySlugUrl } from '../../../stores/product/api';
import { getTempStock, performAddToCard } from '../../../components/Cart/utils';
import { NavHashLink } from 'react-router-hash-link';
import useProductSoldOut from '../../../stores/product/hooks/useProductSoldOut';
import CategoryBreadcrumb from '../shop/Breadcrumb';
import ProductFeatures from './productFeatures';
import ProductDescription from './productDescription';
import StockCallback from './stockCallback';
import QuantitySelect from './QuantitySelect';
import { cartSelector } from '../../../stores/cart/selector';
import { isValidValue } from '../../../utils';
import useVitrinFetcher2 from '../../../stores/vitrin/hooks2';
import DangeriousHtml from '../../../components/DangeriousHtml';

const SingleProduct = ({
  addToCart,
  addToWishlist,
  location,
  selectedProduct,
}) => {
  //

  const cartData = useSelector(cartSelector);
  const params = useParams();

  const pageInfos =
    useVitrinFetcher2({
      endpoints: 'page-detail-produit',
      name: 'pageProduit',
    }) || defaultPageInfos;

  const [state, setState] = React.useState({
    isMobile: window.innerWidth < 976,
    qte: 1,
  });

  const { product, other, category, currentVariation } = state;

  const {
    stock,
    isSoldOut,
    soldOutMessage,
    price,
    sale,
    image: forcedImage,
    manageStock,
  } = useProductSoldOut(product, 'stock épuisé', currentVariation);
  const URL = getProductBySlugUrl(params.slug);
  const data = useFetch(URL);

  React.useEffect(() => {
    document.body.scroll(0, 0);
    getCategoryFromLink();

    if (selectedProduct) {
      setState({
        ...state,
        other: selectedProduct.other.filter((item) => item && item !== null),
      });
    }

    // 
  }, [params.slug]);

  React.useEffect(() => {
    if (data && data !== null) {
      setState((state) => ({ ...state, product: data }));
    }

    // 
  }, [data]);

  const getCategoryFromLink = () => {
    const { state: linkState } = location;

    if (linkState && linkState !== null) {
      const { category } = linkState;

      if (category && category !== null)
        setState((state) => ({ ...state, category }));
    }
  };

  const carouselSeeMore = (other) => {
    return (
      <div>
        <div className="produits-container">
          <Row gutter={20}>
            {other
              ? other.map((item) => (
                  <Fragment key={item.id}>
                    <ProductCardV2 prod={item} />{' '}
                  </Fragment>
                ))
              : ''}
          </Row>
        </div>
      </div>
    );
  };

  const add = async () => {
    await performAddToCard(
      addToCart,
      {
        ...product,
        variation: currentVariation,
        qte: state.qte,
      },
      window
    );

    setState((state) => ({ ...state, qte: 1 }));
  };

  const showPrice = () => {
    return sale && sale !== null && (
      <p className="product-section-amount">
        {sale} €
        <br />
        {price && price !== null && price !== sale && (
          <strike style={{ fontWeight: 300, fontSize: 18 }}>{price} €</strike>
        )}
      </p>
    );
  };

  const getLink = (slug) => {
    return CATEGORIES_SLUG + '/' + slug;
  };

  const getDefaultVariation = () => {
    const { variations } = data;
    return (
      variations.find((item) => isValidValue(item) && item.isDefault) ||
      variations[0]
    );
  };

  const onCurrentVariationChange = (val) => {
    setState((state) => ({ ...state, currentVariation: val }));
  };

  const onQUantityChange = (value) => {
    setState({ ...state, qte: value });
  };

  const disabledAddButton =
    getTempStock({
      cart: cartData,
      productId: product?._id,
      variation_slug: currentVariation?.slug,
      stock: stock,
      manageStock,
    }) === 0;

  const showProductInfo = () => (
    <div>
      <p className="product-section-title">{product.name}</p>
      <p className="product-section-subtitle">{product.deliveryTime}</p>
      {showPrice()}
      <div className="product-section-tax-info">
        <DangeriousHtml value={pageInfos.taxeMessage || ""} />
      </div>
      {isSoldOut ? (
        <>
          {soldOutMessage}
          <StockCallback product={product} texts={pageInfos.alert} />
        </>
      ) : (
        <>
          <QuantitySelect
            stock={stock}
            value={state.qte}
            onChange={onQUantityChange}
          />
          <div className="product-section-buttons">
            <Row>
              <Col span={12}>
                <AdsButton
                  disabled={disabledAddButton}
                  className="product-button"
                  onClick={add}
                  text={pageInfos.addButton}
                />
              </Col>

              <Col span={12} className="product-button-div">
                <a href={pageInfos.rdvLink}>
                  <button className="product-button">
                    {pageInfos.RdvButton}
                  </button>
                </a>
              </Col>
            </Row>
          </div>
        </>
      )}

      {data.variations && (
        <ProductFeatures
          data={data}
          value={getDefaultVariation()}
          onCHange={onCurrentVariationChange}
        />
      )}

      <ProductDescription data={product.content} />
      <div className="product-section-shipping">
        <NavHashLink to={pageInfos.faqPageSlug || "#"}>
          {pageInfos.faqButton}
        </NavHashLink>
      </div>
    </div>
  );

  const showContent = () => (
    <div id="product-page" className="product-page container">
      <div style={{ marginTop: 20 }}>
        <CategoryBreadcrumb
          getLink={getLink}
          deepItems={category}
          pageName={(product || {}).name}
        />
      </div>

      <section className="product">
        <Row className="content">
          <Col md={12} sm={24}>
            <ImagesCarroussel
              product={product}
              addToWishlist={(v) => addToWishlist({ ...v, window })}
              forcedImage={forcedImage}
            />
          </Col>
          <Col md={12} sm={24} className="product-section-div">
            {showProductInfo()}
          </Col>
        </Row>
      </section>
      <section className="see-more">
        {other && (
          <>
            <HeaderH3TextNormal text="VOUS POURRIEZ ÉGALEMENT AIMER" />
            <div>{carouselSeeMore(other)}</div>
          </>
        )}
      </section>
    </div>
  );

  return product ? showContent() : <Spiner />;
};

const Wrapper = connect(
  (state) => ({
    selectedProduct: state.shop.product,
  }),

  (dispatch) => {
    return {
      addToCart: (val) => dispatch(actions.addItemToCart(val)),
      addToWishlist: (data) =>
        dispatch(wishlistActions.addItemToWishlist(data)),
    };
  }
);

export default Wrapper(SingleProduct);

const defaultPageInfos = {
  taxeMessage: (
    <>Taxes incluses. Frais de port calculés à l'étape de paiement.</>
  ),
  alert: undefined,
  addButton: 'AJOUTER AU PANIER',
  rdvLink: APPOINTMENT_LINK,
  RdvButton: 'ESSAYER EN INSTITUT',
  faqPageSlug: `${MANUFACTURING_LINK}#top`,
  faqButton: 'LIVRAISON ET REMBOURSEMENT',
};
