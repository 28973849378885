import React from 'react';
import { Row, Col } from 'antd';
import Img from 'react-cool-img';
import { MOBILE_SIZE } from '../../config';
import useWindowSize from '../../hooks/useWindowSize';
import HeaderH1TextItalic from '../../components/common/HeaderH1TextItalic';
import HeaderH1TextNormal from '../../components/common/HeaderH1TextNormal';
import { getFileUrl, isValidValue } from '../../utils';
import './style.less';
import { vitrinSelector } from '../../stores/vitrin/selector';
import * as actions from '../../stores/vitrin/actions';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import useHashScrooler from '../../hooks/useHashScrooler';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import SectionWrapper from '../../components/SectionWrapper';

function Underline(props) {
  if (props.underline) {
    return <p className="healthcare-section4-col-underline">{props.underline}</p>;
  }
  return '';
}

const Helthcare = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'prise-en-charge-medicale',
    name: NAME,
  });

  useHashScrooler({ location, data });

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;
  if (!data) return <Spiner />;

  const getMutualImage = () => {
    let image;
    if (isMobile) {
      if (isValidValue(data.sectionAndMutual?.mobile_media))
        image = getFileUrl(data.sectionAndMutual?.mobile_media);
    } else if (isValidValue(data.sectionAndMutual?.desktop_media))
      image = getFileUrl(data.sectionAndMutual?.desktop_media);

    return image ? <img className="healthcare-img" src={image} alt="Smile" /> : '';
  };

  return data && (
    <div
      id="healthcare"
      className="container healthcare"
      style={isMobile ? { padding: '0' } : {}}
    >
      {data?.SectionBanniere?.banniere && <div class="healthcare-section1">
        <HeaderH1TextNormal
          text={data.SectionBanniere?.banniere?.titre_normal}
          isCenter={true}
          shouldBringUp={false}
        />
        <HeaderH1TextItalic
          text={data.SectionBanniere?.banniere?.titre_italique}
          isCenter={true}
          shouldBringUp={true}
          customclassName={'healthcare-section1-absolute'}
        />
        <div className="healthcare-section1-img">
          <Img
            src={
              isMobile
                ? getFileUrl(data.SectionBanniere.banniere.mobile_media)
                : getFileUrl(data.SectionBanniere.banniere.desktop_media)
            }
            alt="Prise en charge"
          />
        </div>
        <div className="healthcare-section1-text">
          <Row>
            <Col span={12}>
              <p
                className="healthcare-section1-text-left"
                dangerouslySetInnerHTML={{
                  __html: data.SectionBanniere?.descriptionLeft,
                }}
              ></p>
            </Col>
            <Col span={12}>
              <p
                className="healthcare-section1-text-right"
                dangerouslySetInnerHTML={{
                  __html: data.SectionBanniere?.descriptionRight,
                }}
              ></p>
            </Col>
          </Row>
        </div>
      </div>}

      {data.sectionHowToProceed &&<SectionWrapper
        hashId={data.sectionHowToProceed.hashId}
        className="healthcare-section2"
      >
        <Row>
          <Col span={10}>
            <div className="healthcare-section2-div">
              <p className="healthcare-section2-title">
                {data.sectionHowToProceed.tilte.normalTitle} 
              </p>
              <p className="healthcare-section2-subtitle">
                {data.sectionHowToProceed.tilte.italicTitle} 
              </p>
              <Img
                className="healthcare-section2-img"
                src={getFileUrl(data.sectionHowToProceed.desktop_media)}
                alt="Prince Akachi"
              />
            </div>
          </Col>
          <Col span={14}>
            <div>
              {data.sectionHowToProceed.listOfDescription.map((item, index) => (
                <SectionWrapper
                  hashId={item.hashId}
                  key={item._id}
                  className="healthcare-section2-rigth"
                  style={{ 'background-color': item.background }}
                >
                  <p>{index + 1}/4</p>
                  <p
                    className="healthcare-section2-rigth-text"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></p>
                </SectionWrapper>
              ))}
            </div>
          </Col>
        </Row>
      </SectionWrapper>}

      {data.sectionAndMutual && <SectionWrapper
        hashId={data.sectionAndMutual.hashId}
        className="healthcare-section3"
      >
        <Row>
          <Col span={12}>
            <p className="healthcare-section2-title title-sm">
              {data.sectionAndMutual.sectionTitle.sectionTitle}
            </p>
            <p
              className="healthcare-section3-text"
              dangerouslySetInnerHTML={{
                __html: data.sectionAndMutual.descriptinLeft,
              }}
            ></p>
          </Col>
          <Col span={12}>
            <span className="empty"></span>
            <p
              className="healthcare-section3-textrigth"
              dangerouslySetInnerHTML={{
                __html: data.sectionAndMutual.descriptipnRight,
              }}
            ></p>
          </Col>
        </Row>
      </SectionWrapper>}

      <div>{getMutualImage()}</div>

      {data.partenaires && <><SectionWrapper
        hashId={data.partenaires.hashId}
        className="healthcare-section3"
      >
        <Row>
          <Col span={12}>
            <p class="healthcare-section2-title">
              {data.partenaires.sectionTitle?.sectionPageTitle}
            </p>
            <p
              className="healthcare-section3-text"
              dangerouslySetInnerHTML={{
                __html: data.partenaires.descriptionLeft,
              }}
            ></p>
          </Col>
          <Col span={12}>
            <span className="empty"></span>
            <p
              className="healthcare-section3-textrigth"
              dangerouslySetInnerHTML={{
                __html: data.partenaires.descriptionRight,
              }}
            ></p>
          </Col>
        </Row>
      </SectionWrapper>
      <hr />
      <div class="healthcare-section4">
        {data.partenaires.listOfpartenaire?.map((t) => (
          <div class="healthcare-section4-col">
            <p class="healthcare-section4-col-title">{t.titre_normal}</p>
            <p
              className="healthcare-section4-col-text"
              dangerouslySetInnerHTML={{ __html: t.description }}
            ></p>
            <Underline underline={t.underline} />
          </div>
        ))}
      </div></>}

      {data.sectionLogo?.length && <SectionWrapper
        hashId={data.sectionLogo[0].hashId}
        className="healthcare-section5"
      >
        {data.sectionLogo.map((s) => (
          <Img src={getFileUrl(s.logo)} alt="sponsor" key={s._id} />
        ))}
      </SectionWrapper>}
    </div>
  );
};
const NAME = 'helthcare';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Helthcare);
