export const GET_CART = 'GET_CART';
export const SET_OPEN_CART = 'SET_OPEN_CART';
export const SET_CART = 'SET_CART';
export const SET_CART_CONTENT = 'SET_CART_CONTENT';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';
export const MERGE_CART = 'MERGE_CART';
export const CLEAR_CART = 'CLEAR_CART';
