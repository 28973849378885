import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import HTMLReactParser from 'html-react-parser';
import SectionWrapper from '../../components/SectionWrapper';

const WelcomeSection = ({
  contentClass,
  title,
  secondColumn,
  firstColumn,
  hashId,
}) => {
  const gutter = [{ lg: 60, sm: 60, xs: 20 }, { xs: 20 }];

  return (
    <SectionWrapper hashId={hashId}>
      <WelcomeWrapper>
        <div className={contentClass}>
          <Row className="bienvenue__title">
            <h3>{title}</h3>
          </Row>

          <Row gutter={gutter}>
            <Col xl={11}>{HTMLReactParser(firstColumn)}</Col>

            <Col xl={13}>{HTMLReactParser(secondColumn)}</Col>
          </Row>
        </div>
      </WelcomeWrapper>
    </SectionWrapper>
  );
};

export default WelcomeSection;

const WelcomeWrapper = styled.div`
  margin: 100px auto;
  background-color: #e6ceb5;
  padding-top: 20px;
  padding-bottom: 40px;
  line-height: 24px;

  font-family: Visby CF;
  font-weight: 300;
  font-size: 16px;

  .bienvenue__title {
    font-family: 'Everty Glorial';
    font-size: 36px;
    line-height: 46.8px;
    font-weight: 400;
    margin-top: 40px;
  }

  .bienvenue__item {
    list-style-image: url('../../assets/icons/black-circle.png');
  }

  @media (max-width: 766px) {
    font-size: 11px;
    font-weight: 300;
    line-height: 13px;
    padding-left: 20px;

    .bienvenue__title {
      font-size: 30px;
      line-height: 39px;
    }

    .bienvenue__title h3 {
      margin-bottom: 0;
    }
  }
`;
