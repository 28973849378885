import * as types from './types';

const INITIAL_STATE = {};

const cartReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SET_PROVIDERS:
      return { ...state, providers: payload };
    case types.SET_TEMP_ORDER:
      return { ...state, tempOrder: payload };
    case types.SET_ORDERS_HISTORY:
      return { ...state, orders: payload };
    default:
      return {
        ...state,
      };
  }
};

export default cartReducer;

export const key = 'order';
