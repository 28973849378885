import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setLinkedAuthAction } from '../stores/auth/actions';
import { isValidValue } from '../utils';
import { useHistory } from 'react-router-dom';
import { setOpenCart } from '../stores/cart/cartActions';
import { MODALPAGES } from '../stores/auth/reducer';

const CartRoute = ({ component: Component, ...restOfProps }) => {
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();

  let { location: historyLocation } = useHistory();

  React.useEffect(() => {
    if (!isValidValue(token))
      dispatch(setLinkedAuthAction(true, restOfProps.path, MODALPAGES.SIGNIN));
    else dispatch(setOpenCart(true));

    // 
  }, [token]);

  return <Redirect to={{ pathname: historyLocation }} {...restOfProps} />;
};

export default CartRoute;
