let getAlmaquery = ({ amount = 45000, shipping_address, return_url,
  customer,
}) => `

<script
type="text/javascript"
src="https://unpkg.com/@alma/fragments@1.x/dist/alma-fragments.umd.js"
></script>
<script>
var YOUR_MERCHANT_ID = '${process.env.REACT_APP_ALMA_MARCHANT_ID}'
</script>


<script id="sample-script">

function pay(installments, deferredDays) {
  // You'd typically get this data from your current cart/order to be paid for
  let fragments = new Alma.Fragments(YOUR_MERCHANT_ID, {
    mode: Alma.ApiMode.${process.env.REACT_APP_PAYMENT_MODE || 'LIVE'},
  })
  let paymentData = {
    payment: {
      purchase_amount: ${amount}, // in Euro cents
      shipping_address: ${shipping_address} ,
      installments_count: installments,
      deferred_days: deferredDays,
      return_url: '${return_url}',
    },
    customer: ${customer}
  }
  let key= '#alma-payment'+installments
  fragments.createPaymentForm(paymentData).mount(key)
}
</script>
  `;

export default getAlmaquery;
