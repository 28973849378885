import React from "react";
import "./headerTextNormal.less";

const HeaderH1TextNormal = ({ text, isCenter, shouldBringUp }) => {
  const classesToApply = () => {
    let classesList = "header-h1-text-normal";
    if (isCenter) {
      classesList = classesList + " center-text";
    }
    if (shouldBringUp) {
      classesList = classesList + " bring-upfront";
    }
    return classesList;
  };
  return (
    <>
      <h1 className={classesToApply()}>{text}</h1>
    </>
  );
};

export default HeaderH1TextNormal;
