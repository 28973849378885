import { BASE_URL } from '../../api/config';
import { getToken, getUsers } from '../../api/utils';
import http from '../../api/http';

export const getCartUrl = () => {
  const user = getUsers();
  return user ? `${BASE_URL}/cart` : null;
};

export const getOrderById = async (id) => {
  const token = getToken();
  //requestcancel
  return http
    .get(`/orders/` + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const launchCancelOrder = async (id) => {
  const token = getToken();

  return http
    .post(
      `/requestcancel/` + id,
      { body: {} },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const getShippingProviderApi = async () => {
  const token = getToken();

  return http
    .get(`/shipping-providers`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const checkOrderTotals = async (body) => {
  const token = getToken();

  return http
    .post(`/totals`, body, { headers: { Authorization: `Bearer ${token}` } })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const removeItemApi = async (id) => {
  const token = getToken();

  return http
    .delete(`/cart/${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const submitOrderApi = async (body) => {
  const token = getToken();
  return http
    .post(`/orders`, body, { headers: { Authorization: `Bearer ${token}` } })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error?.response?.data
    });
};

export const orderTotalsApi = async ({ shipping, promoCode }) => {
  const token = getToken();

  return http
    .post(
      `/ordertotals`,
      { shipping, promoCode },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return { error: true };
    });
};

export function fetchOrdersApi() {
  const token = getToken();

  return http
    .get('/userorders', { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      // Handle success.
      return response;
    })
    .catch((error) => {
      // Handle error.
      console.log('Erreur :', error.response);
    });
}
