import axios from 'axios';

const SANDBOX_BASE_URL = 'https://sandbox.clicrdv.com';
const SANDBOX_API_KEY = '1d0687bb119048e4ab6928fb0d6437f3';
const GROUP_ID = '56232';

export const GET_CALENDARS_API =
  '/api/v1/groups/' +
  GROUP_ID +
  '/calendars.json?results=all&apikey=' +
  SANDBOX_API_KEY;

export const GET_APPOINTMENTS_API =
  '/api/v1/groups/' +
  GROUP_ID +
  '/appointments.json?results=all&apikey=' +
  SANDBOX_API_KEY;

export const GET_INTERVENTIONS_API =
  '/api/v1/groups/' +
  GROUP_ID +
  '/interventions.json?results=all&apikey=' +
  SANDBOX_API_KEY;

const GET_INTERVENTIONSET_API =
  '/api/v1/groups/' +
  GROUP_ID +
  '/interventionsets.json?results=all&apikey=' +
  SANDBOX_API_KEY;

const getAvalaibilitiesApi = (interventionId, nDays) =>
  `api/v1/groups/${GROUP_ID}/availabletimeslots.json?results=all&apikey=${SANDBOX_API_KEY}&nDays=${nDays}&intervention_ids[]=${interventionId}`;

const getInterventionsDetail = (interventionId) =>
  `/api/v1/groups/${GROUP_ID}/interventions.json?intervention_id=${interventionId}&apikey=${SANDBOX_API_KEY}`;

const instance = axios.create({
  baseURL: SANDBOX_BASE_URL,
  // withCredentials: true,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  // },
});

export const axiosGet = async (apiLInks) => {
  try {
    let data = await instance.get(apiLInks);

    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getInterventions = async () => {
  const fetchData = await axiosGet(GET_INTERVENTIONS_API);
  return fetchData.data.records;
};

export const getInterventionsDetails = async (interventionId) => {
  const fetchData = await axiosGet(getInterventionsDetail(interventionId));
  return fetchData ? fetchData.data.records[0] : undefined;
};

export const getInterventionSets = async () => {
  const fetchData = await axiosGet(GET_INTERVENTIONSET_API);
  return fetchData.data.records;
};

export const getAvailableCalendar = async (interventionId, nDays = 100) => {
  const fetchData = await axiosGet(getAvalaibilitiesApi(interventionId, nDays));
  return fetchData ? fetchData.data : undefined;
};
