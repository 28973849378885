import React from 'react';
import axios from 'axios';
import './style.less';

import { Layout } from 'antd';
import { useState } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom'

import styled from 'styled-components';
import { ReactComponent as IconsSearch } from '../assets/icons/search.svg';
import { ReactComponent as MenuLogo } from '../assets/icons/menu.svg';
import AppMenu from './Menu';
import * as links from '../Routes/links';
import CartIcon from '../components/CartIcon';

import AppLogo from './AppLogo';
import SearchField from '../components/SearchField';
import { isValidValue } from '../utils';

const { Header } = Layout;

const MobileHeader = ({ pushRoute, pathname }) => {
	const [cartToggle, setCartToggle] = useState(false);
	const [showSearch, setShowSearch] = React.useState(false);
	const location = useLocation();

	React.useEffect(() => {
		const element = document.getElementById('search-input');
		if (isValidValue(element)) {
			element.addEventListener('change', (e) => {
				search(e.currentTarget.value);
			});
		}
	}, []);
	const [appointmentLink,setAppointmentLink] = useState('')
  React.useEffect(()=>{
    async function getRdvLink(){
      var link = (await axios.get(process.env.REACT_APP_BACKOFFICE_URL+"/configuration"))?.data.prendreRdv
      setAppointmentLink(link)
    }
    getRdvLink()
  })
  React.useEffect(()=>{},[appointmentLink])

	const search = (value) => {
		if (value.trim() === '') return;
		const search = queryString.stringify({ _q: value, page: 1 });
		pushRoute({ pathname: links.PRODUCTS_LINK, search });
		setShowSearch(false);
	};

	const searchingClass = showSearch ? ' searching' : '';

	const toggleCart = () => {
		setCartToggle(!cartToggle);
		if (cartToggle === false) document.getElementById('cart').style.height = '700px';
		else document.getElementById('cart').style.height = '0';
	};

	return (
		<HeaderContainer className="mobile-header">
			<div style={{ backgroundColor: '#f7f2e9', paddingTop: '15px' }}>
				<div className="wrapper header-container">
					<AppMenu>
						{(handleOpen) => <MenuLogo onClick={handleOpen} />}
					</AppMenu>
					<div className="center">
						<AppLogo width="50px" />
					</div>
					<div className="rightHeader">
						{location.pathname.split("/")[1]==="categories" && <IconsSearch
							className={'search-button' + searchingClass}
							onClick={() => setShowSearch(!showSearch)}
						/>}
						<CartIcon onClick={toggleCart} />
					</div>
				</div>
			</div>
			<div className={'header-actions' + searchingClass}>
				<div className="wrapper header-container">
					<NavHashLink className="eshop_link" to={`${links.CATEGORIES_LINK}#top`}>E-SHOP</NavHashLink>
					<div className="flex"></div>
					<a href={appointmentLink}>
					PRENDRE RDV
				</a>
				</div>
				<SearchField
					visible={showSearch}
					setVisible={setShowSearch}
					pathname={pathname}
				/>
			</div>
				
		</HeaderContainer>
	);
};

const HeaderContainer = styled(Header)`
	position: relative;
	background-color: transparent;
`;

export default React.memo(MobileHeader);