import { Popover } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';

const ProductFilter = ({
  data,
  applyFilter,
  filterFromUrl,
  children,
  clearFilter,
}) => {
  //

  const [filterSelection, setFilterSelection] = React.useState({});
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    perfomFilterFRomUrl(filterFromUrl);
  }, [filterFromUrl]);

  const perfomFilterFRomUrl = (urlFilter) => {
    if (!urlFilter || urlFilter === null) return;
    for (const [key, value] of Object.entries(urlFilter)) {
      if (typeof value === 'string') urlFilter[key] = [value];
    }
    setFilterSelection({ ...urlFilter });
  };

  const handleApplyFilter = () => {
    applyFilter && applyFilter(filterSelection);
    setVisible(false);
  };

  const handleClearFilter = () => {
    setFilterSelection({});
    setVisible(false);
    clearFilter && clearFilter({});
  };

  const onAttributChange = (filterKey, attribut) => (e) => {
    const { checked } = e.target;

    const selection = { ...filterSelection };
    let attrubutsChecked = selection[filterKey];

    if (checked) {
      if (attrubutsChecked) selection[filterKey].push(attribut);
      else selection[filterKey] = [attribut];
    } else {
      if (attrubutsChecked) {
        attrubutsChecked = attrubutsChecked.filter((item) => item !== attribut);
        if (attrubutsChecked.length === 0) delete selection[filterKey];
        else selection[filterKey] = attrubutsChecked;
      }
    }

    setFilterSelection(selection);
  };

  const isSelected = (filterKey, attribut) => {
    const selection = { ...filterSelection };
    let attrubutsChecked = selection[filterKey];

    if (!attrubutsChecked) return false;

    const index = attrubutsChecked.findIndex((item) => item === attribut);
    if (index === -1) return false;

    return true;
  };

  const displayFiltersItems = (filter) =>
    (filter.filters_values || []).map((attribut, index) => (
      <div key={index}>
        <Checkbox
          key={attribut.id}
          onChange={onAttributChange(filter.name, attribut.name)}
          checked={isSelected(filter.name, attribut.name)}
        >
          <span className="shop-filters-text">{attribut.name}</span>
        </Checkbox>
      </div>
    ));

  const content = (
    <div>
      <div className="shop-filters">
        {data &&
          data.map((filter) => (
            <div key={filter.id} className="shop-filters-col" style={{margin: "0.5em"}}>
              <div>
                <p className="shop-filters-title">{filter.title}</p>
                {displayFiltersItems(filter)}
              </div>
            </div>
          ))}
      </div>

      <div className="shop-filters-btns">
        <button onClick={handleApplyFilter} className="shop-filters-btn">
          Appliquer
        </button>
        <button onClick={handleClearFilter} className="shop-filters-btn">
          Effacer
        </button>
      </div>
    </div>
  );

  const handleVisibleChange = (val) => {
    setVisible(val);
  };

  return (
    <Popover
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      content={content}
      placement="rightBottom"
    >
      {children}
    </Popover>
  );
};

export default ProductFilter;
