const SublimeSignature = ({
  signature_img,
  rotationAngleValue,
  widthValue,
  heightValue,
}) => {
  const imgWidth = widthValue ? widthValue : 290;
  const imgHeight = heightValue ? heightValue : 290;

  return (
    <div className="signature-img-wrapper">
      <img
        alt=""
        src={signature_img}
        style={{
          transform: 'rotate(' + rotationAngleValue + 'deg)',
          height: imgHeight,
          width: imgWidth,
        }}
      />
    </div>
  );
};

export default SublimeSignature;
