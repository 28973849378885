import { isValidValue } from '../../utils';
import * as types from './types';

export const openModal =
  ({ type, nextUrl }) =>
  (dispatch) => {
    dispatch({
      type: types.OPEN_MODAL,
      payload: { typeModal: type, nextUrl },
    });
  };

export const setAuthedAction =
  ({ user, jwt }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_AUTHED,
      payload: { user, isAuth: isValidValue(jwt), token: jwt },
    });
  };
