import { useHistory } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODALPAGES } from '../../../stores/app/reducer';
import { openModal, setAuthedAction } from '../../../stores/app/actions';

import image from '../../../assets/images/login.png';

import { notification } from 'antd';
import '../signin/style.less';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { mergeCart } from '../../../stores/cart/cartActions';
import { BASE_URL } from '../../../api/config';
import SignupForm from './Form';
import {
  registerAction,
  setLinkedAuthAction,
} from '../../../stores/auth/actions';
import useVitrinFetcher2 from '../../../stores/vitrin/hooks2';
import { getFileUrl } from '../../../utils';
import { nextUrlSelector } from '../../../stores/app/selector';

const Signup = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const url = useSelector(nextUrlSelector);

  const { signFormImage } =
    useVitrinFetcher2({
      endpoints: 'menu-config',
      name: 'menuData',
    }) || {};

  const [state, setState] = React.useState({
    username: '',
    email: '',
    password: '',
    newsletter: false,
    policy: false,
    loading: false,
  });

  const { loading } = state;

  const performSinginSuccess = async (data) => {
    setState({ ...state, loading: false });
    dispatch(setLinkedAuthAction());
    dispatch(openModal({ type: MODALPAGES.NONE }));
    dispatch(setAuthedAction(data));
    dispatch(mergeCart());
    openNotification('Inscription éffectuée avec succès !');

    url && history.push(url);
  };

  const performSinginError = async (error) => {
    console.log({ m: error.message });
    if (!error.message) return;

    const message = getErrorMessage(error.message);
    setState((state) => ({ ...state, error: message, loading: false }));
  };

  const onSubmit = async (values,subscribe) => {
    setState({ ...state, loading: true });

    dispatch(
      registerAction(values, (data)=>{
        if(data.user.newsletter) subscribe({EMAIL: data.user.email})
        performSinginSuccess(data)
      }, performSinginError)
    );

  };

  const getErrorMessage = (errorMessages = []) => {

    const allMessage = [];

    for (let index = 0; index < errorMessages.length; index++) {
      const element = errorMessages[index];

      for (let v = 0; v < element.messages.length; v++) {
        allMessage.push(element.messages[v]);
      }
    }

    const index = allMessage.findIndex(
      (item) => item.id === 'Auth.form.error.email.taken'
    );
    if (index !== -1) return 'Cette adresse e-mail est déjà utilisée.';

    return;
  };

  const connectFb = () => {
    setTimeout(function () {
      window.location = BASE_URL + '/connect/facebook';
    }, 100);
  };

  const connectGo = () => {
    setTimeout(function () {
      window.location = BASE_URL + '/connect/google';
    }, 100);
  };

  const onSignForm = () => {
    dispatch(openModal({ type: MODALPAGES.SIGNIN, nextUrl: url }));
  };

  return (
    <>
      <div id="signup">
        <div>
          <img
            className="illustration-img"
            src={signFormImage ? getFileUrl(signFormImage) : image}
            alt=""
          />
        </div>
        <div className="content-form">
          <p className="form-title">S'inscrire</p>

          <MailchimpSubscribe
            id="newsLetter"
            url={process.env.REACT_APP_MAILCHIMP_URL}
            render={({ subscribe ,status,message }) => {
              return (
                <>
                  <SignupForm
                    onConnectFb={connectFb}
                    onConnectGo={connectGo}
                    onSignForm={onSignForm}
                    onSubmit={onSubmit}
                    error={state.error}
                    loading={loading}
                    subscribe={subscribe}
                  />
                </>
              )
            }}
          />
        </div>
      </div>
    </>
  );
};

const openNotification = (text) => {
  const args = {
    message: '',
    description: text,
    duration: 5,
  };
  notification.open(args);
};

export default Signup;
