import React from 'react';

import './style.less';
import BannerSection from '../home/HomeSections/BannerSection';
import Spiner from '../../components/Spiner';
import { NavHashLink } from 'react-router-hash-link';

import { connect } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import * as actions from '../../stores/vitrin/actions';
import { vitrinSelector } from '../../stores/vitrin/selector';
import { getFileUrl } from '../../utils';
import { CREATRICE_LINK } from '../../Routes/links';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';

const Team = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'notre-equipe',
    name: NAME,
  });

  useHashScrooler({ data, location });

  const teamComponent = (dat) => {
    return data && (
      <div id="team" className="container">
        {dat.banniere && <BannerSection
          normalHeaderText={dat.banniere.titre_normal}
          italicHeadertext={dat.banniere.titre_italique}
          banner={[dat.banniere]}
        />}

        { dat.description_banniere && <SectionWrapper
          hashId={dat.description_banniere.hashId}
          className="elles-vous-sublime"
          style={
            dat.description_banniere.background
              ? { backgroundColor: dat.description_banniere.background }
              : { backgroundColor: '#51262E' }
          }
        >
          <div className="title-section">
            <p className="normal-title">
              {dat.description_banniere.titre_normal}
            </p>
            <p className="italic-title">
              {dat.description_banniere.titre_italique}
            </p>
          </div>
          <div className="elles-vous-sublime-text-content">
            <p>{HTMLReactParser(`${dat.description_banniere.description}`)}</p>
          </div>
        </SectionWrapper>}

        <div className="notreTeamSection">
          {dat.section_liste_des_membres?.map((member, index) => (
            <SectionWrapper
              key={member._id}
              className="team-card"
              hashId={member.hashId}
            >
              <div className="team-card-title-section">
                <p className="person-name">{member.titre_italique}</p>
                <div className="person-position-wrapper">
                  <p className="person-position">{member.poste_occupe}</p>
                </div>
              </div>
              <div className="team-card-content">
                <div className="team-card-content-img-wrapper">
                  <img
                    className="team-card-content-img"
                    alt=""
                    src={getFileUrl(member.desktop_media)}
                  />
                </div>
                <div className="team-card-content-text">
                  <p>{HTMLReactParser(`${member.description}`)}</p>
                  {index === 0 && (
                    <NavHashLink to={`${CREATRICE_LINK}#top`}>
                      <span className="creatrice-link">En savoir plus</span>
                    </NavHashLink>
                  )}
                </div>
              </div>
            </SectionWrapper>
          ))}
        </div>
      </div>
    );
  };

  if (!data) return <Spiner />;

  return teamComponent(data);
};

const NAME = 'notre-equipe';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Team);
