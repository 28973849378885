import moment from 'moment';
import { getDateCalendar } from '../../../utils.js';

export const buildCalendar = async (data, daysCount) => {
  const { firstSlot, availabletimeslots } = data;
  const calendarsData = [];

  let date = moment(firstSlot).format(dateFormat);
  let litteral = getDateCalendar(date, litteralFormat);
  calendarsData.push({ litteral, date });

  for (let i = 1; i < daysCount; i++) {
    const previews = calendarsData[i - 1];

    date = moment(previews.date).add(1, 'days').format(dateFormat);
    litteral = getDateCalendar(date, litteralFormat);
    calendarsData.push({ litteral, date });
  }

  for (let i = 0; i < calendarsData.length; i++) {
    const { date } = calendarsData[i];

    const dispo = await getAvailbalesHours(availabletimeslots, date);
    calendarsData[i].disponibility = dispo;
  }

  return calendarsData;
};

const getAvailbalesHours = async (restAvalibilities, date) => {
  let disponibility = [];

  for (let i = 0; i < restAvalibilities.length; i++) {
    const item = restAvalibilities[i];
    const isDay = moment(item.start).format(dateFormat) === date;

    if (isDay) {
      disponibility.push({
        hour: moment(item.start).format(hourFormat),
        calendar_ids: item.calendar_ids,
        start: item.start,
      });
    }
  }

  return disponibility;
};

const dateFormat = 'YYYY-MM-DD';
const hourFormat = 'HH-mm-ss';
const litteralFormat = 'ddd d MMMM';
