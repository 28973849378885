import React from 'react';
import { Select } from 'antd';
import countriesList from './config';

import styled from 'styled-components';

const CountrySelector = ({ className, value, onChange }) => {
  const handleChange = (value) => {
    onChange && onChange(value);
  };

  return (
    <CSelect
      className={className}
      placeholder="Choisissez un état ou un pays"
      value={value}
      onChange={handleChange}
      size="large"
      showSearch
      // onSearch={onSearch}
    >
      {countriesList.map((item,index) => (
        <Select.Option key={index} className="country-item" value={item.id}>
          {item.value}
        </Select.Option>
      ))}
    </CSelect>
  );
};

export default CountrySelector;

const CSelect = styled(Select)`
  .ant-select-item {
    font-size: 20px;
  }
`;
