import React from 'react';

import PhoneField from './PhoneField';
import { Form, Input,Checkbox } from 'antd';
import styled from 'styled-components';
import AdsButton from '../home/Button/AdsButton';
import SectionWrapper from '../../components/SectionWrapper';

const ContactForm = ({ handleSubmit, hashId,subscribe}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = React.useState(false);

  const onFinish = (values) => {
    setLoading(true);

    const data = {
      ...values,
    };

    handleSubmit &&
      handleSubmit(data, () => {
        form.resetFields();
        setLoading(false);
      },subscribe);
  };

  return (
    <SectionWrapper hashId={hashId}>
      <Form
        className="contact-form"
        form={form}
        initialValues={initialValues}
        size="large"
        onFinish={onFinish}
      >
        <Form.Item name="lastName" required rules={rules.lastName}>
          <CInput className="form-input" placeholder="Nom" />
        </Form.Item>

        <Form.Item name="firstName" rules={rules.firstName}>
          <CInput className="form-input" placeholder="Prénom(s)" />
        </Form.Item>

        <Form.Item name="email" rules={rules.email}>
          <CInput className="form-input" placeholder="Email" />
        </Form.Item>

        <Form.Item name="phoneNumber" rules={rules.phoneNumber}>
          <PhoneField placeholder="Numéro de téléphone" />
          {/* </Tooltip> */}
        </Form.Item>

        <Form.Item name="message" required rules={rules.message}>
          <Input.TextArea
            className="form-input"
            placeholder="Entrez votre message"
          />
        </Form.Item>
        <Form.Item name="newsletter" valuePropName="checked" noStyle>
          <Checkbox className="newsletter-checkbox">
            Inscrivez-vous à la newsletter pour bénéficier d'un accès exclusif et
            privilégié aux soldes, aux nouveaux arrivages et aux promotions.
          </Checkbox>
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <AdsButton
              text="Soumettre"
              htmlType="submit"
              className="submit-button contact-buton"
              id="adz-reset-button"
              loading={loading}
              isSubmitButton={true}
            />
          )}
        </Form.Item>
      </Form>
    </SectionWrapper>
  );
};

export default ContactForm;

const rules = {
  lastName: [
    {
      required: true,
      message: 'Entrez votre nom!',
    },
    {
      min: 2,
      message: 'Entrez votre nom!',
    },
  ],

  firstName: [
    {
      required: true,
      message: 'Entrez votre prénom!',
    },
    {
      min: 2,
      message: 'Entrez votre prénom!',
    },
  ],

  email: [
    {
      required: true,
      message: 'Veuillez entrer une adresse e-mail',
    },
    {
      type: 'email',
      message: 'Veuillez entrer une adresse e-mail',
    },
  ],

  phoneNumber: [
    {
      required: true,
      message: 'Entrez votre numéro de téléphone',
    },
    // {
    //   message: "Entrez votre numéro de téléphone",
    //   pattern: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    // },
  ],

  message: [
    {
      required: true,
      message: 'Entrez votre message',
    },
    {
      min: 2,
      message: 'Entrez votre message!',
    },
  ],
};

const initialValues = {
  message: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
};

const CInput = styled(Input)``;
