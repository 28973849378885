/* eslint-disable eqeqeq */
import { Row, Col } from 'antd';
import './style.less';
import Related from './Related';
import { NavHashLink } from 'react-router-hash-link';
import AdsButton from '../home/Button/AdsButton';
import BannerSection from '../home/HomeSections/BannerSection';

import { vitrinSelector } from '../../stores/vitrin/selector';
import * as actions from '../../stores/vitrin/actions';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import HTMLReactParser from 'html-react-parser';
import React from 'react';
import useEssentialScrooler from '../../hooks/useEssentialScrooler';

const Detail = ({ match, data, setData, location, pageData }) => {
  //

  const setDatas = () => {
    if (!data)
      setData({
        endpoints: 'blogs',
        name: NAME,
      });

    if (!pageData)
      setData({
        endpoints: 'page-blog',
        name: PAGE_NAME,
      });
  };

  React.useEffect(() => {
    setDatas();
    
  }, []);

  useEssentialScrooler({
    data: pageData,
    location,
    hashId: '#contenu',
    property: 'singleEssentialAnchor',
  });

  if (!data) return <Spiner />;

  const { id } = match.params;
  let blogs = data.filter((article) => article.slug === id);
  let blogsDetail = blogs[0].post[0];

  return (
    <div id="blog">
      <div className="container detail">
        <div className="banner-section">
          <BannerSection
            normalHeaderText={blogsDetail.normal_title}
            italicHeadertext={blogsDetail.italic_title}
            banner={[{ ...blogsDetail }]}
          />

          <div id="contenu" style={{ marginBottom: '50px' }} />

          <Row className="space-content" gutter={20}>
            <Col xs={24} sm={20} md={20} lg={20} className="">
              <div className="content-blog">
                {HTMLReactParser(`${blogsDetail.big_description}`)}
              </div>
            </Col>
            <Col xs={24} sm={4} md={4} lg={4} className="">
              <div className="title-post-recent">Articles récents </div>
              <div>
                {data
                  .filter((article) => article.slug != id)
                  .map((item, index) => (
                    <NavHashLink to={`/blogs/${item.slug}/#top`} key={index}>
                      <li className="liste">
                        {item.post[0].normal_title} {item.post[0].italic_title}
                      </li>
                    </NavHashLink>
                  ))}
              </div>
            </Col>
          </Row>
          <Row className="suite-blog">
            <Col xs={12} sm={12} md={12} lg={12} className="title-also">
              VOUS POUVEZ ÉGALEMENT AIMER
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="btnblog">
              <AdsButton text="RETOUR AU BLOG" href="/blogs" />
            </Col>
          </Row>
          <Related others={data.filter((article) => article.slug != id)} />
        </div>
      </div>
    </div>
  );
};

const NAME = 'blogs';
const PAGE_NAME = 'pageBlogs';

const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
    pageData: vitrinSelector(state, PAGE_NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Detail);
