export const pushTRansactionOnTagManager = (window, value) => {
  var dataLayer = window.dataLayer;

  const {
    payment,
    amount,
    totalTaxes,
    shipping = {},
    cart = [],
    promotion = {},
  } = value;

  const products = cart.map((item) => ({
    name: item.name, // Name or ID is required.
    id: item.product._id,
    price: item.sale,
    quantity: item.qte,
    coupon: promotion.code, // Optional fields may be omitted or set to empty string.
  }));

  const layerData = {
    event: 'transaction',
    ecommerce: {
      purchase: {
        actionField: {
          id: payment?._id, // Transaction ID. Required
          revenue: amount, // Total transaction value (incl. tax and shipping)
          tax: totalTaxes,
          shipping: shipping?.amount,
        },
        products, //expand this array if more product exists
      },
    },
  };

  dataLayer.push(layerData);
};

export const performDatalayer = (
  window,
  data,
  { id, qte },
  event = 'cart-add'
) => {
  //

  if (data !== null) {
    const item = data.content.find((item) => item.product._id === id);

    if (window !== undefined && item !== undefined) {
      addToLayer(window.dataLayer, event, { ...item.product, qte });
    }
  }
};

const addToLayer = (dataLayer = [], event, product) => {
  const { qte } = product;
  const qteData = qte ? { qte } : {};

  dataLayer.push({
    event,
    item: {
      name: product.name, // Name or ID is required.
      id: product._id,
      price: product.sale,
      ...qteData,
      slug: product.slug,
    },
  });
};
