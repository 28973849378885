import { BASE_URL } from '../../api/config';
import { getToken } from '../../api/utils';
import http from '../../api/http';

export const getProductBySlugUrl = (slug) => {
  return `${BASE_URL}/productbyslug/${slug}`;
};

export const getProductsCatalog = ({ filters, page }) => {
  return `${BASE_URL}/products/`;
};

export const makeStockALertApi = async (id) => {
  const token = getToken();

  return http
    .post(
      `/stock-alert-requests/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteStockALertApi = async (id) => {
  const token = getToken();

  return http
    .delete(`/stock-alert-requests/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getAlertDemandApi = async (id) => {
  const token = getToken();

  return http
    .get(`/stock-alert-requests`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
