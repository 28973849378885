import React from 'react';
import { Breadcrumb } from 'antd';
import { NavHashLink } from 'react-router-hash-link';
import { CATEGORIES_LINK } from '../../../Routes/links';
import styled from 'styled-components';

const CategoryBreadcrumb = ({ deepItems = {}, getLink, pageName }) => {
  const { breadcrumb: values = [] } = deepItems;

  const formatTitle = (item) => (
    <NavHashLink to={getLink(item.slug) || "#"}>{item.title}</NavHashLink>
  );

  return (
    <CBreackPoint separator=">" className="cat-breadcrumb">
      <Breadcrumb.Item>
        <NavHashLink to={CATEGORIES_LINK + '#top'}> E-shop </NavHashLink>{' '}
      </Breadcrumb.Item>

      {values.map((item, index) => (
        <Breadcrumb.Item key={item.slug}>
          {!pageName && index === values.length - 1
            ? item.title
            : formatTitle(item)}
        </Breadcrumb.Item>
      ))}

      {pageName && <Breadcrumb.Item>{pageName}</Breadcrumb.Item>}
    </CBreackPoint>
  );
};

export default CategoryBreadcrumb;

const CBreackPoint = styled(Breadcrumb)`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
`;
