import React from 'react';
import './style.less';
import BannerSection from '../home/HomeSections/BannerSection';
import data from './data';
import TextualCollapse from './Collapse/TextualCollapse';
import IllustratorCOllapse from './Collapse/illustratorCOllapse';
import TitleCollapseTwo from './Collapse/TitleCollapseTwo';
import { Row, Col } from 'antd';

import Spiner from '../../components/Spiner';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';

const { illustratorCollapse, textualCollapse } = data;

const Manufacturing = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'tarifs-et-delais-de-fabrication',
    name: NAME,
  });

  useHashScrooler({ location, data });

  const illustratorSections = () =>
    data.sectionContentPage.map((item, index) => (
      <IllustratorCOllapse key={index} item={item} index={index} />
    ));

  const textualSection = () => (
    <SectionWrapper hashId={data.sectionQuestionTitre.hashId}>
      <TitleCollapseTwo
        normalTitle={data.sectionQuestionTitre.titre_normal}
        italicTitle={data.sectionQuestionTitre.titre_italic}
      />
      <>
        <Row gutter={(0, 40)}>
          {data.question_accordeon.map((item, index) => (
            <Col key={index} xs={24} sm={12} md={12} lg={12}>
              <TextualCollapse item={item} index={index} />
            </Col>
          ))}
        </Row>
      </>
    </SectionWrapper>
  );

  if (!data) return <Spiner />;

  return (
    <div id="manufacturing" className="container">
      <div className="banner-section">
        <BannerSection
          normalHeaderText={data.banniere.titre_normal}
          italicHeadertext={data.banniere.titre_italique}
          banner={[data.banniere]}
        />
      </div>

      {illustratorCollapse && illustratorSections()}
      {textualCollapse && textualSection()}
    </div>
  );
};

const NAME = 'tarifs-et-delais-de-fabrication';

export default Manufacturing;
