import React from 'react';
import './productCard.less';
import useWhishList from '../../stores/wishList/hook';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import {
  addWishNotification,
  removeWishNotification,
} from '../../pages/eshop/SingleProduct/notifications';

import { useSelector } from 'react-redux';
import { openModal } from '../../stores/app/actions';
import { isAuthSelector } from '../../stores/auth/selector';

import { MODALPAGES } from '../../stores/app/reducer';
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from '../../stores/wishList/actions';

const WishLogo = ({
  productId,
  children = defaultChild,
  filledChildren = defaultFilledChild,
}) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);
  const { isWished } = useWhishList({ productId });

  const handleAddWishList = async (e) => {
    e.stopPropagation();
    if (!isAuth) return dispatch(openModal({ type: MODALPAGES.SIGNIN }));

    let done;

    if (!isWished) {
      done = dispatch(addItemToWishlist({ id: productId, window }));
    } else {
      done = dispatch(removeItemFromWishlist(productId));
    }
    if (done) {
      onClickWish();
    }
  };

  const onClickWish = () => {
    if (!isWished) addWishNotification();
    else removeWishNotification();
  };

  if (!isWished) return children({ onClick: handleAddWishList });

  return filledChildren({ onClick: handleAddWishList });
};

export default WishLogo;

const defaultChild = ({ onClick }) => <HeartOutlined onClick={onClick} />;
const defaultFilledChild = ({ onClick }) => (
  <HeartFilled style={{ color: '#B22222' }} onClick={onClick} />
);
