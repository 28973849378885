import * as types from './cartTypes';

const INITIAL_STATE = {
  openCart: false,
};

const cartReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_CART:
      return { ...state, data: payload };
    case types.SET_CART:
      return { ...state, data: payload };
    case types.SET_CART_CONTENT:
      return { ...state, data: payload };
    case types.CLEAR_CART:
      return { ...state, data: undefined };
    default:
      return {
        ...state,
      };
  }
};

export default cartReducer;

export const key = 'cart';
