import React from "react";

const ProductAdvantageCard = ({ advantageNum, text }) => {
  return (
    <div className="produit-advantage">
      <span className="advantage-number">{advantageNum}</span>
      {/* <span className="advantage-slash">/</span> */}
      <span className="advantage-main-text">{text}</span>
    </div>
  );
};

export default ProductAdvantageCard;
