import React from 'react';
import { getFileUrl } from '../../../utils';
import './productCard.less';
import { useHistory } from 'react-router-dom';
import { PRODUCTS_LINK } from '../../../Routes/links';
import { showCardPrice } from '../../../components/Cart/utils';

const ProductCard = ({ product, showPrice = false }) => {
  const { image, name, slug, price, sale } = product;
  const history = useHistory();

  const onClick = () => {
    history.push(PRODUCTS_LINK + '/' + slug);
  };

  return (
    <div className="product-card">
      <div onClick={onClick} className="product-card-img-wrapper">
        <img src={getFileUrl(image, {small:true})} 
        alt="" />
      </div>
      {showPrice ? (
        <div className="product-card-detail">
          <p onClick={onClick} className="product-card-name">
            {name}
          </p>
          <p className="product-card-amount">{showCardPrice(price, sale)}€</p>
        </div>
      ) : (
        <p className="product-card-text">{name}</p>
      )}
    </div>
  );
};

export default ProductCard;
