import { loginApi, registerApi, updateUserApi } from './api';
import { MODALPAGES } from './reducer';
import * as types from './types';

export const setAuthFromLocaldata = () => async (dispatch) => {
  let user = localStorage.getItem('user');
  user && (user !== 'undefined') && (user = JSON.parse(user))
  let token = localStorage.getItem('token');

  const data = {
    user,
    token,
  };

  dispatch({
    type: types.SET_AUTH,
    payload: data,
  });

  return data;
};

export const logoutAction = () => async (dispatch) => {
  localStorage.clear();

  dispatch({
    type: types.LOG_OUT,
  });
};

export const setLinkedAuthAction =
  (mustAuth = false, nextAuthedLink = undefined, typeModal = MODALPAGES.NONE) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_MUST_AUTHED,
      payload: {
        mustAuth: mustAuth,
        nextAuthedLink: nextAuthedLink,
        typeModal: typeModal,
      },
    });
  };

const performValidUser = async (data, dispatch) => {
  const token = data.jwt;
  const user = data.user;
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));

  dispatch({
    type: types.SET_AUTH,
    payload: { user, token },
  });
};

export const registerAction = (value, resolve, reject) => async (dispatch) => {
  try {
    const data = await registerApi(value);
    await performValidUser(data, dispatch);

    resolve(data);
  } catch (error) {
    reject(error);
  }
};

export const signinAction = (user, resolve, reject) => async (dispatch) => {
  try {
    const data = await loginApi(user);

    await performValidUser(data, dispatch);
    resolve(data);
  } catch (error) {
    reject(error);
  }
};

export const updateUserDataAction =
  ({ userId, data }, resolve, reject) =>
  async (dispatch) => {
    //

    try {
      const result = await updateUserApi(userId, data);
      localStorage.setItem('user', JSON.stringify(result));

      dispatch({
        type: types.SET_USER_DATA,
        payload: result,
      });

      resolve && resolve(result);
    } catch (error) {
      reject && reject(error);
    }
  };
