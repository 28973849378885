import React from "react";
import "./headerTextNormal.less";

const HeaderH3TextNormal = ({ text, customStyle }) => {
  return (
    <>
      <h2 className="header-h3-text-normal" style={customStyle}>
        {text}
      </h2>
    </>
  );
};

export default HeaderH3TextNormal;
