import { useEffect } from 'react';

function useEssentialScrooler({
  location,
  data,
  hashId,
  property = 'essentialAnchor',
}) {
  //

  useEffect(() => {
    performScrolling();

    return () => {};
    // 
  }, [data, location]);

  const performScrolling = () => {
    const { hash } = location || {};
    let mustScrool = hash === hashId;

    const hashCopy = hash.replace('#', '');

    if (!mustScrool) {
      if (hashCopy !== '' && hashCopy !== 'top') return;

      mustScrool =
        data && data[property] && document.querySelector(hashId) !== null;
    }

    if (mustScrool) scroolToEssential();
  };

  const scroolToEssential = () => {
    setTimeout(() => {
      const nodes = document.querySelector(hashId);
      if (nodes && nodes !== null)
        nodes.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center',
        });
    }, 2000);
  };

  return;
}

export default useEssentialScrooler;
