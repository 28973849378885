import React from 'react';
import Img from 'react-cool-img';

const Maps = ({ className, imgsrc }) => {
  return (
    <Img
      src={imgsrc}
      alt="google maps"
      className={`contact-maps ${className}`}
    />
  );
};

export default Maps;
