import { performDatalayer } from '../tagLayerUtils';
import { addItemApi, removeItemApi, removeAllApi, getItemsApi } from './api';
import * as types from './type';

export const setWishlistContent = (payload) => async (dispatch) => {
  dispatch({
    type: types.SET_WISHLIST_CONTENT,
    payload,
  });
};

export const refreshWishlist = () => async (dispatch) => {
  let data = await getItemsApi();

  if (data) {
    dispatch({
      type: types.SET_WISHLIST_CONTENT,
      payload: data,
    });
  }
};

export const addItemToWishlist = (payload) => async (dispatch) => {
  const { id, window } = payload;

  // ​<a href="#" name="button1" onclick="dataLayer.push({'event': 'button1-click'});">Button 1</a>

  const data = await addItemApi(id);

  if (data) {
    dispatch({
      type: types.SET_WISHLIST_CONTENT,
      payload: data,
    });

    performDatalayer(window, data, { id }, 'wishlist-add');

    return true;
  }

  return false;
};

export const removeItemFromWishlist = (id) => async (dispatch) => {
  const data = await removeItemApi(id);

  if (data) {
    dispatch({
      type: types.SET_WISHLIST_CONTENT,
      payload: data,
    });
  }
};

export const removeAllFromWishlist = () => async (dispatch) => {
  const data = await removeAllApi();

  if (data) {
    dispatch({
      type: types.SET_WISHLIST_CONTENT,
      payload: data,
    });
    return true;
  } else return false;
};

export const clearWishlist = () => async (dispatch) => {
  dispatch({
    type: types.CLEAR_WISHLIST,
  });
};
