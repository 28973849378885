import React from "react";
import "./headerTextNormal.less";

const HeaderH1TextItalic = ({ text, isCenter, shouldBringUp, isRedColor, isSmaller }) => {
  const classesToApply = () => {
    let classesList = "header-h1-text-italic";

    if (isCenter) {
      classesList = classesList + " center-text";
    }
    
    if (shouldBringUp) {
      classesList = classesList + " bring-upfront";
    }
    if (isRedColor) {
      classesList = classesList + " red-text-color";
    }

    if (isSmaller) {
      classesList = classesList + " font-smaller";
    }

    return classesList;
  };
  return (
    <>
      <h1 className={classesToApply()}>{text}</h1>
    </>
  );
};

export default HeaderH1TextItalic;
