import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import './userDetails.less';

import iconDetails from '../../../assets/icons/details.png';
import ChangePassword from './changePassword';
import RequestPassword from './RequestPassword';
import ChangeProfile from './changeProfile';
import BillingAddress from './billingAddress';
import { useDispatch } from 'react-redux';
import Spiner from '../../../components/Spiner';
import { updateUserDataAction } from '../../../stores/auth/actions';

const UserDetails = ({ logOut, userData, toggleNavbar }) => {
  //

  const dispatch = useDispatch();
  const [token, setToken] = useState('');

  useEffect(() => {
    document.body.scroll(0, 0);
    extractParamsUrl(window.location.href);
    // 
  }, [window.location.href]);

  const extractParamsUrl = (chaineGET) => {
    chaineGET = chaineGET.split('&');
    var result = {};

    chaineGET.forEach(function (el) {
      var param = el.split('=');
      param[0] = param[0].replace('?', '');
      result['token'] = param[1];
    });
    setToken(result.token);
    // setUrl2(BASE_URL + result.token);
  };

  if (!userData) {
    return <Spiner />;
  }

  const onSubmitUserChange = (data, callback) => {
    dispatch(
      updateUserDataAction({ userId: userData.id, data }, callback, (error) =>
        callback({ error })
      )
    );
  };

  return (
    <div id="details" className="details">
      <Row>
        <Col md={12} sm={11} xs={21}>
          <h2 className="details__title">Détails du compte</h2>
        </Col>

        <Col sm={0} xs={3} className="navbar-toggle">
          <button
            onClick={() => toggleNavbar()}
            className="navbar-toggle__button"
          >
            <img src={iconDetails} alt="details.png" />
          </button>
        </Col>

        <Col md={12} sm={13} className="details__buttons">
          <button className="details__button board__logout" onClick={logOut}>
            DECONNEXION
          </button>
        </Col>
      </Row>

      {userData && (
        <ChangeProfile
          user={userData}
          onSubmitUserChange={onSubmitUserChange}
        />
      )}

      {token ? <ChangePassword token={token} /> : <RequestPassword />}

      {userData && (
        <>
          <BillingAddress
            user={userData}
            onSubmitUserChange={onSubmitUserChange}
          />
        </>
      )}
    </div>
  );
};

export default UserDetails;
