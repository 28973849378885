import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import ProductCard from '../../components/shop/ProductCard';
import HighlightingItem from '../../components/shop/HighlightingItem';
import SectionWrapper from '../../components/SectionWrapper';

const HighlightingCollection = ({ rootData, items = [] }) => {
  //

  const { hashId } = rootData;

  const firstRow = (
    <Row gutter={70}>
      <Col xs={24} sm={12} lg={12} md={12}>
        <p className="titleNew"> {rootData.label}</p>
        <p
          className="descriptionNew"
          dangerouslySetInnerHTML={{
            __html: rootData.text_introductif,
          }}
        ></p>
      </Col>

      {items[0] && (
        <Col xs={24} sm={12} lg={12} md={12}>
          <HighlightingItem item={items[0]} height="640px" />
        </Col>
      )}
    </Row>
  );

  const secondRow = (
    <Row gutter={70} className="second-row">
      {items[1] && (
        <Col xs={24} sm={12} lg={12} md={12}>
          <HighlightingItem item={items[1]} height="640px" />
        </Col>
      )}

      {items[2] && (
        <LastProdCol xs={24} sm={12} lg={12} md={12}>
          <HighlightingItem item={items[2]} height="324px" />
        </LastProdCol>
      )}
    </Row>
  );

  const hasInfos = rootData.infos && rootData.infos !== null;

  const thirdRow = () => {
    const forFiveProducts = items.slice(3);

    return (
      <Row gutter={50}>
        {forFiveProducts.map((product, index = product._id) => (
          <Col
            key={index}
            lg={hasInfos ? 12 : 8}
            md={hasInfos ? 12 : 8}
            sm={12}
            xs={24}
          >
            <ProductCard data={product} showAppointmentLink={false} />
          </Col>
        ))}
      </Row>
    );
  };

  const smWidth = hasInfos ? 12 : 24;

  const colProps = {
    xs: 24,
    sm: hasInfos,
    lg: smWidth,
    md: smWidth,
  };

  return (
    <SectionWrapper hashId={hashId} className="highlighting-section">
      <div className="title-header">
        <h1 className="header-h1-text-italic center-text bring-upfront">
          {rootData.title}
        </h1>
      </div>

      {firstRow}

      {secondRow}

      <Row gutter={70}>
        <Col {...colProps}>{thirdRow()}</Col>

        <Col {...colProps}>
          <p
            className="descriptionNew"
            dangerouslySetInnerHTML={{
              __html: rootData.infos,
            }}
          ></p>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default HighlightingCollection;

const LastProdCol = styled(Col)`
  display: flex;
  align-items: flex-end;

  .highlightingItem {
    width: 100%;
  }
`;
