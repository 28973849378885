import React from 'react';
import './style.less';

import BannerSection from '../home/HomeSections/BannerSection';
import { MOBILE_SIZE } from '../../config';
import useWindowSize from '../../hooks/useWindowSize';
import CreatorShortMenuCard from './CreatorShortMenuCard';

import { connect } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import * as actions from '../../stores/vitrin/actions';
import { vitrinSelector } from '../../stores/vitrin/selector';
import Spiner from '../../components/Spiner';
import { getFileUrl } from '../../utils';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';

const TheCreator = ({ location }) => {
  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  const data = useVitrinFetcher2({
    endpoints: 'creatrice',
    name: NAME,
  });

  useHashScrooler({ location, data });

  const creatorComponent = (dat) => {
    return data && (
      <div id="the-creator" className="container">
        {dat.banniere && <BannerSection
          normalHeaderText={dat.banniere.titre_normal}
          italicHeadertext={dat.banniere.titre_italique}
          banner={[dat.banniere]}
        />}

        {dat.sectioAboutMe && <SectionWrapper
          hashId={dat.sectioAboutMe.hashId}
          className="de-moi-section"
        >
          <h3 className="de-moi-section-title">
            {dat.sectioAboutMe.title}
          </h3>
          <img
            className="de-moi-section-img"
            // Do not call mobile_media on this one, there is not version of it
            src={getFileUrl(dat.sectioAboutMe.desktop_media, {
              small: isMobile,
            })}
            alt="Carine Mas"
          />

          <div className="de-moi-description">
            {HTMLReactParser(`${dat.sectioAboutMe.description}`)}
          </div>
        </SectionWrapper>}

        <div className="short-menu-section">
          {dat.sectionArticlepanel?.map((item, index) => {
            const { hashId } = item;
            return (
              <CreatorShortMenuCard
                key={item._id}
                title={item.title}
                items={item.description}
                hashId={hashId}
              />
            );
          })}
        </div>
      </div>
    );
  };

  if (!data) return <Spiner />;

  return creatorComponent(data);
};

const NAME = 'creatrice';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(TheCreator);
