import { getUsers } from '../../api/utils';
import {
  deleteStockALertApi,
  getAlertDemandApi,
  makeStockALertApi,
} from './api';
import * as types from './types';

export const setAlertDemandsAction = (payload) => async (dispatch) => {
  const user = getUsers();

  let data;

  if (user) {
    data = await getAlertDemandApi();
  } else {
    // const dataTemp = await setTempItemQteApi({ product: id, qte });
    // data = await buildTempCartApi(dataTemp);
  }

  if ((data && !data.error) || (data && data.content)) {
    dispatch({
      type: types.SET_ALERT_DEMANDS,
      payload: data,
    });
  }

  return data;
};

export const makeStockALertAction =
  ({ id, operation = '+' }) =>
  async (dispatch) => {
    const user = getUsers();

    let data;

    if (!user) return;

    data =
      operation === '+'
        ? await makeStockALertApi(id)
        : await deleteStockALertApi(id);

    if ((data && !data.error) || (data && data.content)) {
      dispatch({
        type: types.SET_ALERT_DEMANDS,
        payload: data,
      });
    }

    return data;
  };
