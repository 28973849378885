import { Row, Col, notification } from 'antd';
import { secondary } from '../../styles/config';
import { getFileUrl, isValidValue } from '../../utils';

export const getCartArgs = ({ image, name, price, error, errorMessage }) => ({
  placement: 'bottomRight',
  message: (
    <p
      className="notif__title "
      style={{ fontSize: '40px', marginBottom: '15px' }}
    >
      Ajout au panier
    </p>
  ),
  className: 'notif',
  description: (
    <Row
      gutter={15}
      style={{
        fontFamily: 'Frunchy',
        fontSize: '30px',
        color: '#333333',
        lineHeight: '28px',
      }}
    >
      <Col span={7}>
        <div className="notif__image-wrapper">
          <img
            className="notif__image"
            style={{ width: '100%', height: '90px', objectFit: 'cover' }}
            src={getFileUrl(image, { xs: true })}
            alt={name}
          />
        </div>
      </Col>
      <Col span={17}>
        {error
          ? getErrorMessage({ name, errorMessage, price })
          : getSuccessMessage({ name, price })}
        {/* <p 
           className="notif__details notif_panier" 
           style={{marginBottom: "0px", textAlign: "right", cursor: "pointer"}}>
             Panier</p> */}
      </Col>
    </Row>
  ),
});

const getSuccessMessage = ({ name, price }) => (
  <>
    <p style={{ marginBottom: '0px' }}>{name}</p>
    <p style={{ marginBottom: '0px', fontWeight: '600' }}>{price}€</p>
  </>
);

const getErrorMessage = ({ name, errorMessage, price }) => (
  <>
    <p style={{ marginBottom: '0px' }}>{name}</p>
    <p style={{ marginBottom: '0px', fontWeight: '500', color: secondary }}>
      {errorMessage}
    </p>
  </>
);

export const openCartNotification = ({
  image,
  name,
  price,
  error,
  errorMessage,
}) => {
  const message =
    error === 'Unauthorized'
      ? "Une erreur s'est produite. Veuillez vous reconnecter svp !"
      : errorMessage;

  const cartArgs = getCartArgs({
    image,
    name,
    price,
    error,
    errorMessage: message,
  });
  notification.open(cartArgs);
};

export const performAddToCard = async (
  addFunc,
  { qte = 1, id, variation, name, ...product },
  window
) => {
  const { sale, image } = getRealDetails(product, variation);

  const data = await addFunc({ id, qte, variation, window });

  if (data)
    openCartNotification({
      name,
      image,
      price: sale,
      error: data.error,
      errorMessage: data.message,
    });
};

export const getRealDetails = (product, variation) => {
  let { price, image, sale = price, stock } = product || {};
  let realVariation = variation;

  // if (product.variations)
  //   realVariation = product.variations.find(
  //     (item) => item.slug === variation.slug
  //   );

  if (isValidValue(variation) && variation._id) {
    price = realVariation.price || price;
    image = realVariation.image || image;
    sale = realVariation.sale || sale;
    stock = realVariation.stock;
  }

  sale = sale === null ? price : sale;

  return { ...product, sale, price, image, stock };
};

export const showCardPrice = (price, sale = price) => {
  //

  if (
    sale !== undefined &&
    [null, price].findIndex((item) => item === sale) === -1
  ) {
    return (
      <>
        {sale}€
        <br />
        {price && price !== null && price > 0 && (
          <strike
            style={{
              color: '#7d7272',
            }}
          >
            {price}€
          </strike>
        )}
      </>
    );
  }

  return <> {price || 0} € </>;
};

export const getProductInfos = (product, variation) => {
  let { stock, isSoldOut, manageStock, ...rest } = getRealDetails(
    product,
    variation
  );
  if (!manageStock) isSoldOut = false;
  else if (stock <= 0 || stock === null) {
    isSoldOut = true;
    stock = 0;
  }

  return { isSoldOut, stock, manageStock, ...rest };
};

export const getTempStock = ({
  cart,
  productId,
  stock = 0,
  variation_slug,
  manageStock,
}) => {
  if (!manageStock) return 10;

  if (!isValidValue(cart)) return stock;

  const { content } = cart;
  if (!isValidValue(content)) return stock;

  const item = content.find(
    (item) =>
      item?.product?.id === productId && item?.variation_slug === variation_slug
  );

  if (!isValidValue(item)) return stock;

  const diff = stock - (item.qte || 0);

  return diff >= 0 ? diff : 0;
};
