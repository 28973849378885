import './style.less';
import { Row, Col } from 'antd';
import { getFileUrl } from '../../utils';
import Spiner from '../../components/Spiner';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';
const Prestation = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'nos-prestations',
    name: NAME,
  });

  useHashScrooler({ location, data });

  if (!data) return <Spiner />;
  return (
    <div id="prestations">
      <div className="container">
        <div className="title-header">
          <h1 className="header-h1-text-italic center-text bring-upfront">
            {data.sectionPageTitle.sectionTitle}
          </h1>
        </div>
        {data.listOfPrestation.map((item, index) => (
          <SectionWrapper key={index} hashId={item.hashId} className="prestation-container">
            <Row key={index}>
              <Col
                xs={24}
                sm={12}
                lg={12}
                md={12}
                className="prestation-card__left-wrapper"
              >
                <p id={index} className="prestation-title">
                  {item.title}
                </p>
                <p className="prestation-title-mobile">{item.titleMobile}</p>
                <div className="prestation-image">
                  <img
                    src={getFileUrl(item.desktop_media)}
                    alt="prestations"
                    className="image-prestation"
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={12}
                lg={12}
                md={12}
                style={{
                  borderBottom: '1px solid #333333',
                }}
                className="description-col"
              >
                <div className="content-description">
                  <p
                    className="prestation-description"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></p>
                </div>
              </Col>
            </Row>
          </SectionWrapper>
        ))}
      </div>
    </div>
  );
};

const NAME = 'nos-prestations';

export default Prestation;
