import React, { useState } from 'react';
import { Image } from 'antd';
import { getFileUrl } from '../utils';

const ImagesPreviewer = ({ open, setOPen, images = [] }) => {
  //

  React.useEffect(() => {
    open && setVisible(open);
  }, [open]);

  const [visible, setVisible] = useState(false);

  const handleClose = (vis) => {
    setVisible(vis);
    setOPen && setOPen(false);
  };

  if (!images || images.length === 0) return <> </>;

  return (
    <>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => handleClose(vis) }}
        >
          {images.map((item, index) => (
            <Image key={index} src={getFileUrl(item)} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default ImagesPreviewer;
