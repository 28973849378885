import { notification } from 'antd';

const openNotificationWithIcon = ({ type, message, description, icon }) => {
  notification[type]({
    message,
    description,
    icon,
    className: 'newsletter-notification',
  });
};

export default openNotificationWithIcon;
