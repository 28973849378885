import React from "react";
import "./itemsNavigation.less";

import LeftArrow from "../../../assets/images/left-arrow.svg";
import RightArrow from "../../../assets/images/right-arrow.svg";

const ItemsNavigationOnImage = ({ handleLeftArrow, handleRightArrow }) => {
  return (
    <>
      <div className="produit-navigation-wrapper-on-image">
        <img
          onClick={handleLeftArrow}
          className="make-it-clickable"
          src={LeftArrow}
          alt='left-arrow'
        />
        <img
          onClick={handleRightArrow}
          className="make-it-clickable"
          src={RightArrow}
          alt="right-arrow"
        />
      </div>
    </>
  );
};

export default ItemsNavigationOnImage;
