import React from 'react';
import styled from 'styled-components';

const AppointmentItem = ({ item, className = '' }) => {
  return (
    <div className={`appointItem ${className}`}>
      <DName className="name">{item.publicname}</DName>
      <Description className="description">{item.description}</Description>
    </div>
  );
};

export default AppointmentItem;

const DName = styled.div`
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
  margin-top: 20px;
  width: 80%;
  line-height: 20.8px;

  @media (max-width: 1024px) {
    margin-top: 0;
  }

  @media (max-width: 766px) {
    font-size: 11px;
    font-weight: 500;
    width: 95%;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 15px;
  line-height: 18px;
  width: 80%;

  @media (max-width: 766px) {
    font-size: 11px;
    margin-top: 15px;
    width: 95%;
  }
`;
