import React from 'react';
import { ReactComponent as NotImg } from '../../assets/images/404.svg';
import { ReactComponent as RibonRight } from '../../assets/images/404RibonRight.svg';
import { ReactComponent as RibonLeft } from '../../assets/images/404Ribon.svg';

import './style.less';
import AdsButton from '../home/Button/AdsButton';
import Spiner from '../../components/Spiner';

const NotFound = ({ notData = true }) => {
  //

  if (notData) {
    return <Spiner />;
  }

  return (
    <div className="not-found ">
      <div className="container content">
        <NotImg className="not-img" />
        <div className="message">
          <h1>OUPS ! Page introuvable</h1>
        </div>
        <AdsButton text="Retour à l’accueil" href="/" />
      </div>
      <RibonRight className="ribon right" />
      <RibonLeft className="ribon left" />
    </div>
  );
};

export default NotFound;
