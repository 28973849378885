import './resumecommande.less';

import secureShield from '../../../assets/icons/secure-shield.png';
import Card from './card';

import { Form } from 'antd';
import PromoField from './PromoField';

const ResumeCommande = ({
  cart,
  removeFromCart,
  order,
  canSetContent = true,
  onApplyPromo,
  promoCode,
}) => {
  //

  const removeItem = (id) => {
    removeFromCart && removeFromCart({ id });
  };

  const displyaShippingAmount = () => {
    if (order) {
      let { shippingCost } = order;

      if (!shippingCost || shippingCost === null) shippingCost = 0;

      return (
        <span className="command-recap__expe command-recap__span amount">
          {order.freeShipping || shippingCost === 0
            ? 'GRATUIT'
            : `${shippingCost} €`}
        </span>
      );
    }

    return (
      <Form.Item
        shouldUpdate
        className="command-recap__expe command-recap__span amount"
      >
        {({ getFieldValue }) => {
          const price = getFieldValue('shippingCost');
          const freeShipping = getFieldValue('freeShipping');

          const isFree = freeShipping || price === 0 || price === null;

          if (!isFree) return `+ ${price} €`;
          else return 'GRATUIT';
        }}
      </Form.Item>
    );
  };

  const displayTOtal = () => {
    if (order) {
      return (
        <span className="command-recap__tot command-recap__span amount">
          {`${order.amount.toFixed(2).replace('.', ',').replace(',00', '')} €`}
        </span>
      );
    }

    return (
      <Form.Item
        shouldUpdate
        className="command-recap__tot command-recap__span amount"
      >
        {({ getFieldValue, setFieldsValue }) => {
          const amount = getFieldValue('amount');

          return `${amount.toFixed(2).replace('.', ',').replace(',00', '')} €`;
        }}
      </Form.Item>
    );
  };

  const getSousTotal = () => {
    let totals = cart.total_sales;
    if (cart.fixedCardPromo) return cart.total_prices;
    return totals;
  };

  const displayFixdedPromoAmount = () => (
    <div className="command-recap__subtotal">
      Promotion
      <span className="command-recap__subtot command-recap__span">
        - {cart.discount.replace('.', ',').replace(',00', '')}€
      </span>
    </div>
  );

  return (
    <div id="resume-commande" className="resume-commande">
      <h3 className="section__title">Résumé de la commande</h3>
      <p className="section__subtitle">
        Le prix peut changer en fonction du mode d'expédition.
      </p>

      {cart && cart.content && (
        <>
          {cart.content.map((item,key) => {
            return (
              <Card
                key={key}
                item={item}
                removeItem={() => removeItem(item.id)}
                canSetContent={canSetContent}
              />
            );
          })}

          <div className="command-recap">
            <div className="command-recap__subtotal">
              Sous-total
              <span className="command-recap__subtot command-recap__span bold">
                {getSousTotal().toFixed(2).replace('.', ',').replace(',00', '')}
                €
              </span>
            </div>

            {cart.fixedCardPromo && displayFixdedPromoAmount()}

            <div className="command-recap__expedition line-amount">
              Frais d'expédition
              {displyaShippingAmount()}
            </div>

            <div className="command-recap__total line-amount">
              Total (Tva incluse)
              {displayTOtal()}
            </div>

            {canSetContent && (
              <PromoField onApply={onApplyPromo} promoCode={promoCode} />
            )}

            <Form.Item name="amount">
              <></>
            </Form.Item>

            <Form.Item name="shippingCost">
              <></>
            </Form.Item>

            <Form.Item name="freeShipping">
              <></>
            </Form.Item>
          </div>
        </>
      )}

      <div className="secure-data">
        <img
          src={secureShield}
          alt="secure-shield.png"
          className="securedata__logo"
        />

        <div className="section__title">
          Toutes vos données sont en sécurité
        </div>

        <div className="resume-commande__variable">
          Nous utilisons les systèmes de sécurité les plus avancés pour vous
          offrir la meilleure expérience possible.
        </div>
      </div>
    </div>
  );
};

export default ResumeCommande;
