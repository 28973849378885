import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './style.less';
import { Row, Col } from 'antd';
import { openModal } from '../../stores/app/actions';
import { Collapse } from 'antd';

import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import HTMLReactParser from 'html-react-parser';
import * as actions from '../../stores/vitrin/actions';
import { vitrinSelector } from '../../stores/vitrin/selector';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import SectionWrapper from '../../components/SectionWrapper';
import useHashScrooler from '../../hooks/useHashScrooler';
import { MODALPAGES } from '../../stores/app/reducer';

const { Panel } = Collapse;

const Conditions = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'conditions-generales',
    name: NAME,
  });

  useHashScrooler({ location, data });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openModal({ type: MODALPAGES.NONE }));
  }, []);

  const conditionComponent = (dat) => {
    return (
      <div id="conditions">
        <div className="container">
          <div className="content-condition">
            <Row gutter={40}>
              <Col xs={24} sm={10} md={10} lg={10}>
                {dat.sectionTitleAndDescriptionLeft.map((condition) => {
                  const { hashId, _id } = condition;
                  return (
                    <SectionWrapper hashId={hashId} key={_id}>
                      <h3 className="header-h1-text-normal title-condition">
                        {condition.title}
                      </h3>
                      {condition.background && (
                        <p className="subtitle-condition">
                          {condition.background}
                        </p>
                      )}
                      <p className="text-Condition">
                        {HTMLReactParser(`${condition.description || ""}`)}
                      </p>
                    </SectionWrapper>
                  );
                })}
              </Col>
              <Col xs={24} sm={14} md={14} lg={14}>
                {dat.sectionTitleAndDescriptionRight.map((condition) => {
                  const { hashId } = condition;
                  return (
                    <SectionWrapper hashId={hashId} key={condition._id}>
                      <h3 className="header-h1-text-normal title-condition">
                        {condition.title}
                      </h3>
                      {condition.background && (
                        <p className="subtitle-condition">
                          {condition.background}
                        </p>
                      )}
                      <p className="text-Condition">
                        {HTMLReactParser(`${condition.description}`) || ""}
                      </p>
                    </SectionWrapper>
                  );
                })}

                <Collapse
                  className="font-collapse-condition"
                  accordion
                  defaultActiveKey={['0']}
                  expandIconPosition={'right'}
                >
                  {dat.sectionAccordeon.map((acc) => {
                    const { hashId } = acc;

                    return (
                      <Panel header={acc.title} key={acc._id}>
                        <SectionWrapper hashId={hashId}>
                          <p>{HTMLReactParser(`${acc.description}`)}</p>
                        </SectionWrapper>
                      </Panel>
                    );
                  })}
                </Collapse>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  if (!data) return <Spiner />;

  return conditionComponent(data);
};

const NAME = 'conditions-generales';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Conditions);
