import { notification } from 'antd';

export const addCartNotification = (error) => {
  const cartArgs = {
    placement: 'bottomRight',
    message: (
      <p
        className="notif__title"
        style={{ fontSize: '40px', marginBottom: '15px' }}
      >
        Ajout au panier
      </p>
    ),
    className: 'notif',
    description: (
      <p
        className="notif__title"
        style={{ fontSize: '25px', marginBottom: '15px' }}
      >
        {error ? "L'article a bien été ajouté à votre panier" : error.message}
      </p>
    ),
  };
  notification.open(cartArgs);
};

export const addWishNotification = () => {
  const cartArgs = {
    placement: 'bottomRight',
    message: (
      <p
        className="notif__title"
        style={{ fontSize: '40px', marginBottom: '15px' }}
      >
        Ajout aux favoris
      </p>
    ),
    className: 'notif',
    description: (
      <p
        className="notif__title"
        style={{ fontSize: '25px', marginBottom: '15px' }}
      >
        L'article a bien été ajouté à vos favoris
      </p>
    ),
  };
  notification.open(cartArgs);
};

export const removeWishNotification = () => {
  const cartArgs = {
    placement: 'bottomRight',
    message: (
      <p
        className="notif__title"
        style={{ fontSize: '40px', marginBottom: '15px' }}
      >
        Suppression des favoris
      </p>
    ),
    className: 'notif',
    description: (
      <p
        className="notif__title"
        style={{ fontSize: '25px', marginBottom: '15px' }}
      >
        L'article a bien été enlevé de vos favoris
      </p>
    ),
  };
  notification.open(cartArgs);
};
