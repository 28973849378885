import { Row, Col } from 'antd';

import './board.less';
import iconDetails from '../../../assets/icons/details.png';

import { NavHashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';

import moment from 'moment';
import 'moment/locale/fr';
import Spiner from '../../../components/Spiner';
import { getFileUrl } from '../../../utils';
import { ORDER_DETAIL_SLUG } from '../../../Routes/links';
import { ordersSelector } from '../../../stores/order/selector';
import { getProductInfos } from '../../../components/Cart/utils';
moment.locale('fr');
var mois =
  'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
    '_'
  );

moment.updateLocale('fr', { months: mois });

const Board = ({ logOut, toggleNavbar, switchPassword }) => {
  const orders = useSelector(ordersSelector);
  console.log(orders)
  if (!orders) {
    return <Spiner />;
  }

  const getImage = (item) => {
    const { product, variation } = item;
    const { image } = getProductInfos(product, variation);
    return image;
  };

  return (
    <div id="board" className="board">
      <Row>
        <Col lg={12} sm={11} xs={21}>
          <h2 className="board__title">Tableau de bord</h2>
        </Col>

        <Col sm={0} xs={3} className="navbar-toggle">
          <button
            onClick={() => toggleNavbar()}
            className="navbar-toggle__button"
          >
            <img src={iconDetails} alt="details.png" />
          </button>
        </Col>

        <Col lg={12} sm={13} className="board__buttons">
          <button
            onClick={() => switchPassword()}
            className="board__button board__reset-password"
          >
            MODIFIER LE MOT DE PASSE
          </button>
          <button className="board__button board__logout" onClick={logOut}>
            DECONNEXION
          </button>
        </Col>
      </Row>

      <div className="board__recap">
        {orders && orders.length > 0 ? (
          orders
            .sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((item, index = item.ref) => {
              const itemStatus = item.status === null ? {} : item.status;

              return (
                <Row key={index} className="board__command">
                  <Col
                    lg={7}
                    md={8}
                    sm={9}
                    xs={24}
                    className={
                      item.status == null
                        ? 'command__details processing'
                        : 'command__details completed'
                    }
                  >
                    <ul className="command__details-list">
                      <li className="command__info status">
                        {itemStatus.title}
                      </li>

                      <li className="command__info status">
                        N° {item.numOrder}
                      </li>

                      <li className="command__info">
                        {moment(item.createdAt).format('DD MMMM YYYY')}
                      </li>

                      <li className="command__info price">{item.amount} €</li>

                      <li>
                        <NavHashLink
                          to={ORDER_DETAIL_SLUG + '/' + item.id + '#top'}
                          className="cancel__button cancel__button--responsive"
                        >
                          DETAILS
                        </NavHashLink>
                      </li>
                    </ul>
                  </Col>

                  <Col
                    lg={13}
                    md={10}
                    sm={10}
                    xs={24}
                    className="command__articles"
                  >
                    <Row gutter={[6, 10]} style={{ height: '100%' }}>
                      {item.cart.map((item, index) => {
                        return (
                          <Col key={index}  lg={6} md={8} sm={12} xs={12}>
                            {item?.product && item.product !== null && (
                              <NavHashLink
                                to={{
                                  pathname: `/produits/${item.product?.slug}`,
                                }}
                              >
                                <img
                                  src={getFileUrl(getImage(item), {
                                    xs: true,
                                    small: true,
                                  })}
                                  alt="article"
                                  className="command__images"
                                />
                              </NavHashLink>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>

                  <Col lg={4} md={6} sm={5} xs={0} className="command__cancel">
                    <NavHashLink
                      to={ORDER_DETAIL_SLUG + '/' + item.id + '#top'}
                      className="cancel__button"
                    >
                      DETAILS
                    </NavHashLink>
                  </Col>
                </Row>
              );
            })
        ) : (
          <div className="nodata">
            <p className="nodata__text">
              Aucune commande n'est disponible actuellement.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Board;
