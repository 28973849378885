import React from 'react';
import { useRef } from 'react';
import './style.less';
import { Row, Col } from 'antd';
import { MOBILE_SIZE } from '../../config';
import useWindowSize from '../../hooks/useWindowSize';
import CommitmentPromesseSection from './promesseSection';
import BannerSection from '../home/HomeSections/BannerSection';
import WheelIcon from '../../assets/images/wheelIcon.svg';
import LeftArrow from '../../assets/images/left-arrow.svg';
import RightArrow from '../../assets/images/right-arrow.svg';
import HeaderH1TextItalic from '../../components/common/HeaderH1TextItalic';
import HeaderH1TextNormal from '../../components/common/HeaderH1TextNormal';
import { vitrinSelector } from '../../stores/vitrin/selector';
import * as actions from '../../stores/vitrin/actions';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';

const Commitment = ({ location }) => {
  //

  const data = useVitrinFetcher2({ endpoints: 'nos-engagements', name: NAME });
  useHashScrooler({ location, data });

  const screen = useWindowSize({ width: window.innerWidth });

  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  const ref = useRef({});
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const displaySectionContent = () => {
    const { section_liste_des_neuf_methodes_de_poses = [] } = data;

    if (section_liste_des_neuf_methodes_de_poses === null) return;

    return section_liste_des_neuf_methodes_de_poses.map((m, index) => (
      <div key={index}>
        <div className="methode-section-content-title">
          <HeaderH1TextNormal
            text={index + 1 + '/'}
            isCenter={false}
            shouldBringUp={false}
          />
          <p
            className="methode-section-content-titleLeft"
            dangerouslySetInnerHTML={{ __html: m.title }}
          ></p>
        </div>

        <p
          className="methode-section-content-text"
          dangerouslySetInnerHTML={{ __html: m.description }}
        ></p>
      </div>
    ));
  };

  if (!data) return <Spiner />;

  return (
    <div
      id="commitment"
      className="container"
      style={isMobile ? { marginTtop: '50px' } : {}}
    >
      <BannerSection
        normalHeaderText={data.banniere.titre_normal}
        italicHeadertext={data.banniere.titre_italique}
        banner={[data.banniere]}
      />
      <div className="artisan-sublime-chiffre">
        <CommitmentPromesseSection
          section={data.section_trois_promesses_six_coffrets}
          section3promesses={data.section_trois_promesses}
          sectionListes={data.section_liste_des_promesses}
        />
        <div>
          <HeaderH1TextItalic
            text={data.section_six_coffrets?.title}
            isCenter={false}
            shouldBringUp={true}
          />
          <p
            className="subsection-text"
            dangerouslySetInnerHTML={{
              __html: data.section_six_coffrets?.description,
            }}
          ></p>
        </div>
        <div className="coffrets-section">
          {data.section_liste_des_coffrets?.map((t) => (
            <div className="coffrets-section-col">
              <p className="coffrets-section-col-title">{t.title}</p>
              <p
                className="coffrets-section-col-text"
                dangerouslySetInnerHTML={{ __html: t.description }}
              ></p>
            </div>
          ))}
        </div>
        <div className="methode-section">
          <HeaderH1TextItalic
            text={data.section_neuf_methodes_de_poses?.title}
            isCenter={false}
            shouldBringUp={true}
          />
          <p
            className="subsection-text"
            dangerouslySetInnerHTML={{
              __html: data.section_neuf_methodes_de_poses?.description,
            }}
          ></p>
        </div>
        <div className="methode-section-container">
          <img
            onClick={() => scroll(-300)}
            className="make-it-clickable"
            src={LeftArrow}
            alt="left-arrow"
          />
          <div className="methode-section-content" ref={ref}>
            {displaySectionContent()}
          </div>
          <img
            onClick={() => scroll(300)}
            className="make-it-clickable"
            src={RightArrow}
            alt="right-arrow"
          />
        </div>
        <Row className="advantage-services-garanti">
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            className="section-item-card yellow-bg-color"
          >
            <div className="header-section">
              <h3 className="header-section-text">
                {data.section_liste_des_cartes?.[0]?.title}
              </h3>
              <img
                className="header-wheel-img"
                src={WheelIcon}
                alt="wheel icon"
              />
            </div>
            <div className="content-section">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.section_liste_des_cartes?.[0]?.description,
                }}
              ></p>
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            className="section-item-card gray-1-bg-color"
          >
            <div className="header-section">
              <h3 className="header-section-text">
                {data.section_liste_des_cartes?.[1]?.title}
              </h3>
              <img
                className="header-wheel-img"
                src={WheelIcon}
                alt="wheel icon"
              />
            </div>
            <div className="content-section">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.section_liste_des_cartes?.[1]?.description,
                }}
              ></p>
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            className="section-item-card gray-2-bg-color positionColum"
          >
            <div className="header-section">
              <h3 className="header-section-text">
                {data.section_liste_des_cartes?.[2]?.title}
              </h3>
              <img
                className="header-wheel-img"
                src={WheelIcon}
                alt="wheel icon"
              />
            </div>
            <div className="content-section">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.section_liste_des_cartes?.[2]?.description,
                }}
              ></p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const NAME = 'engagement';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Commitment);
