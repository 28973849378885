import useSWR from "swr";
import { useState, useEffect } from "react";

function useFetch(url, shouldFetch = true, params = {}) {
  const [value, setValue] = useState();
  const { data, error } = useSWR(shouldFetch ? url : null, { ...params });

  useEffect(() => {
    if (!error && data && !data.error) {
      if (!isEqual(value, data)) setValue(data);
    }

    return () => {};
    // 
  }, [data]);

  return value;
}

export default useFetch;

const isEqual = (data, newData) => {
  return JSON.stringify(data) === JSON.stringify(newData);
};
