import http from '../../api/http';

export function fetchProductsAndSelection(params, breackpoint = '/products') {
  const { page, _q, _sort, _limit = 9, ...rest } = params;

  let value = {
    ...rest,
    _start: page * 9,
    _limit,
    _q: _q !== '' ? _q : undefined,
    _sort,
    // published: true,
  };

  value = JSON.parse(JSON.stringify(value));

  return http
    .get(breackpoint, {
      params: value,
    })
    .then((data) => {
      return data;
    });
}

export function fetchFilters() {
  return http.get('/filters').then((data) => {
    return data;
  });
}

export function fetchCatgories() {
  const data = http.get('/categories').then((data) => {
    return data;
  });

  return data;
}

export function fetchCatgory(slug) {
  try {
    const data = http.get('/categorybyslug/' + slug).then((data) => {
      return data;
    });

    return data;
  } catch (error) {}
}
