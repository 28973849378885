import './style.less';
import PageTitle from './PageTitle';

import Spiner from '../../components/Spiner';
import WelcomeSection from './WelcomeSection';
import Clickrdv from './clickrdv';

import { connect } from 'react-redux';
import { vitrinSelector } from '../../stores/vitrin/selector';
import { setVitrinData } from '../../stores/vitrin/actions';
import BannerSection from '../home/HomeSections/BannerSection';
import useEssentialScrooler from '../../hooks/useEssentialScrooler';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';

const Appointment = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: ENDPOINTS,
    name: NAME,
  });

  useEssentialScrooler({
    location,
    data,
    hashId: '#rdv',
  });

  useHashScrooler({ location, data, time: 3000 });

  const displayBanner = () => {
    if (!data) return <Spiner />;
    const { banniere } = data;
    return (
      <BannerSection
        normalHeaderText={banniere[0].titre_normal}
        italicHeadertext={banniere[0].titre_italique}
        banner={banniere}
      />
    );
  };

  const displayWelcom = () => {
    if (!data || !data.section_bienvenue) return <></>;

    const { secondColumn, title, firstColumn, hashId } = data.section_bienvenue;

    return (
      <WelcomeSection
        contentClass="rdv-container"
        title={title}
        secondColumn={secondColumn}
        firstColumn={firstColumn}
        hashId={hashId}
      />
    );
  };

  return (
    <div id="appointment" className="container appointment">
      {displayBanner()}

      { data && data.sectionRdvTitle && <PageTitle caption={data.sectionRdvTitle} />}

      <Clickrdv />
      {displayWelcom()}
    </div>
  );
};

const NAME = 'appointment';
const ENDPOINTS = 'appointment';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(setVitrinData(val)),
    };
  }
);

export default Wrapper(Appointment);
