import React from 'react';
import { notification } from 'antd';

import './style.less';
import Footer from './footer';
import Header from './header';
import NoCart from './noCart';
import { Fragment } from 'react';
import CartItemCard from './itemCard';
import { useHistory } from 'react-router-dom';
import { isValidValue } from '../../utils';

export default function Content({ data, onClose, removeFromCart }) {
  
  const history = useHistory();

  const removeArgs = {
    message: (
      <p className="notif__title" style={{ fontSize: '40px' }}>
        Notification
      </p>
    ),
    placement: 'bottomLeft',
    className: 'notif',
    description: (
      <p
        className="notif__title"
        style={{ fontSize: '25px', marginBottom: '15px' }}
      >
        L'article a bien été enlevé de votre panier
      </p>
    ),
  };

  const removeFromCartNotification = () => {
    notification.open(removeArgs);
  };

  const handleDelete = async (data) => {
    removeFromCart && (await removeFromCart(data));
    removeFromCartNotification();
  };

  const onClickCart = (sluggy) => {
    history.push(`/produits/${sluggy}`);
    onClose();
  };

  const displayProductItem = (item) => {
    return (
      <CartItemCard
        data={item}
        onClickCart={onClickCart}
        handleDelete={handleDelete}
      />
    );
  };

  const displayContent = () => {
    if (!isValidValue(data) || data.content.length === 0)
      return <NoCart onClose={onClose} />;

    const { content, total_prices, total_sales = total_prices } = data;

    return (
      <>
        {content && content.length > 0 && (
          <div className="cart__content">
            {content.map((item, index) => (
              <Fragment key={'m' + index}>
                {displayProductItem(item, index)}
              </Fragment>
            ))}
          </div>
        )}

        <Footer
          onClose={onClose}
          totalPrices={total_sales === null ? '' : total_sales}
        />
      </>
    );
  };

  return (
    <div id="cart" className="cart" onMouseLeave={onClose}>
      <Header onClose={onClose} />
      {displayContent()}
    </div>
  );
}
