import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import { vitrinSelector } from '../../stores/vitrin/selector';
import * as actions from '../../stores/vitrin/actions';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import NousJoindreSection from '../home/HomeSections/NousJoindreSection';
import ProductCard from '../../components/shop/ProductCard';
import HighlightingCollection from './HighlightingCollection';
import useWindowSize from '../../hooks/useWindowSize';
import { MOBILE_SIZE } from '../../config';
import HighlightingCollectionMobile from './HighlightingCollectionMobile';
import ProductsSection from '../home/HomeSections/ProductsSection';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import SectionWrapper from '../../components/SectionWrapper';

const OurCollection = ({ featureadProducts }) => {
  //

  const data = useVitrinFetcher2({ endpoints: 'collections', name: NAME });

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  const [highlightingProducts, setHighlightingProducts] = React.useState([]);

  React.useEffect(() => {
    if (data) initProducts();
    // 
  }, [data]);

  const initProducts = () => {
    let higtlight = data.highlightingProducts.products;

    if (!higtlight || higtlight === null) higtlight = featureadProducts;
    else if (higtlight.length < 5)
      higtlight = higtlight.concat(featureadProducts);

    setHighlightingProducts(higtlight);
  };

  const patchPriseEnCharge = (priseEnCharge) => {
    const val = {
      titre_normal: priseEnCharge.title,
      mobile_media: priseEnCharge.image,
      description: priseEnCharge.description,
      titre_bouton: priseEnCharge.button_text,
      lien_bouton: '',
      titre_italique: priseEnCharge.label,
      hashId: priseEnCharge.hashId,
    };

    return val;
  };

  const displayFeaturedProducts = () => {
    let featured = featureadProducts || [];

    const { section_plus_nouveaux_produits = {} } = data;
    const { hashId } = section_plus_nouveaux_produits;

    if (isMobile)
      return (
        <ProductsSection
          hashId={hashId}
          showSectionTitle={false}
          data={featured}
        />
      );

    return (
      <SectionWrapper hashId={hashId}>
        <Row>
          {featured.map((product, index = product._id) => {
            return (
              <Col key={index} lg={8} md={12} sm={24} xs={24}>
                <ProductCard data={product} />
              </Col>
            );
          })}
        </Row>
      </SectionWrapper>
    );
  };

  const displayHightlight = () =>
    isMobile ? (
      <HighlightingCollectionMobile
        rootData={data}
        items={highlightingProducts}
      />
    ) : (
      <HighlightingCollection rootData={data} items={highlightingProducts} />
    );

  if (!data) return <Spiner />;

  return (
    <div id="collection">
      <div className="container">
        {highlightingProducts && displayHightlight()}

        <NousJoindreSection section={patchPriseEnCharge(data.priseEnCharge)} />

        {featureadProducts && (
          <div className="new-product-section">
            <h2 className="titleNew">PLUS DE NOUVEAUX PRODUITS</h2>

            {displayFeaturedProducts()}
          </div>
        )}
      </div>
    </div>
  );
};

const NAME = 'our-collection';
const FEAT_NAME = 'featuredProducts';

const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
    featureadProducts: vitrinSelector(state, FEAT_NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(OurCollection);
