import React, { useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';

import { forgotpassword } from '../../../api/profile';

// POST Request to /auth/forgot-password
// Receive an E-Mail with a code
// Post Request to /auth/change-password with the new password and the code

const ChangePassword = (props) => {
  const [isError, setIsError] = useState(false);
  const [success, setSuccess] = React.useState(false);

  const onFinish = async (values) => {
    setIsError(false);

    try {
      await forgotpassword({
        email: values.email,
      });

      setSuccess(true);
    } catch (err) {
      // console.log({ err });
      console.log(err);
      setIsError(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onRetry = () => {
    setSuccess(false);
  };

  return (
    <div id="changePassword" className="changePassword">
      <h2 className="details__title">Réinitialiser votre mot de passe</h2>

      <Form
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          {!success && (
            <Col lg={8} md={12} sm={24} xs={24} className="">
              <Form.Item
                label="Veuillez entrer votre adresse Email"
                name="email"
                className="form__abel"
                rules={[
                  {
                    required: true,
                    message: 'Veuillez renseigner ce champ !',
                  },
                ]}
              >
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </Col>
          )}

          {isError && (
            <Col xs={24}>
              <span className="error_reset_pwd-message">
                Une erreur es survenue
              </span>
            </Col>
          )}

          {success && (
            <Col xs={24}>
              <span className="successed_reset_pwd-message">
                Un e-mail contenant la procédure à suivre pour modifier votre
                mot de passe vient de vous être envoyé.
              </span>

              <Button type="link" onClick={onRetry} className="retry-button">
                Réessayer
              </Button>
            </Col>
          )}
        </Row>

        {!success && (
          <div className="switch-password__submit">
            <button disabled={success} className="details__button">
              APPLIQUER
            </button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ChangePassword;
