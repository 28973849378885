import * as types from './types';

export const MODALPAGES = Object.freeze({
  SIGINUP: 'SIGNUP',
  SIGNIN: 'login',
  NONE: 'none',
});

const INITIAL_STATE = {
  typeModal: MODALPAGES.NONE,
};

const appReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        typeModal: payload.typeModal,
        nextUrl: payload.nextUrl,
      };

    case types.SET_AUTHED:
      return {
        ...state,
        user: payload.user,
        isAuth: payload.isAuth,
        token: payload.token,
      };

    default:
      return {
        ...state,
      };
  }
};

export default appReducer;

export const key = 'app';
