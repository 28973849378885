import { Select } from 'antd';

const QuantitySelect = ({ stock, value = 1, onChange }) => {
  return (
    <div className="quantity-select">
      <span> QUANTITÉ: </span>
      <Select
        defaultValue={1}
        placeholder={`QUANTITÉ: ${value}`}
        bordered={false}
        onChange={onChange}
      >
        {[...Array(stock)].map((e, i) => (
          <Select.Option value={i + 1} key={i}>
            {i + 1}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default QuantitySelect;
