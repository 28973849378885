import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import CountrySelector from '../../../components/CountryField';

const BillingAddress = ({ user, onSubmitUserChange }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);

    onSubmitUserChange &&
      onSubmitUserChange(
        {
          billingAddress: values,
        },
        callback
      );
  };

  const callback = (result) => {
    if (result.error) {
      return openNotification("Une erreur s'est produite !");
    }

    openNotification('Modificattion effectuée avec succès !');

    setIsLoading(false);
  };

  const getInitialValue = () => {
    if (!user.billingAddress) return {};

    return {
      remember: true,
      ...user.billingAddress,
    };
  };

  return (
    <div id="details__billing" className="details__billing">
      <h2 className="details__title">Informations de facturation</h2>

      {user && (
        <Form
          layout="vertical"
          initialValues={getInitialValue()}
          onFinish={onFinish}
        >
          <Row gutter={[{ sm: 30 }, {}]}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label="Nom"
                name="lastName"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label="Prénom"
                name="firstName"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label="Téléphone"
                name="phone"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label="Email"
                name="email"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={16} md={24} sm={24} xs={24}>
              <Form.Item
                label="Adresse"
                name="address"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label="Code postal"
                name="postalCode"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label="Ville"
                name="city"
                hasFeedback
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} className="">
              <Form.Item
                className="infos-form-item country-selector"
                label="Pays"
                name="country"
                rules={[
                  { required: true, message: 'Veuillez renseigner ce champ !' },
                ]}
              >
                <CountrySelector className="country" />
              </Form.Item>
            </Col>
          </Row>
          <div className="switch-password__submit">
            <Button
              htmlType="submit"
              className="details__button"
              type="text"
              loading={isLoading}
            >
              APPLIQUER LES MODIFICATIONS
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default BillingAddress;

const openNotification = (text) => {
  const args = {
    message: '',
    description: text,
    duration: 5,
  };
  notification.open(args);
};
