export const HOME_LINK = '/';
export const PRODUCTS_LINK = '/produits';
export const CATEGORIES_DETAILS_LINK = '/categories/:slug';
export const CATEGORIES_SLUG = '/categories';
export const CATEGORIES_LINK = '/categories';
export const FAVORI_LINK = '/favoris';
export const NEW_PASSWORD_LINK = '/reset-password';
export const PRODUCT_DETAIL_LINK = '/produits/:slug';
export const INSTI_LINK = '/institut';
export const NOS_CREATION_LINK = '/nos-creations';
export const MANUFACTURING_LINK = '/nos-faqs';
export const CONDITION_LINK = '/mentions-legales';
export const CONFIANCE_LINK = '/elles-nous-font-confiance';
export const PRISE_EN_CHARGE_LINK = '/prise-en-charge-médicale';
export const CONTACT_LINK = '/contact';
export const BLOG_LINK = '/blogs';
export const Our_COLLECTION_LINK = '/our-collection';
export const ORDER_DETAIL_LINK = '/detailscommande/:id';
export const ORDER_DETAIL_SLUG = 'detailscommande';
export const ORDER_CONFIRM_LINK = '/confirmationcommande/:id';
export const ORDER_CONFIRM_SLUG = '/confirmationcommande';
export const BLOG_DETAILS_LINK = '/blogs/:id';

export const CHECKOUT_LINK = '/checkout';
export const CHECKOUT_SLUG = 'checkout';

export const SOCIAL_CONNECT_LINK = '/connect/:provider/redirect';

export const APPOINTMENT_LINK = 'https://www.planity.com/lartisan-du-sublime-75012-paris';
export const USER_PROFILE_LINK = '/user-profile';
export const APPOINTMENT_AVAILABILITY_LINK = '/prendrerdv/:id';

export const HISTOIRE_SLUG = '/notre-histoire';
export const ENGAGEMENT_SLUG = '/nos-engagements';
export const CREATRICE_SLUG = '/  ';
export const EQUIPE_SLUG = '/equipes';

export const PRESTATION_SLUG = '/prestations';
export const NEW_COLLECTION_SLUG = '/nouvelles-collections';
export const INNOVATION_SLUG = '/inovations';
export const FIDELITE_SLUG = '/fidelites';
export const TARIFS_FABRICATION_SLUG = '/tarifs';
export const CART_LINK = '/panier';

export const CONFIANCE_CREATION_SLUG = '/creations';
export const PAYMENT_LINK = '/paiements';

export const EVENT_DETAILS_LINK = CONFIANCE_LINK + '/:slug';

export const PRESTATION_LINK = NOS_CREATION_LINK + PRESTATION_SLUG;
export const NEW_COLLECTION_LINK = NOS_CREATION_LINK + NEW_COLLECTION_SLUG;
export const INNOVATION_LINK = NOS_CREATION_LINK + INNOVATION_SLUG;
export const FIDELITE_LINK = NOS_CREATION_LINK + FIDELITE_SLUG;
export const TARIFS_FABRICATION_LINK =
  NOS_CREATION_LINK + TARIFS_FABRICATION_SLUG;

export const EVENEMENT_LINK = CONFIANCE_LINK + PRESTATION_SLUG;
export const CONFIANCE_CREATION_LINK = CONFIANCE_LINK + CONFIANCE_CREATION_SLUG;

export const HISTOIRE_LINK = INSTI_LINK + HISTOIRE_SLUG;
export const ENGAGEMENT_LINK = INSTI_LINK + ENGAGEMENT_SLUG;
export const CREATRICE_LINK = INSTI_LINK + CREATRICE_SLUG;
export const EQUIPE_LINK = INSTI_LINK + EQUIPE_SLUG;
