import React from 'react';
import {
  CATEGORIES_SLUG,
  CHECKOUT_LINK,
  CHECKOUT_SLUG,
  PAYMENT_LINK,
} from '../../Routes/links';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import CategoryBreadcrumb from '../eshop/shop/Breadcrumb';

const CheckoutBreadcrumb = ({ location }) => {
  const [deepItems, setDeepItems] = React.useState();

  const data = useVitrinFetcher2({
    endpoints: 'page-categories',
    name: NAME,
  });

  React.useEffect(() => {
    buildDeepsFromLink((data || {}).viewAllText);
    
  }, [data]);

  const { pathname } = location;
  const isPayment = pathname === PAYMENT_LINK;

  const buildDeepsFromLink = (viewAllText) => {
    const breadcrumb = [];

    if (viewAllText)
      breadcrumb.push({
        title: viewAllText,
        slug: VIEW_ALL_SLUG,
        url:
          `${CATEGORIES_SLUG}/` +
          process.env.REACT_APP_CATEGORY_ALL_PRODUCT_SLUG,
      });

    if (pathname === PAYMENT_LINK)
      breadcrumb.push({
        title: 'Validation',
        slug: CHECKOUT_SLUG,
        url: CHECKOUT_LINK,
      });

    setDeepItems({ breadcrumb });
  };

  const getLink = (slug) => {
    const { breadcrumb = [] } = deepItems;
    const item = breadcrumb.find((item) => item.slug === slug);

    if (item) return item.url;

    return CHECKOUT_LINK;
  };

  return (
    <CategoryBreadcrumb
      getLink={getLink}
      deepItems={deepItems}
      pageName={isPayment ? 'Paiement' : 'Validation'}
    />
  );
};

export default CheckoutBreadcrumb;
const NAME = 'page-categories';
const VIEW_ALL_SLUG = 'VIEW_ALL_SLUG';
