import Img from 'react-cool-img';

import './style.less';
import HeaderH1TextItalic from '../../HeaderText/HeaderH1TextItalic';
import HeaderH1TextNormal from '../../HeaderText/HeaderH1TextNormal';
import { MOBILE_SIZE } from '../../../../config';
import useWindowSize from '../../../../hooks/useWindowSize';
import Slider from 'react-slick';
import ItemsNavigationOnImage from '../../ItemsNavigation/ItemsNavigationOnImage';

import { NavHashLink } from 'react-router-hash-link';
import { getFileUrl, getNavHashTo } from '../../../../utils';

const BannerSection = ({ normalHeaderText, italicHeadertext, banner = [] }) => {
  //

  let slider;
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const moveNext = () => {
    slider.slickNext();
  };

  const movePrev = () => {
    slider.slickPrev();
  };

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;
  const isMultiplesImages = Array.isArray(banner) && banner.length > 1;

  const getImage = (item) => {
    if (!item._id) return <> </>;

    return (
      <NavHashLink {...getNavHashTo(window, item.link)}>
        <Img
          className={
            isMobile
              ? 'banner-img-std-m banner-img-home-mobile-m'
              : 'banner-img-std-m banner-img-home-desktop-m'
          }
          src={isMobile ? getBannerMobileImg(item) : getBannerDesktopImg(item)}
          alt="Prothese carpillaire sur mesure"
        />
      </NavHashLink>
    );
  };

  const getBannerDesktopImg = (item) =>
    getFileUrl(item.desktop_media, { lg: true });

  const getBannerMobileImg = (item) =>
    getFileUrl(item.mobile_media, { small: true });

  const displayBannerImages = () => {
    if (isMultiplesImages) {
      return (
        <div id="banner-section">
          <div className="slider-wrapper">
            <Slider ref={(c) => (slider = c)} {...settings}>
              {banner.map((item, index) => (
                <div key={index} className="banner-img-wrapper-m">
                  {getImage(item)}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      );
    }

    return (
      <div className="slider-wrapper">
        <div className="banner-img-wrapper-m">{getImage(banner[0])}</div>
      </div>
    );
  };

  if (!banner || banner === null || banner.length === 0) return <> </>;

  return (
    <div>
      <div className="header-text-section-m">
        <HeaderH1TextNormal
          text={normalHeaderText}
          isCenter={true}
          shouldBringUp={true}
        />

        <HeaderH1TextItalic
          text={italicHeadertext}
          isCenter={true}
          shouldBringUp={true}
          isRedColor={true}
        />
      </div>

      <div className="banner-img-home-m">
        {displayBannerImages()}

        {isMultiplesImages && (
          <>
            <div className="navigation-arrow-wrapper-m">
              <ItemsNavigationOnImage
                handleLeftArrow={movePrev}
                handleRightArrow={moveNext}
              />
            </div>
            <div onClick={movePrev} className="easyLeftNavigation-m"></div>
            <div onClick={moveNext} className="easyRightNavigation-m"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default BannerSection;
