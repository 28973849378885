import './style.less';
import { NavHashLink } from 'react-router-hash-link';
import { Row, Col } from 'antd';
import { getFileUrl } from '../../utils';
import HTMLReactParser from 'html-react-parser';
import Spiner from '../../components/Spiner';
import ArticleBlogCard from '../home/ArticleBlogCard/ArticleBlogCard';

const Content = ({ datas, onSearch, emptyMessage = 'Pas de blogs' }) => {
  if (!datas || datas === null) return <Spiner />;

  if (datas.length === 0) return <p className="empty-list">{emptyMessage} </p>;

  return (
    <>
      <Row className="BlogImage" gutter={16}>
        {datas.map((item, index) => (
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            key={index}
            className="space-content"
          >
            <NavHashLink to={`/blogs/${item.slug}#top`}>
              <ArticleBlogCard
                headerText={`${item.post[0].normal_title} ${item.post[0].italic_title}`}
                image={getFileUrl(item.post[0].mobile_media)}
                footerText={HTMLReactParser(`${item.post[0].small_descrition}`)}
              />
            </NavHashLink>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Content;
