import React from 'react';
import { Row, Col, Input, Form } from 'antd';

import './informations.less';

import PhoneField from '../../contact/PhoneField';
import CountrySelector from '../../../components/CountryField';

const Informations = ({
  responsivity,
  title,
  subtitle,
  rules = {},
  collections = ['billing'],
}) => {
  //

  return (
    <div
      id="informations"
      className={`${
        responsivity === 1 ? 'info' : 'info--display-none'
      } informations`}
    >
      {title && <h2 className="section__title">{title}</h2>}

      {subtitle && <p className="section__subtitle">{subtitle}</p>}

      <Row gutter={70}>
        <Col lg={12} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item"
            label="Prénom"
            name={[...collections, 'firstName']}
            rules={rules.firstName}
          >
            <Input
              className="infos-form-input"
              id="firstName"
              placeholder="Prénom"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item"
            label="Nom"
            name={[...collections, 'lastName']}
            rules={rules.firstName}
          >
            <Input
              className="infos-form-input"
              id="lastName"
              placeholder="Nom"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item"
            label="Adresse e-mail"
            name={[...collections, 'email']}
            rules={rules.email}
          >
            <Input
              className="infos-form-input"
              id="email"
              placeholder="Adresse e-mail"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item"
            label="Numéro de téléphone"
            name={[...collections, 'phone']}
            rules={rules.phoneNumber}
          >
            <PhoneField
              className="infos-form-input"
              placeholder="Numéro de téléphone"
            />
          </Form.Item>
        </Col>

        <Col lg={24} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item"
            label="Adresse"
            name={[...collections, 'address']}
            rules={rules.address}
          >
            <Input className="infos-form-input" placeholder="Adresse" />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item"
            label="Ville"
            name={[...collections, 'city']}
            rules={rules.city}
          >
            <Input
              className="infos-form-input"
              id="ville"
              placeholder="Ville"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item country-selector"
            label="Pays"
            name={[...collections, 'country']}
            rules={rules.country}
          >
            <CountrySelector className="infos-form-input" />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} className="infos-form-field">
          <Form.Item
            className="infos-form-item"
            label="Code Postal"
            name={[...collections, 'postalCode']}
            rules={rules.postalCode}
          >
            <Input
              className="infos-form-input"
              id="zip"
              placeholder="Code Postal"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Informations;
