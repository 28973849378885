import React from "react";
import "./headerTextNormal.less";

const HeaderH1TextItalic = ({ text, isCenter, shouldBringUp, customClass }) => {
  const classesToApply = () => {
    let classesList = "header-h1-text-italic";
    if (isCenter) {
      classesList = classesList + " center-text";
    }
    if (shouldBringUp) {
      classesList = classesList + " bring-upfront";
    }
    if (customClass) classesList += ` ${customClass}`
    return classesList;
  };
  return (
    <>
      <h1 className={classesToApply()}>{text}</h1>
    </>
  );
};

export default HeaderH1TextItalic;
