import React from 'react';
import './style.less';
import { getAvailableCalendar, getInterventionsDetails } from '../api.js';
import { buildCalendar } from './utils';
import Calendar from '../components/Calendar';
import WelcomeSection from '../WelcomeSection';
import PageTitle from '../PageTitle';
import Spiner from '../../../components/Spiner';

import AppointmentItem from '../Item';
import { Row, Col } from 'antd';

const Availability = ({ match }) => {
  const { id } = match.params;

  const [state, setState] = React.useState({
    interventions: undefined,
    interventionSets: undefined,
    loading: false,
  });

  const { calendarData, intervention } = state;

  React.useEffect(() => {
    setDatas();
    
  }, []);

  const setDatas = async () => {
    setState((state) => ({ ...state, loading: true }));

    const availabilities = await getAvailableCalendar(id, daysCount);
    const intervention = await getInterventionsDetails(id);

    
    if (!availabilities || availabilities === null) return;

    const calendarData = await buildCalendar(availabilities, daysCount);

    setState((state) => ({
      ...state,
      availabilities,
      loading: false,
      calendarData,
      intervention,
    }));
  };

  const displayDescription = () => (
    <Row className="rdv-container">
      <Col flex="1">
        <AppointmentItem item={intervention} />
      </Col>
      <Col flex="none">
        <div className="appointment-offers__price">
          {intervention.price === null ? 0 : intervention.price || 0} €
        </div>
      </Col>
    </Row>
  );

  return (
    <div id="availabilitie" className="container appointment">
      <PageTitle />

      {intervention && displayDescription()}

      {calendarData ? (
        <>
          <div className="rdv-container calendar-title">
            Choix de la date & heure
          </div>

          <Calendar datas={calendarData} className="rdv-container" />
        </>
      ) : (
        <Spiner />
      )}

      <WelcomeSection contentClass="rdv-container" />
    </div>
  );
};

export default Availability;

const daysCount = 100;
