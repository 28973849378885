import { SET_FILTERS, SET_PRODUCTS, SET_CATEGORIES } from './types';

const initialState = [];

function shopReducer(shop = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTERS:
      return { ...shop, filters: payload };

    case SET_CATEGORIES:
      return { ...shop, categories: payload };

    case SET_PRODUCTS:
      return { ...shop, catalog: payload };

    default:
      return shop;
  }
}

export default shopReducer;
export const key = 'shop';
