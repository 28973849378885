import './itemsNavigation.less';

import LeftArrow from '../../../assets/images/left-arrow.svg';
import RightArrow from '../../../assets/images/right-arrow.svg';
import AdsButton from '../Button/AdsButton';

const ItemsNavigation = ({
  href,
  handleLeftArrow,
  handleRightArrow,
  showMore = true,
  textButton = "TOUT VOIR"
}) => {
  return (
    <>
      <div className="produit-navigation-wrapper">
        <img
          onClick={handleLeftArrow}
          className="make-it-clickable"
          src={LeftArrow}
          alt=''
        />
        
        {showMore && <AdsButton text={textButton} href={href} />}
        <img
          onClick={handleRightArrow}
          className="make-it-clickable"
          src={RightArrow}
          alt=''
        />
      </div>
    </>
  );
};

export default ItemsNavigation;
