import { getUsers } from '../../api/utils';
import { performDatalayer } from '../tagLayerUtils';
import {
  addItemApi,
  addTemporaryItem,
  buildTempCartApi,
  mergeCartApi,
  removeItemApi,
  removeTemporaryItemApi,
  setItemQteApi,
  setTemporaryQtItem,
} from './api';
import * as types from './cartTypes';

export const setCartContent = (payload) => async (dispatch) => {
  dispatch({
    type: types.SET_CART_CONTENT,
    payload,
  });
};

export const setOpenCart = (open) => async (dispatch) => {
  dispatch({
    type: types.SET_OPEN_CART,
    payload: open,
  });
};

export const setItemQte = (payload) => async (dispatch) => {
  const { id, qte = 1, variation } = payload;

  const user = getUsers();

  let data;

  if (user) {
    data = await setItemQteApi({
      id,
      qte,
      ...getVarFilter(variation),
    });
  } else {
    const dataTemp = await setTemporaryQtItem({
      product: id,
      qte,
      ...getVarFilter(variation),
    });
    data = await buildTempCartApi(dataTemp);
  }

  if ((data && !data.error) || (data && data.content)) {
    dispatch({
      type: types.SET_CART_CONTENT,
      payload: data,
    });
  }

  return data;
};

export const addItemToCart = (payload) => async (dispatch) => {
  const { id, qte = 1, variation, window } = payload;

  const user = getUsers();

  let data;

  if (user) {
    data = await addItemApi({ id, qte, ...getVarFilter(variation) });
  } else {
    const dataTemp = await addTemporaryItem({
      product: id,
      qte,
      variation,
      ...getVarFilter(variation),
    });
    data = await buildTempCartApi(dataTemp);
  }

  if ((data && !data.error) || (data && data.content)) {
    dispatch({
      type: types.SET_CART_CONTENT,
      payload: data,
    });

    performDatalayer(window, data, { id, qte }, 'cart-add');
  }

  return data;
};

export const mergeCart = () => async (dispatch) => {
  let tempData = await getTempCart();

  const data = await mergeCartApi(tempData);

  if (data) {
    localStorage.removeItem('tempCart');

    dispatch({
      type: types.SET_CART,
      payload: data,
    });
  }
};

const getTempCart = async () => {
  const tempo = localStorage.getItem('tempCart');
  if (!tempo || tempo === null) return;
  return JSON.parse(tempo);
};

export const removeItemFromCart =
  ({ id, variation_slug, product_id }) =>
  async (dispatch) => {
    const user = getUsers();

    let data;
    if (user) {
      data = await removeItemApi(id);
    } else {
      const dataTemp = await removeTemporaryItemApi(product_id, variation_slug);
      data = await buildTempCartApi(dataTemp);
    }

    if (data) {
      dispatch({
        type: types.SET_CART_CONTENT,
        payload: data,
      });

      return true;
    }
    return false;
  };

export const clearCart = () => async (dispatch) => {
  dispatch({
    type: types.CLEAR_CART,
  });
};

const getVarFilter = (variation) =>
  variation ? { variation_slug: variation.slug } : {};
