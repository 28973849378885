import React from 'react';

import { Avatar } from 'antd';
import { useHistory } from 'react-router-dom';
import * as links from '../../Routes/links';
import { NavHashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthedAction } from '../../stores/app/actions';

import './dropdown.less';
import { setCartContent } from '../../stores/cart/cartActions';
import { setWishlistContent } from '../../stores/wishList/actions';

import { userDataSelector } from '../../stores/auth/selector';
import { logoutAction } from '../../stores/auth/actions';

const UserProfileMenu = () => {
  let history = useHistory();

  const dispatch = useDispatch();

  const user = useSelector(userDataSelector);

  const logOut = (e) => {
    dispatch(logoutAction());

    dispatch(setAuthedAction({}));
    dispatch(setCartContent());
    dispatch(setWishlistContent());

    history.push('/');
  };

  const getAvatarName = () => {
    if (!user || user === null || user==="undefined") return '';
    return user.username.substr(0, 1).toUpperCase();
  };

  return (
    <div className="user_profil">
      <ul>
        <li>
          <Avatar
            className="dropbtn"
            style={{
              marginTop: '-9px',
              backgroundColor: 'gray',
              verticalAlign: 'middle',
            }}
            size="default"
          >
            {getAvatarName()}
          </Avatar>
          <ul className="dropdown">
            <li>
              <HashLink link={`${links.USER_PROFILE_LINK}`} title="Profil" />
            </li>
            <li>
              <HashLink
                link={`${links.APPOINTMENT_LINK}`}
                title="Mes Rendez-vous"
              />
            </li>

            <li>
              <HashLink
                link={`${links.USER_PROFILE_LINK}`}
                title="Mes Commandes"
              />
            </li>
            <li>
              <a href="/" onClick={logOut}>
                Se Déconnecter
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default UserProfileMenu;

const HashLink = ({ link, title }) => (
  <NavHashLink to={`${link}#top`}> {title} </NavHashLink>
);
