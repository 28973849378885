import React from 'react';

import InnerHTML from 'dangerously-set-html-content';
import getAlmaquery from './amlaQuery';

const AlmaPaymentField = ({ order, return_url }) => {
  const { amount, billing } = order;

  const { firstName, lastName, email, phone } = billing;

  function createMarkup() {
    return {
      __html: `
      
      <div style="width: 100%">
        <div id="div-alma1" onclick="pay(1, 30)"></div>
        <div id="div-alma2"  onclick="pay(2)"></div>
        <div id="div-alma3" onclick="pay(3)"></div>
        <div id="div-alma4" onclick="pay(4)"></div>
      </div>`,
    };
  }

  // const shipping_address = ` {
  //   line1: '${address.address}',
  //   postal_code: '${address.postalCode}',
  //   city: '${address.city}',
  //   country: '${address.country}',
  // }`;

  const shipping_address = ` {
    line1: "Pont de l'Alma",
    postal_code: '75008',
    city: 'Paris',
    country: 'FR',
  }`;

  const customer = `{
    first_name: '${firstName}', 
    last_name: '${lastName}',
    email: '${email}',
    phone: '${phone}',
  },`;

  return (
    <>
      <InnerHTML
        html={getAlmaquery({
          amount: amount * 100,
          shipping_address,
          return_url,
          customer,
        })}
      />

      <div dangerouslySetInnerHTML={createMarkup()} />
    </>
  );
};

const isEqual = (prev, next) => {
  return (
    JSON.stringify((prev || {}).order) === JSON.stringify((next || {}).order)
  );
};

export default React.memo(AlmaPaymentField, isEqual);
