import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Icon } from '../assets/icons/heart.svg';
import styled from 'styled-components';

import { wishlistCountSelector } from '../stores/wishList/selector';

const WishIcon = ({ className, onClick }) => {
  const count = useSelector(wishlistCountSelector);

  const validCount = count !== undefined && count > 0;

  const displayBadge = () => {
    return <Badge className="cart-badge"> {count} </Badge>;
  };

  return (
    <>
      <IconWrapper onClick={onClick} className={`cartIcon ${className}`}>
        <Icon style={{ cursor: 'pointer' }} />
        {validCount && displayBadge()}
      </IconWrapper>
    </>
  );
};

const Badge = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  background: #fff;
  top: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9px;
  left: 9px;

  font-family: GT Eesti Display Trial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  position: relative;
  display: flex;
  cursor: pointer;
`;

export default WishIcon;
