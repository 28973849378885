import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import useVitrinFetcher2 from '../stores/vitrin/hooks2';
import { buildMenuConfig } from './config';
import { componentsMapped } from './data';
import NotFound from '../pages/404';
import { isValidValue } from '../utils';
import CartRoute from './CartRoute';
import { CART_LINK } from './links';

const Routes = () => {
  //

  const { menus: menuData } =
    useVitrinFetcher2({
      endpoints: 'menu-config',
      name: 'menuData',
    }) || {};

  const { outMenuLinks, submenus, menus, paramsLinks, privatesRoutes } =
    buildMenuConfig(menuData);
  return (
    <>
      <Switch>
        {menus.map((item) => (
          <Route
            path={item.link}
            exact={item.exact}
            component={componentsMapped[item.componentKey]}
            key={item.id}
          />
        ))}

        {submenus.map((item, index) => (
          <Route
            path={item.link}
            exact={item.exact}
            component={componentsMapped[item.componentKey]}
            key={index}
          />
        ))}

        {paramsLinks.map((item, index) => (
          <Route
            key={index}
            path={item.link}
            component={componentsMapped[item.componentKey]}
          />
        ))}

        {outMenuLinks.map((item, index) => (
          <Route
            key={index}
            path={item.link}
            exact={item.exact}
            component={componentsMapped[item.componentKey]}
          />
        ))}

        {privatesRoutes.map((item, index) => (
          <ProtectedRoute
            key={index}
            path={item.link}
            exact={item.exact}
            component={componentsMapped[item.componentKey]}
          />
        ))}

        <CartRoute path={CART_LINK} exact={true} />

        <Route path="*">
          <NotFound notData={!isValidValue(menuData)} />
        </Route>
      </Switch>
    </>
  );
};

export default React.memo(Routes);
