import './style.less';

import Img from 'react-cool-img';
import AdsButton from '../../Button/AdsButton';
import { getFileUrl } from '../../../../utils';
import HTMLReactParser from 'html-react-parser';
import SectionWrapper from '../../../../components/SectionWrapper';

const NousJoindreSection = ({ section }) => {
  const { hashId } = section || {};

  return section && (
    <SectionWrapper hashId={hashId} className="nous-joindre">
      <p className="nous-joindre-text">{section.titre_normal}</p>
      <Img
        className="sublime-img-on-mobile"
        src={getFileUrl(section.mobile_media)}
      />
      <p className="nous-joindre-contact">
        {HTMLReactParser(`${section.description}`)}
      </p>
      <div className="join-button-wrapper">
        <AdsButton
          text={section.titre_bouton}
          href={section.lien_bouton + '#top'}
          customBtnStyle={{
            color: 'white',
            backgroundColor: '#51262e',
            border: '1px solid white',
          }}
        />
      </div>

      <div className="nous-joindre-siblime-img">
        <div className="sublime-img-text">{section.titre_italique}</div>
        <Img className="sublime-img" src={getFileUrl(section.mobile_media)} />
      </div>
    </SectionWrapper>
  );
};

export default NousJoindreSection;
