import http from '../../api/http';

export const getVitrinDataApi = async (endpoints = 'nos-engagements') => {
  return http
    .get(`/${endpoints}`)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};
