import React from 'react';
import { Col, Row } from 'antd';
import ProductCard from '../../../../components/shop/ProductCard';
import { addToWhitelist } from '../../../../api/shop';
import './style.less';

const gutter = [
  { sm: 35, xs: 10 },
  { sm: 20, xs: 15 },
];

const FeaturedRow = ({ data, category }) => {
  const { title, products = [] } = data;

  return (
    <div className="featured-row">
      <h4 className="featured-title"> {title} </h4>

      <Row justify={'flex-start'} gutter={gutter}>
        {products.map((item) => (
          <Col key={item.id} xs={12} sm={12} md={8}>
            <ProductCard
              data={item}
              onSelectWhitelist={() => addToWhitelist(item.id)}
              category={category}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FeaturedRow;
