import './style.less';

import Img from 'react-cool-img';
import { getFileUrl, getNavHashTo } from '../../../../utils';
import { NavHashLink } from 'react-router-hash-link';
import SectionWrapper from '../../../../components/SectionWrapper';

const MosaicSection = ({ data, hashId }) => {
  const createMosaic = (d) => {
    if (!d) return '';
    return d.map((item, id) => (
      <div className="mosaic-card-wrapper" key={id}>
        <NavHashLink {...getNavHashTo(window, item.link)}>
          <p className="sublime-img-text">{item.showTitle && item.title}</p>

          <Img
            src={getFileUrl(item.image, { small: true })}
            className="card-img"
          />

          <button className="more">
            {item.showReadMoreButton && item.buttonText}
          </button>
        </NavHashLink>
      </div>
    ));
  };

  return (
    <>
      <SectionWrapper className="mosaic-sections" hashId={hashId}>
        {createMosaic(data)}
      </SectionWrapper>
    </>
  );
};

export default MosaicSection;
