import React from 'react';
import HighlightingItem from '../../components/shop/HighlightingItem';
import SectionWrapper from '../../components/SectionWrapper';

const HighlightingCollectionMobile = ({ rootData, items = [] }) => {
  //

  const { hashId } = rootData;

  return (
    <SectionWrapper hashId={hashId} className="highlighting-section">
      <div className="title-header">
        <h1 className="header-h1-text-italic center-text bring-upfront">
          {rootData.title}
        </h1>
      </div>
      {items[0] && <HighlightingItem item={items[0]} height="314px" />}
      <div className="description-wrapper">
        <p className="titleNew"> {rootData.label}</p>
        <p
          className="descriptionNew"
          dangerouslySetInnerHTML={{
            __html: rootData.text_introductif,
          }}
        ></p>
      </div>

      {/* <ProductsSection data={rootData} showSectionTitle={false} showMoreButton={false} /> */}

      <div className="description-wrapper">
        <p
          className="descriptionNew"
          dangerouslySetInnerHTML={{
            __html: rootData.infos,
          }}
        ></p>
      </div>
    </SectionWrapper>
  );
};

export default HighlightingCollectionMobile;
