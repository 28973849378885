import React from 'react';
import './style.less';

import { useSelector, useDispatch } from 'react-redux';

import { useLocation, useHistory } from 'react-router-dom';
import closeIcon from '../../../assets/icons/close-icon.svg';
import queryString from 'query-string';

import { alertDemandSelector } from '../../../stores/product/selector';
import {
  makeStockALertAction,
  setAlertDemandsAction,
} from '../../../stores/product/actions';
import useAuth from '../../../stores/auth/hook';

const defaultText = {
  alertText: 'Je souhaite être informé(e) lorsque ce produit sera disponible.',
  removeAlertText: 'Retirer de mes rappels',
  alertConfirmText:
    "Ce produit est noté dans votre liste de suivi, vous serez informé(e) lorsqu'il sera disponible.",
};

const StockCallback = ({ product, texts = defaultText }) => {
  //

  const { linkAuthUser, isAuth } = useAuth();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const alertDemands = useSelector(alertDemandSelector);

  const [hasCallback, setHasCallback] = React.useState(false);
  const { pathname, search } = location;

  React.useEffect(() => {
    const { callback } = queryString.parse(search);
    if (callback === 'true') {
      handleCAllback('+');
    }
    // 
  }, [search]);

  const handleCAllback = async (operation) => {
    if (isAuth) {
      dispatch(makeStockALertAction({ id: product.id, operation }));
      history.push(pathname);
    } else {
      linkAuthUser(pathname + '?callback=true');
    }
  };

  React.useEffect(() => {
    if (!alertDemands) {
      dispatch(setAlertDemandsAction());
    }
    setHasCallback(isProductCallback());

    // 
  }, [alertDemands]);

  const isProductCallback = () => {
    if (!alertDemands) return false;
    const { content } = alertDemands;

    const index = content.findIndex(
      (item) => (item.product || {}).id === product.id
    );

    return index !== -1;
  };

  const onCallbackClick = async (operation) => {
    if (operation === '+') {
      if (!isAuth) linkAuthUser(pathname + '?callback=true');
      else handleCAllback('+');
    } else {
      await dispatch(makeStockALertAction({ id: product.id, operation }));
    }
  };

  return (
    <div className="sold-out-bloc">
      {!hasCallback ? (
        <button
          onClick={() => onCallbackClick('+')}
          className="stock-callback-btn"
        >
          {texts.alertText}
        </button>
      ) : (
        <div className="has-callback">
          <p className="has-callback-text">{texts.alertConfirmText}</p>
          <button
            onClick={() => onCallbackClick('-')}
            className="stock-callback-btn remove-callback-btn"
          >
            {texts.removeAlertText}
            <img src={closeIcon} alt="close-button" />
          </button>
        </div>
      )}
    </div>
  );
};

export default StockCallback;
