import { Col, Row, Space } from 'antd';

import { useDispatch } from 'react-redux';
import { openModal } from '../../stores/app/actions';

import Img from 'react-cool-img';
import styled from 'styled-components';

import AppMenuList from './AppMenuList';
import { MOBILE_SIZE } from '../../config';
import { buildMenuConfig } from '../../Routes/config';
import SocialRow from '../../components/SocialRow';
import {
  CONDITION_LINK,
  FAVORI_LINK,
  USER_PROFILE_LINK,
} from '../../Routes/links';
import { NavHashLink } from 'react-router-hash-link';
import menuWoomen from '../../assets/images/menuImage.png';
import { ReactComponent as ProfileLogo } from '../../assets/icons/user.svg';
import { ReactComponent as WishLogo } from '../../assets/icons/heart.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import { getFileUrl } from '../../utils';
import { MODALPAGES } from '../../stores/app/reducer';

const Menucontent = ({ onClose }) => {
  const dispatch = useDispatch();

  const { menus: menuData, image } =
    useVitrinFetcher2({
      endpoints: 'menu-config',
      name: 'menuData',
    }) || {};

  const { defaultMenu } = buildMenuConfig(menuData);
  const gutter = [40];

  const handleOpenUserProfil = () => {
    dispatch(
      openModal({ type: MODALPAGES.SIGNIN, nextUrl: USER_PROFILE_LINK })
    );
    onClose();
  };

  const handleOpenWishlist = () => {
    dispatch(openModal({ type: MODALPAGES.SIGNIN, nextUrl: FAVORI_LINK }));
    onClose();
  };

  const displayActionButtons = () => (
    <div className="actions-buttons">
      <Space className="left-action mobile" size={18} align="baseline">
        {!JSON.parse(sessionStorage.getItem('user')) && (
          <ProfileLogo onClick={handleOpenUserProfil} />
        )}
        {JSON.parse(sessionStorage.getItem('user')) && (
          <NavHashLink to={`${FAVORI_LINK}#top`}>
            <WishLogo onClick={handleOpenWishlist} />
          </NavHashLink>
        )}
      </Space>

      <CloseButton onClick={onClose} className="close-button" />
    </div>
  );

  return (
    <div className="app-menu">
      <div className="wrapper">
        {displayActionButtons()}

        <Row gutter={gutter}>
          <Col className="img-wrapper">
            <Img src={getFileUrl(image, { md: true }) || menuWoomen} alt="" />
          </Col>

          <Col className="menu-wrapper">
            <Row gutter={[0, 20]}>
              <Col sm={24} md={24} lg={24}>
                <AppMenuList menus={defaultMenu} onClose={onClose} />
              </Col>

              <Col xs={24} md={12} lg={12}>
                <CustomLink
                  className="condition"
                  link={CONDITION_LINK}
                  onClick={() => onClose()}
                  title={
                    <>
                      Conditions générales de vente
                      <br />
                      et Mentions légales
                    </>
                  }
                />
              </Col>

              <Col xs={24} md={12} lg={12}>
                <SocialRow />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  @media (max-width: ${MOBILE_SIZE}px) {
    cursor: pointer;
    width: 21px;
    height: 21px;
  }
`;

export default Menucontent;

const CustomLink = ({ title, link, ...props }) => (
  <NavHashLink to={`${link}#top`} {...props}>
    {title}
  </NavHashLink>
);
