import React,{useEffect,useState} from 'react';
import { connect } from 'react-redux';
import './favs.less';
import { addItemToCart } from '../../../stores/cart/cartActions';
import cartIcon from '../../../assets/icons/cart.svg';
import closeIcon from '../../../assets/icons/close-icon.svg';
import { getFileUrl } from '../../../utils';
import { removeItemFromWishlist } from '../../../stores/wishList/actions';
import {
  performAddToCard,
  showCardPrice,
} from '../../../components/Cart/utils';
import { useHistory } from 'react-router-dom';
import useProductSoldOut from '../../../stores/product/hooks/useProductSoldOut';
import axios from 'axios';
const FavItem = ({ item, addToCart, removeItem }) => {
  //
  const [appointmentLink,setAppointmentLink] = useState('')
  useEffect(()=>{
    async function getRdvLink(){
      var link = (await axios.get(process.env.REACT_APP_BACKOFFICE_URL+"/configuration"))?.data.prendreRdv
      setAppointmentLink(link)
    }
    getRdvLink()
  })
  useEffect(()=>{},[appointmentLink])
  let history = useHistory();

  const { isSoldOut, soldOutMessage } = useProductSoldOut(item.product);

  const onClick = (slug) => {
    history.push(`/produits/${slug}`);
  };

  return (
    <div className="favs-card">
      <div className="favs-card__top">
        <div>
          {item.product.image && (
            <img
              onClick={() => onClick(item.product.slug)}
              src={getFileUrl(item.product.image, { small: true })}
              alt="article_image"
              className="favs-card__image"
            />
          )}
        </div>

        {!isSoldOut && (
          <img
            src={cartIcon}
            alt="cart.svg"
            className="favs-card__icon cart-icon"
            onClick={() => performAddToCard(addToCart, item.product, window)}
          />
        )}

        <img
          src={closeIcon}
          alt="close-icon.svg"
          className="favs-card__icon close-icon"
          onClick={() => removeItem(item.product.id)}
        />

        {isSoldOut ? (
          soldOutMessage
        ) : (
          <a href={appointmentLink} target={"_blank"} rel="noreferrer" >
            <div className="favs__try">ESSAYER EN INSTITUT</div>
          </a>
        )}
      </div>

      <div className="favs-card__bottom">
        <div className="favs-card__article-name">{item.product.name}</div>

        <div className="favs-card__price">
          {showCardPrice(item.product.price, item.product.sale)}{' '}
        </div>
      </div>
    </div>
  );
};

// export default Favs;

const Wrapper = connect(
  (state) => ({
    //  cart: cartSelector(state),
  }),
  (dispatch) => {
    return {
      addToCart: (data) => dispatch(addItemToCart(data)),
      removeItem: (data) => dispatch(removeItemFromWishlist(data)),
    };
  }
);

export default Wrapper(FavItem);
