import React from 'react';
import './style.less';
import HeaderH1TextNormal from '../../home/HeaderText/HeaderH1TextNormal';
import { Row, Col } from 'antd';
import Img from 'react-cool-img';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getFileUrl } from '../../../utils';
import HTMLReactParser from 'html-react-parser';
import SectionWrapper from '../../../components/SectionWrapper';

const { Panel } = Collapse;

const IllustratorCOllapse = ({ item, index }) => {
  //

  return (
    <SectionWrapper hashId={item.hashId} key={item._id}>
      <div className="delai-duree">
        <div className="titlefaq-bottom">
          <HeaderH1TextNormal
            text={item.normal_tilte}
            isCenter={false}
            shouldBringUp={false}
          />
        </div>
      </div>

      <Row>
        <Col xs={24} sm={10} md={8} lg={10}>
          <div className="img-faq-position">
            <Img
              className="duree-img"
              src={getFileUrl(item.desktop_media)}
              alt="Duree"
            />
          </div>
        </Col>
        <Col xs={24} sm={14} md={16} lg={14}>
          <Collapse
            className="font-collapse-1"
            accordion
            expandIconPosition="right"
            // defaultActiveKey={["0"]}
            expandIcon={({ isActive }) => (
              <DownOutlined rotate={isActive ? -180 : 0} />
            )}
          >
            {item.sectionAccordeon &&
              item.sectionAccordeon.map((item) => {
                return (
                  <Panel header={item.tilte} key={item._id}>
                    <p>{HTMLReactParser(`${item.description}`)}</p>
                  </Panel>
                );
              })}
          </Collapse>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default IllustratorCOllapse;
