import { useEffect } from 'react';

function useHashScrooler({ location, data, time = 2000 }) {
  //

  useEffect(() => {
    performScrolling();

    return () => {};
    // 
  }, [data, location]);

  const performScrolling = () => {
    const { hash } = location || {};
    if (hash && hash !== null && !hash.includes('top')) scroolToEssential(hash);
    else scroolToTop();
  };

  const scroolToEssential = (hashId) => {
    setTimeout(() => {
      const nodes = document.querySelector(hashId);

      if (nodes && nodes !== null) {
        nodes.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center',
        });
      }
    }, time);
  };

  const scroolToTop = () => {
    document.body.scroll({
      top: 0,
      left: 100,
      behavior: 'smooth',
    });
  };
  return;
}

export default useHashScrooler;
