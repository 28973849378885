import http from '../../api/http';
import { getToken } from '../../api/utils';

export async function registerApi(value) {
  return new Promise(async (resolve, reject) => {
    await http
      .post('/auth/local/register', value)
      .then((data) => resolve(data))
      .catch((error) => reject(error.response.data));
  });
}

export async function loginApi(user) {
  return http
    .post('/auth/local', {
      identifier: user.identifier,
      password: user.password,
    })
    .then((response) => {
      return response;
    });
}

export async function updateUserApi(id, data) {
  const token = getToken();

  return http
    .put(`/users/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    });
}
