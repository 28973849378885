import './style.less';
import { Row, Col } from 'antd';
import Img from 'react-cool-img';
import HTMLReactParser from 'html-react-parser';
import { getFileUrl } from '../../utils';
import SectionWrapper from '../../components/SectionWrapper';

const Rester = ({ data }) => {
  return (
    <SectionWrapper hashId={data.hashId}>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Img
            className="imageRester"
            src={getFileUrl(data.desktop_media)}
            alt=""
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <h1 className="header-h1-text-normal left-text">{data.title}</h1>
          <div className="textRester">
            {HTMLReactParser(`${data.description}`)}
          </div>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default Rester;
