import { connect } from 'react-redux';
import React from 'react';
import { Row, Col } from 'antd';

import Spiner from '../../components/Spiner';
import AdsButton from '../home/Button/AdsButton';
import ProductCardV3 from './productCardv3/ProductCardV3';

import { wishlistSelector } from '../../stores/wishList/selector';
import * as actions from '../../stores/wishList/actions';
import * as cartActions from '../../stores/cart/cartActions';

import './wishList.less';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';

const WishList = (props) => {
  const { wishlist, addToCart, removeFromList } = props;
  const data = useVitrinFetcher2({ endpoints: 'favories-page', name: NAME });

  const [state, setState] = React.useState({
    isMobile: window.innerWidth < 976,
    isLoading: true,
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener('resize', () => {
      setState({ ...state, isMobile: window.innerWidth < 976 });
    });
  }, []);

  let productList;

  if (wishlist) productList = wishlist.content;

  const displayProducts = () => {
    if (!productList) return <Spiner />;

    if (wishlist.count === 0)
      return <p className="empty-text">Vos n'avez aucun favoris</p>;

    return productList.map((p, index) => (
      <Col key={index} className="productCard" lg={8} sm={12} xs={24}>
        <ProductCardV3
          addToCart={(val) => addToCart({ ...val, window })}
          removeFromList={removeFromList}
          data={p.product}
        />
      </Col>
    ));
  };
  
  const showHeader = () => {
    var defaultValues = {
      title: 'Favoris',
      ribon: {
        text: 'INFORMATIONS SUR LA LIVRAISON ET LES RENVOIS DE PRODUITS',
        buttonText: 'EN SAVOIR PLUS',
        linkSlug: '/nos-creations/fidelites#top',
      },
    }
    return (
      <>
        <section className="wishlistPage-section1">
          <Row className="section1-title">
            <Col>
              <h1 className="header-h1-text-italic center-text bring-upfront red-text-color">
                {data?.title || defaultValues.title}
              </h1>
            </Col>
          </Row>
        </section>
        <div className="wishlist-band">
          <Row className="band">
            <Col className="band-info" xs={10} xl={8}>
              {data?.ribon?.text || defaultValues.ribon.text}
            </Col>
            <Col xs={10} xl={8} className="band-seemore">
              <AdsButton
                text={data?.ribon?.buttonText || defaultValues.ribon.buttonText}
                href={`${data?.ribon?.linkSlug || defaultValues.ribon.linkSlug}`}
                customBtnStyle={{
                  borderRadius: 0,
                  padding: 0,
                  border: 'none',
                  textDecoration: 'underline',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  };
  return (
    <div id="wishlistPage">
      {showHeader()}
      <section className="products">
        <Row className="products-row">{displayProducts()}</Row>
      </section>
    </div>
  );
};

const Wrapper = connect(
  (state) => ({
    wishlist: wishlistSelector(state),
  }),

  (dispatch) => {
    return {
      removeFromList: (val) => dispatch(actions.removeItemFromWishlist(val)),
      removeAllFromWishlist: (val) =>
        dispatch(actions.removeAllFromWishlist(val)),
      addToCart: (data) => dispatch(cartActions.addItemToCart(data)),
      removeAll: () => {
        dispatch(actions.removeAllFromWishlist());
      },
    };
  }
);

export default Wrapper(WishList);

const NAME = 'favories-page';
