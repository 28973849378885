import React from 'react';
import { useSelector } from 'react-redux';
import { isValidValue } from '../../utils';
import { wishlistSelector } from './selector';

const useWhishList = ({ productId }) => {
  const wishList = useSelector(wishlistSelector);
  const [isWished, setIsWished] = React.useState();

  React.useEffect(() => {
    const inside = check(productId);
    setIsWished(inside);
    // 
  }, [productId, wishList]);

  const check = (id) => {
    if (!isValidValue(wishList) || !isValidValue(wishList.content))
      return false;
    return wishList.content.findIndex((item) => item.product.id === id) !== -1;
  };

  return { isWished };
};

export default useWhishList;
