import React from 'react';
import './style.less';

const VariationDetails = ({ variation }) => {
  const { content } = variation;

  if (!variation.slug) return <></>;

  const keys = Object.keys(content);

  if (keys.length === 0) return '';

  const options = keys.map((item) => content[item]);

  return (
    <div className="variation-details">
      {options.map((item,index) => (
        <li key={index} className="info-item--detail info-item--small">
          <span className="title">{item.filterTitle.toLowerCase()}</span> :
          <span className="value">{` ${item.value}`}</span>
        </li>
      ))}
    </div>
  );
};

export default VariationDetails;
