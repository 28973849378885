import { Button, Col, Input, Row, Form } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import AdsButton from '../../home/Button/AdsButton';

const SignInForm = ({
  onSubmit,
  onNotSignin,
  onForgotPassword,
  error,
  loading,
}) => {
  //

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit && onSubmit(values);
  };

  const initialValues = {
    keepalive: false,
    identifier: '',
    password: '',
    credError: false,
    loading: false,
    email: '',
  };

  const notSinginForm = (
    <div className="mobile-align-center">
      <Button
        type="link"
        className="content-form-flatbtn-btn"
        onClick={onNotSignin}
      >
        Pas Encore Inscrit? S'inscrire
      </Button>

      <Button
        type="link"
        onClick={onForgotPassword}
        className="content-form-flatbtn-btn forget-mdp"
      >
        Mot De Passe Oublié
      </Button>
    </div>
  );

  return (
    <Form
      form={form}
      size="large"
      initialValues={initialValues}
      onFinish={handleSubmit}
    >

      <Form.Item name="email" required rules={rules.email}>
        <Input className="form-input" placeholder="Email" name="email" />
      </Form.Item>

      <Form.Item name="password" required rules={rules.password}>
        <Input.Password
          className="form-input"
          placeholder="Mot de passe"
          suffix={<EyeFilled />}
        />
      </Form.Item>

      <Form.Item name="keepalive" valuePropName="checked" noStyle>
        <Checkbox className="content-form-savepwd">
          Se souvenir du mot de passe
        </Checkbox>
      </Form.Item>

      <Row gutter={[0, 20]}>
        <Col sm={{ span: 16, order: 1 }} xs={{ span: 24, order: 1 }}>
          {error && (
            <div className="signin-error">
              <p className="signin-error__text mobile-align-center">{error} </p>
            </div>
          )}
        </Col>

        <Col sm={{ span: 8, order: 2 }} xs={{ span: 24, order: 2 }}>
          <Form.Item noStyle>
            <div className="content-form-button">
              <AdsButton
                text="Se connecter"
                htmlType="submit"
                className="submit-button signin-buton"
                id="adz-reset-button"
                loading={loading}
                sm
                isSubmitButton={true}
              />
            </div>
          </Form.Item>
        </Col>

        <Col sm={{ span: 16, order: 3 }} xs={{ span: 24, order: 4 }}>
          {notSinginForm}
        </Col>

        <Col sm={{ span: 8, order: 4 }} xs={{ span: 24, order: 3 }}>
        </Col>
      </Row>
    </Form>
  );
};

export default SignInForm;

const rules = {
  password: [
    {
      required: true,
      message: 'Veuillez entrer un mot de passe',
    },
  ],
  email: [
    {
      required: true,
      message: 'Veuillez entrer une adresse e-mail',
    },
    {
      type: 'email',
      message: 'Veuillez entrer une adresse e-mail valide',
    },
  ],
};
