import { useState, useEffect } from 'react';
import { Table, notification, Button, Alert, Popconfirm } from 'antd';
import { columns } from './data.js';
import { Row, Col } from 'antd';
import { launchCancelOrder } from '../../stores/order/api';
import { NavHashLink } from 'react-router-hash-link';

import moment from 'moment';
import 'moment/locale/fr';

import { getFileUrl } from '../../utils.js';
import { USER_PROFILE_LINK } from '../../Routes/links.js';
import {
  getProductInfos,
} from '../../components/Cart/utils.js';
import VariationDetails from '../../components/Cart/VariationDetails/index.js';

moment.locale('fr');
var mois =
  'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
    '_'
  );
moment.updateLocale('fr', { months: mois });

const openNotification = (text) => {
  const args = {
    message: '',
    description: text,
    duration: 5,
  };
  notification.open(args);
};

const Content = ({ data, isNewOrder }) => {
  const {
    numOrder,
    status = {},
    createdAt,
    totalPrice,
    totalSale,
    shipping,
    amount,
  } = data;

  const [state, setState] = useState({
    isLoading: true,
    isError: false,
    cancelValide: false,
    isReduction: totalPrice !== totalSale,
    orderStatus: status === null ? {} : status,
    tableSataSource: [],
    loading: false,
  });

  const { orderStatus, loading } = state;

  useEffect(() => {
    initState();
    // 
  }, [data]);

  const { tableSataSource } = state;

  const initState = async () => {
    let tableSataSource = [];

    try {
      if (data.cart.length > 0) {
        tableSataSource = await buildDataSource(data.cart);
      }

      setState({ ...state, tableSataSource, isLoading: false });
    } catch (err) {
      setState({ ...state, isLoading: false, isError: true });
    }
  };

  const buildDataSource = async (cart) => {
    let data = [];
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];

      const { product = {}, qte, price = 0, sale = price, variation } = element;
      const { name, slug, image } = getProductInfos(product, variation);

      let totalItem = sale * qte;

      data.push({
        image: (
          <NavHashLink to={`/produits/${slug}`}>
            <img
              className="productImg"
              alt={name}
              src={getFileUrl(image, { small: true })}
            />
          </NavHashLink>
        ),
        product: (
          <>
            {name} {variation && <VariationDetails variation={variation} />}
          </>
        ),
        price: showPrice(price, sale),
        qte: qte,
        priceUnit:
          totalItem.toFixed(2).replace('.', ',').replace(',00', '') + ' €',
      });
    }

    return data;
  };

  const showPrice = (price, sale) => {
    if (sale !== price)
      return (
        <>
          {sale} € <strike> {price} €</strike>
        </>
      );

    return <> {price} € </>;
  };

  const getReduction = (price, sale) => {
    return <>- {(price - sale).toFixed(2)} €</>;
  };

  const launchCancel = async (id) => {
    setState((state) => ({ ...state, loading: true }));

    let result = await launchCancelOrder(id);

    if (result) {
      if (result.ok) {
        openNotification(
          "Votre demande d'annulation a bien été prise en compte."
        );
        setState({
          ...state,
          orderStatus: result.order.status,
          cancelValide: true,
          loading: false,
        });
      } else {
        openNotification("Une erreur s'est produite !");
        setState((state) => ({ ...state, loading: false }));
      }
    }
  };

  const showHeader = () => (
    <div className="header">
      <p>Commande N° : {numOrder}</p>
      <p>Passée le : {moment(createdAt).format('DD MMMM YYYY HH:mm')}</p>
      <p>Statut : {status && status.title}</p>
    </div>
  );

  const displayConfirmationMessage = () => {
    if (!isNewOrder) return;
    return (
      <div className="confirmOrderAlertBox">
        <Alert
          //message="Success Tips"
          description="votre commande a été prise en compte!"
          type="success"
          showIcon
          closable
        />
      </div>
    );
  };

  const shoWCancelButton = () => (
    <Popconfirm
      title="Etes-vous sûr(e) de vouloir annuler votre commande"
      onConfirm={() => launchCancel(data.id)}
      onCancel={() => {}}
      okText="Oui"
      cancelText="Non"
      className="cancel-dialog"
    >
      <Button loading={loading} type="link" className="annule">
        Annuler la commande
      </Button>
    </Popconfirm>
  );

  return (
    <div className="container ">
      {displayConfirmationMessage()}
      {data && showHeader()}

      {tableSataSource && tableSataSource.length > 0 && (
        <Table dataSource={tableSataSource} columns={columns} />
      )}

      {data ? (
        <>
          <Row className="spaceTotal">
            <Col xs={12} sm={12} md={12} lg={12}>
              <p className="facture">Sous-total</p>
              {state.isReduction && <p className="facture">Réduction</p>}
              <p className="facture">Frais de livraison</p>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} className="factureLeft">
              <p className="facture">{totalPrice} €</p>
              {state.isReduction && (
                <p className="facture">{getReduction(totalPrice, totalSale)}</p>
              )}
              <p className="facture">{shipping && '+ ' + shipping.amount} €</p>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12}>
              <p className="factureT">TOTAL (Tva incluse)</p>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12}>
              <p className="factureT total">{amount} €</p>
            </Col>
          </Row>

          <div className="return">
            <NavHashLink to={USER_PROFILE_LINK + '#top'} className="profile">
              RETOURNER AU PROFIL
            </NavHashLink>
            <br /> <br />
            {!isNewOrder &&
              orderStatus.code !== 'cancel_request' &&
              shoWCancelButton()}
          </div>
        </>
      ) : (
        <p>Aucune donnée disponible.</p>
      )}
    </div>
  );
};

export default Content;
