import React from 'react';

const ProductDescription = ({ data }) => {
  const [descToggle, setDescToggle] = React.useState(false);

  const readMoreBtn = (
    <button
      onClick={() => setDescToggle(!descToggle)}
      className="descrption-read-more"
    >
      Lire la suite
    </button>
  );

  const readLessBtn = (
    <button
      onClick={() => setDescToggle(!descToggle)}
      className="descrption-read-more"
    >
      Réduire la description
    </button>
  );

  const chooseButton = descToggle ? readLessBtn : readMoreBtn;

  return (
    <div className="product-section-description">
      <p className="product-section-description-title">DESCRIPTION</p>
      <div
        className={
          descToggle ? 'description-content' : 'description-content--restricted'
        }
        dangerouslySetInnerHTML={{ __html: data }}
      />
      {chooseButton}
    </div>
  );
};

export default ProductDescription;
