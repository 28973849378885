import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../stores/app/actions';
import { isAuthSelector } from '../stores/auth/selector';

import './style.less';

import AppMenu from './Menu';
import { Layout, Space } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ProfileLogo } from '../assets/icons/user.svg';
import { ReactComponent as MenuLogo } from '../assets/icons/menu.svg';

import { NavHashLink } from 'react-router-hash-link';
import * as links from '../Routes/links';
import './style.less';
import CartIcon from '../components/CartIcon';
import { useHistory } from 'react-router-dom';

import AppLogo from './AppLogo';

import { HOME_LINK } from '../Routes/links';

import UserProfileMenu from './Menu/UserProfileMenu';
import WishIcon from '../components/WishListIcon';
import useAuth from '../stores/auth/hook';
import { MODALPAGES } from '../stores/app/reducer';
import axios from 'axios';

const { Header } = Layout;

const LgHeader = ({ menus }) => {
  //
  const [appointmentLink,setAppointmentLink] = useState('')
  useEffect(()=>{
    async function getRdvLink(){
      var link = (await axios.get(process.env.REACT_APP_BACKOFFICE_URL+"/configuration"))?.data.prendreRdv
      setAppointmentLink(link)
    }
    getRdvLink()
  })
  useEffect(()=>{},[appointmentLink])
  const dispatch = useDispatch();

  let history = useHistory();
  const isAuth = useSelector(isAuthSelector);

  const { linkAuthUser } = useAuth();

  const pushRoute = (route) => history.push(route || HOME_LINK);

  const handleOpenWishlist = () => {
    if (linkAuthUser(links.FAVORI_LINK)) pushRoute(links.FAVORI_LINK);
  };

  const handleOpenUserProfil = () => {
    if (!isAuth) openConnectionForm(links.USER_PROFILE_LINK);
    else pushRoute(links.USER_PROFILE_LINK);
  };

  const openConnectionForm = (redirectLink) => {
    dispatch(openModal({ type: MODALPAGES.SIGNIN, nextUrl: redirectLink }));
  };

  const leftHeader = (
    <div className="left-header">
      <NavHashLink className="action" to={`${links.CATEGORIES_LINK}#top`}>
        E-SHOP
      </NavHashLink>

      <CircleButton
        className="rdv-btn"
      >
        <a className="action" target={"_blank"} href={appointmentLink} rel="noreferrer">
          PRENDRE RDV
        </a>

      </CircleButton>
    </div>
  );

  const rightHeader = (
    <div className="rightHeader">
      <div className="phone-number">
        <span>09 83 66 24 22</span>
      </div>

      <div>
        <Space size={14} align="baseline">
          <div>
            {!isAuth ? (
              <ProfileLogo
                style={{ cursor: 'pointer' }}
                onClick={handleOpenUserProfil}
              />
            ) : (
              <UserProfileMenu />
            )}
          </div>

          <WishIcon onClick={handleOpenWishlist} />

          <CartIcon />

          <AppMenu>
            {(handleOpen) => (
              <MenuLogo style={{ cursor: 'pointer' }} onClick={handleOpen} />
            )}
          </AppMenu>
        </Space>
      </div>
    </div>
  );

  return (
    <HeaderContainer id="top" className="lg-header">
      <div className="wrapper container">
        {leftHeader}
        <div className="center">
          <AppLogo
            width="140px"
            height="183px"
            style={{ marginTop: '25px' }}
            onClick={() => pushRoute(links.HOME_LINK + '#top')}
          />
        </div>

        {rightHeader}
      </div>
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Header)`
  position: relative;
  background-color: transparent;
  display: contents;
`;

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.menus || []) === JSON.stringify(nextProps.menus)
  );
}

export default React.memo(LgHeader, areEqual);

const CircleButton = styled.button`
  font-family: Visby CF;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03em;
  text-align: center;

  border: 1px solid #333333;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 8px;

  cursor: pointer;
  width: 170px;
  height: 50px;
`;
