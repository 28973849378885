import './style.less';

import { getFileUrl } from '../../../utils';
import { NavHashLink } from 'react-router-hash-link';

const BestSales = ({ data }) => {
  // destructuring bestSales datas
  const { title, secondary_title, products } = data;

  // repeat function for product cards of the section
  const bestSales = () => {
    return products.map((product, id) => {
      return (
        <div className="sales-product-card" key={id}>
          <NavHashLink to={`/produits/${product.slug}`}>
            <div className="card-image-wrapper">
              <img
                src={getFileUrl(product.image, { small: true })}
                alt={product.name}
              />
            </div>
            <div className="card-footer">
              <span className="product-name">{product.name}</span>
              <div className="product-prices">
                <div className="normal-price">{product.price} €</div>
                {product.sale && (
                  <div className="sold-price">{product.sale} €</div>
                )}
              </div>
            </div>
          </NavHashLink>
        </div>
      );
    });
  };

  // render
  return (
    <div id="best-sales">
      <div className="title">
        <h2 className="sales-title-normal">{title}</h2>
        <h3 className="sales-title-italic">{secondary_title}</h3>
      </div>
      <div className="best-sales-wrapper">
        {products
          ? bestSales()
          : "Aucun produit n'est associé à cette rubrique"}
      </div>
    </div>
  );
};

export default BestSales;
