import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setLinkedAuthAction } from '../stores/auth/actions';
import { MODALPAGES } from '../stores/auth/reducer';
import { isValidValue } from '../utils';

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isValidValue(token))
      dispatch(setLinkedAuthAction(true, restOfProps.path, MODALPAGES.SIGNIN));
    // 
  }, [token]);

  if (token && token !== null) return <Component {...restOfProps} />;

  return <Redirect to={{ pathname: '/' }} {...restOfProps} />;
};

export default ProtectedRoute;
