import './style.less';

import BestSales from './BestSales';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import CategoriesBanner from './CategoriesBanner';
import CategoriesMosaic from './CategoriesMosaic';
import * as actions from '../../stores/vitrin/actions';
import { vitrinSelector } from '../../stores/vitrin/selector';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';

const Categories = ({ location }) => {
  const data = useVitrinFetcher2({
    endpoints: 'page-categories',
    name: NAME,
  });
  useHashScrooler({ location, data });

  if (!data) return <Spiner />;

  //destructuring categories page datas
  const {
    viewAllText,
    banner,
    categories,
    displayStands,
    section_categories_title,
    displayStandsHashId,
  } = data;

  // Repeat function of the bestSales section
  const showDisplayStands = (stand) => {
    if (!stand) return '';
    return (
      <SectionWrapper hashId={displayStandsHashId}>
        {stand.map((element, id) => {
          return <BestSales key={id} data={element} />;
        })}
      </SectionWrapper>
    );
  };

  // Render
  return (
    <div id="categories-page" className="categories-page container">
      <CategoriesBanner data={banner[0]} />
      <CategoriesMosaic
        data={categories?.categories}
        title={section_categories_title}
        btnText={viewAllText}
      />
      {showDisplayStands(displayStands)}
    </div>
  );
};

const NAME = 'page-categories';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Categories);
