import React from 'react';
import CheckoutForm from './Form';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import * as links from '../../Routes/links';

import Spiner from '../../components/Spiner';

const Content = ({
  cart,
  shProviders,
  removeFromCart,
  clearCart,
  tempOrder,
  setTempOrder,
  buildOrderTotals,
}) => {
  //

  async function handleSubmit({ value, promoCode }, callback,subscribe) {
    const { address } = value.shipping;

    if (!address || address.firstName)
      value.shipping = { ...value.shipping, address: value.billing };

    const checkedOrder = await buildOrderTotals({ ...value, promoCode });
    
    if (!checkedOrder) {
      callback && callback();
      return;
    }
    
    if(value.newsletter) subscribe({EMAIL: value.billing.email})
    setTempOrder({ ...value, ...checkedOrder, promoCode });
    window.open(links.PAYMENT_LINK, '_self');
  }
  
  const canDisplay = () => {
    if (!cart) return false;
    if (!shProviders) return false;
    return true;
  };

  return canDisplay() ? (
    <>
      <MailchimpSubscribe
        id="newsLetter"
        url={process.env.REACT_APP_MAILCHIMP_URL}
        render={({ subscribe }) => {
          return (
            <>
              <CheckoutForm
                handleSubmit={handleSubmit}
                cart={cart}
                shProviders={shProviders}
                removeFromCart={removeFromCart}
                tempOrder={tempOrder}
                setTempOrder={setTempOrder}
                buildOrderTotals={buildOrderTotals}
                subscribe={subscribe}
              />
            </>
          )
          }}
        />
    </>
  ) : (
    <Spiner />
  );
};

export default Content;
