import { useHistory, useLocation } from 'react-router-dom';

import './style.less';

import { MOBILE_SIZE } from '../config';
import { HOME_LINK } from '../Routes/links';
import useWindowSize from '../hooks/useWindowSize';
import { buildMenuConfig } from '../Routes/config';

import RenderPcHeader from './RenderPcHeader';
import RenderMobileHeader from './RenderMobileHeader';

const AppHeader = () => {
  //

  const { defaultMenu } = buildMenuConfig();

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  let history = useHistory();
  let { pathname } = useLocation();
  const ishome = pathname === HOME_LINK;

  const pushRoute = (route) => history.push(route || HOME_LINK);
  
  return isMobile ? 
    <RenderMobileHeader defaultMenu={defaultMenu} pathname={pathname} pushRoute={pushRoute}/> :  
    <RenderPcHeader defaultMenu={defaultMenu} ishome={ishome} pushRoute={pushRoute}/>;

};

export default AppHeader;
