import "./style.less";
import { Row, Col } from "antd";
// import { Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const Search = ({ onSearch }) => {
  const onChange = (e) => onSearch && onSearch(e.target.value);

  return (
    <>
      <form>
        <Row className="space-pagination">
          <Col xs={24} sm={12} md={12} lg={12}>
            <SearchOutlined
              type="search"
              style={{ fontSize: "18px", color: "#303030, 100 %" }}
            />
            <input
              type="text"
              className="inputsearch"
              placeholder="Recherche"
              onChange={onChange}
            ></input>
          </Col>
          {/* <Col xs={24} sm={12} md={12} lg={12} className="pagination">
            <Pagination defaultCurrent={1} total={50} />
          </Col> */}
        </Row>
      </form>
    </>
  );
};
export default Search;
