import { Pagination } from 'antd';
import React from 'react';
import styled from 'styled-components';

const ShopPagination = ({
  products,
  page = 0,
  filtervalue,
  reloadProductsPage,
}) => {
  //

  const loadPage = (pageIndex) => {
    reloadProductsPage(filtervalue, pageIndex - 1);
  };

  if (!products) return <> </>;

  const onShowSizeChange = (current, pageSize) => {};

  return (
    <div className="shop-bottom">
      <div className="shop-bottom-page">
        <CPagination
          current={page + 1}
          total={products.size}
          pageSize={9}
          onChange={loadPage}
          onShowSizeChange={onShowSizeChange}
          showSizeChanger={false}
          showQuickJumper={false}
          showLessItems
          // simple
        />
      </div>
    </div>
  );
};

export default ShopPagination;

const CPagination = styled(Pagination)`
  .ant-pagination-item {
    color: #333333;
    font-family: Visby CF;
    font-size: 15px;
    border: none;
    height: 40px;
    background-color: unset;
  }

  .ant-pagination-item-active {
    border-bottom: 1px solid #333333;
    font-weight: 900;
    border-bottom-width: medium;
    width: 45px;
    font-size: 16px;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    margin-bottom: 9px;
    button {
      font-size: 16px;
      border: none;
      background-color: unset;
    }
  }
`;
