import { BASE_URL } from '../../api/config';
import { getToken, getUsers } from '../../api/utils';
import http from '../../api/http';

export const getCartUrl = () => {
  const user = getUsers();

  return user ? `${BASE_URL}/cart` : undefined;
};

export const getTempCartUrl = () => {
  return `${BASE_URL}/cart/tempo`;
};

export const buildTempCartApi = async (selections = []) => {
  return http
    .post(`/cart/tempo`, { selections })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const getProductItem = ({ id }) => {
  return http
    .get(`/products/${id}`)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const getProductItemURLById = (id) => {
  return BASE_URL + `/products/${id}`;
};

export const mergeCartApi = async (selections = []) => {
  const token = getToken();

  return http
    .post(
      `/cart/merge`,
      { selections },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const addItemApi = async ({ id, qte, variation_slug }) => {
  const token = getToken();

  return http
    .post(
      `/cart/${id}`,
      { qte, variation_slug },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      if (error.response) return error.response.data;
    });
};

export const setItemQteApi = async ({ id, qte, variation_slug }) => {
  const token = getToken();

  return http
    .put(
      `/cart/${id}`,
      { qte, variation_slug },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      if (error.response) return error.response.data;
    });
};

export const removeItemApi = async (id) => {
  const token = getToken();

  return http
    .delete(`/cart/${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const addTemporaryItem = async ({ product, qte, variation_slug }) => {
  const tempCart = localStorage.getItem('tempCart');
  let data = [];

  if (tempCart && tempCart !== null) {
    data = JSON.parse(tempCart);
    data = await performAddTemp(data, { product, qte, variation_slug });
  } else {
    data = await performAddTemp(data, { product, qte, variation_slug });
  }

  localStorage.setItem('tempCart', JSON.stringify(data));
  return data;
};

export const setTemporaryQtItem = async ({ product, qte, variation_slug }) => {
  const tempCart = localStorage.getItem('tempCart');
  let data = [];

  if (tempCart && tempCart !== null) {
    data = JSON.parse(tempCart);
    data = await performSetQteTemp(data, { product, qte, variation_slug });
  } else {
    data = await performAddTemp(data, { product, qte, variation_slug });
  }

  localStorage.setItem('tempCart', JSON.stringify(data));
  return data;
};

export const removeTemporaryItemApi = async (id, variation_slug) => {
  const tempCart = localStorage.getItem('tempCart');
  let data = [];

  if (tempCart && tempCart !== null) {
    data = JSON.parse(tempCart);
    data = await performRemoveTemp(data, id, variation_slug);
  }

  localStorage.setItem('tempCart', JSON.stringify(data));
  return data;
};

const performAddTemp = async (data, { product, qte, variation_slug }) => {
  const index = data.findIndex(
    (item) => item.product === product && item.variation_slug === variation_slug
  );

  if (index !== -1) data[index].qte = data[index].qte + qte;
  else data.push({ product, qte, variation_slug });
  return data;
};

const performSetQteTemp = async (data, { product, qte, variation_slug }) => {
  const index = data.findIndex(
    (item) => item.product === product && item.variation_slug === variation_slug
  );

  if (index !== -1) data[index].qte = qte;
  else data.push({ product, qte });
  return data;
};

const performRemoveTemp = async (data, id, variation_slug) => {
  const index = data.findIndex(
    (item) => item.product === id && item.variation_slug === variation_slug
  );

  if (index !== -1) data.splice(index, 1);

  return data;
};
