import HTMLReactParser from 'html-react-parser';
import HeaderH1TextNormal from './HeaderText/HeaderH1TextNormal';

import AdsButton from './Button/AdsButton';
import SectionWrapper from '../../components/SectionWrapper';
import VideoPlayer from '../../components/VideoPlayer';

const SavoirFaire = ({ data = {} }) => {
  //

  const { description, title, external_video_link, buttonJedecouvre, hashId } =
    data;

  return (
    <SectionWrapper hashId={hashId} className="youtube-section">
      <HeaderH1TextNormal text={title} isCenter={false} shouldBringUp={false} />

      <p className="youtube-text">{HTMLReactParser(description)}</p>

      {external_video_link && (
        <VideoPlayer url={external_video_link} title={title} />
      )}

      {buttonJedecouvre && buttonJedecouvre !== null && (
        <AdsButton
          text={buttonJedecouvre.butonText}
          href={buttonJedecouvre.butonLink + '#top'}
          customBtnStyle={{
            color: '#333333',
            backgroundColor: 'transparent',
            border: '1px solid #333333',
            marginTop: '20px',
          }}
        />
      )}
    </SectionWrapper>
  );
};

export default SavoirFaire;
