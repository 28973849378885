import './style.less';
import Intro from './Intro';
import ClientsConfiances from './ClientsConfiances';
import IntroVideo from './IntroVideo';
import Rester from './Rester';
import './style.less';

import Spiner from '../../components/Spiner';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';
import VideoPlayer from '../../components/VideoPlayer';

const Confiance = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'elles-nous-font-confiance',
    name: NAME,
  });

  useHashScrooler({ location, data });

  if (!data) return <Spiner />;

  const { SectionEllesNousFontConfiance } = data;

  return (
    <div id="Confiance">
      <div className="container">
        <SectionWrapper
          hashId={SectionEllesNousFontConfiance.hashId}
          className="titleSection"
        >
          <h1 className="header-h1-text-italic center-text bring-upfront red-text-color">
            {data.SectionEllesNousFontConfiance.italic_title}
          </h1>
        </SectionWrapper>
        <div className="contentConfiance">
          <Intro data={data.SectionEllesNousFontConfiance} />
          <ClientsConfiances data={data.sectionListeImage} />
          <IntroVideo data={data.sectionVideo} />
          <VideoPlayer
            url={data?.sectionVideo?.externalVideoLink}
            title="Perruque Lace Wig 100% indétectable avec la technique HAIRLINEMATCH® / Most natural ever hairline"
          />
          <Rester data={data.sectionStayWithUs} />
        </div>
      </div>
    </div>
  );
};

const NAME = 'elles-nous-font-confiance';

export default Confiance;
