import React, { useState } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';

import './productCard.less';
import { APPOINTMENT_LINK } from '../../Routes/links';
import { ShoppingOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';

import * as actions from '../../stores/cart/cartActions';
import * as wishlistActions from '../../stores/wishList/actions';

import { connect } from 'react-redux';
import './productCard.less';
import { performAddToCard, showCardPrice } from '../Cart/utils';
import { getFileUrl } from '../../utils';
import useProductSoldOut from '../../stores/product/hooks/useProductSoldOut';
import { FaEye } from 'react-icons/fa';
import ImagesPreviewer from '../ImagesPreviewer';

import WishLogo from './WishLogo';

const ProductCard = ({
  data,
  addToCart,
  addToWishlist,
  showAppointmentLink = true,
  category,
}) => {
  let history = useHistory();

  const { id, slug, image, name, price, sale } = data;

  const [state, setState] = useState(false);

  const { slides, openPriviewer } = state;

  const { isSoldOut, soldOutMessage } = useProductSoldOut(data);

  React.useEffect(() => {
    data && setProductGaleryImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOpenPriviewer = (val) => {
    setState((state) => ({ ...state, openPriviewer: val }));
  };

  const handleOpenView = (e) => {
    e.stopPropagation();
    setOpenPriviewer(true);
  };

  const setProductGaleryImage = () => {
    if (data) {
      const { GalleryImgs = [], image } = data;
      let slides = [];
      const index = GalleryImgs.findIndex((item) => item?.id === image?.id);

      if (index === -1) slides = [image];

      if (GalleryImgs && GalleryImgs !== null)
        slides = [...slides, ...GalleryImgs];

      setState({
        ...state,
        slides,
      });
    }
  };

  const displayAppointmentLink = () => {
    if (!showAppointmentLink) return;
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
          history.push(APPOINTMENT_LINK);
        }}
      >
        ESSAYER EN INSTITUT
      </span>
    );
  };

  const handleAddToCArt = async (e) => {
    e.stopPropagation();
    performAddToCard(addToCart, data, window);
  };

  const onClick = () => {
    history.push(`/produits/${slug}`, { category });
  };

  const displayViewerButton = () => {
    if (!slides || slides.length <= 0) return;

    return (
      <div className=" product-card-view-icons">
        <FaEye style={{ fontSize: '20px' }} onClick={handleOpenView} />
      </div>
    );
  };

  return (
    <div className="product-card">
      <div className="product-card-img-wrapper">
        <NavHashLink
          to={{ pathname: `/produits/${slug}`, state: { category } }}
        >
          <img src={getFileUrl(image, { small: true })} alt={'ads' + name} />
        </NavHashLink>

        <div className="product-card-icons">
          <WishLogo
            productId={id}
            children={({ onClick }) => <HeartOutlined onClick={onClick} />}
            filledChildren={({ onClick }) => (
              <HeartFilled style={{ color: '#B22222' }} onClick={onClick} />
            )}
          />

          {!isSoldOut && <ShoppingOutlined onClick={handleAddToCArt} />}
        </div>

        {displayViewerButton()}

        {isSoldOut ? (
          soldOutMessage
        ) : (
          <p className="product-card-trybtn">{displayAppointmentLink()}</p>
        )}
      </div>

      <div className="product-card-detail">
        <p onClick={onClick} className="product-card-text">
          {name}
        </p>
        <p className="product-card-amount">{showCardPrice(price, sale)}</p>
      </div>

      <ImagesPreviewer
        open={openPriviewer}
        setOPen={(val) => setOpenPriviewer(val)}
        images={slides}
      />
    </div>
  );
};

const Wrapper = connect(
  () => ({
    //  cart: cartSelector(state),
  }),

  (dispatch) => {
    return {
      addToCart: (data) => dispatch(actions.addItemToCart(data)),
      addToWishlist: (data) =>
        dispatch(wishlistActions.addItemToWishlist(data)),
    };
  }
);

export default Wrapper(ProductCard);
