import React from 'react';
import './style.less';
import BannerSection from '../home/HomeSections/BannerSection';
import Content from './Content';

import { vitrinSelector } from '../../stores/vitrin/selector';
import * as actions from '../../stores/vitrin/actions';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import useEssentialScrooler from '../../hooks/useEssentialScrooler';
import Search from './Search';

const Blog = ({ data, collectionData, setData, location }) => {
  const [results, setResults] = React.useState();

  const setDatas = () => {
    if (!data)
      setData({
        endpoints: 'page-blog',
        name: NAME,
      });

    if (!collectionData)
      setData({
        endpoints: 'blogs?_sort=published_at:DESC',
        name: COLLECTION_NAME,
      });
  };

  React.useEffect(() => {
    setDatas();
    
  }, []);

  React.useEffect(() => {
    collectionData && initNewResults('');
    
  }, [collectionData]);

  const initNewResults = (text) => {
    if (text.trim().length === 0) setResults(collectionData);

    const searchScope = (scope) => {
      let search =
        scope.post[0].normal_title + ' ' + scope.post[0].italic_title;
      return search;
    };

    const newList = collectionData.filter((r) =>
      searchScope(r).toLowerCase().includes(text.toLowerCase())
    );

    setResults(newList);
  };

  const onSearch = async (text) => {
    initNewResults(text);
  };

  useEssentialScrooler({ data, location, hashId: '#blogs' });

  if (!collectionData) return <Spiner />;

  const { banners = [] } = data || {};

  return (
    <div id="blog">
      <div className="container blog">
        <div className="banner-section">
          {banners.length > 0 && (
            <BannerSection
              normalHeaderText={banners[0].titre_normal}
              italicHeadertext={banners[0].titre_italique}
              banner={banners}
            />
          )}

          <div id="blogs" style={{ marginBottom: '50px' }} />

          <Search onSearch={onSearch} />

          <Content
            datas={results}
            onSearch={onSearch}
            emptyMessage={(data || {}).emptyMessage || 'Pas de blogs'}
          />
        </div>
      </div>
    </div>
  );
};

const COLLECTION_NAME = 'blogs';
const NAME = 'pageBlogs';

const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
    collectionData: vitrinSelector(state, COLLECTION_NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Blog);
