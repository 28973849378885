import React, { useState } from 'react';
import './style.less';
import Parametre from './parametre';
import { useCookies } from 'react-cookie';
export default function Cookie() {
  const [openParametre, setOpenParametre] = useState(false);
  const [cookies, setCookie] = useCookies();
  const handleOpenParametre = () => {
    setCookiesValues('_acceptedCookie', true);
    setOpenParametre(true);
  };
  const setCookiesValues = (name, value) => {
    setCookie(name, value, {
      path: '/',
      maxAge: 30 * 24 * 60 * 60,
    });
  };
  const onCloseParametre = () => {
    setOpenParametre(false);
  };
  const saveParamsData = (data) => {
    // traitement
    setOpenParametre(false);
  };
  return (
    <>
      {cookies._acceptedCookie === undefined && (
        <div id="cookiescript_injected">
          <div id="cookiescript_header">Ce site web utilise des cookies</div>
          <div id="cookiescript_description">
            En cliquant sur « accepter », vous acceptez le dépôt de cookies pour
            réaliser des statistiques visant à évaluer le trafic sur notre site
            et pour vous proposer sur d’autres sites des annonces publicitaires
            ciblées en lien avec votre visite sur notre site.
          </div>
          <div id="cookiescript_buttons">
            <div
              id="cookiescript_accept"
              role="button"
              onClick={() => {
                setCookiesValues('_acceptedCookie', true);
              }}
            >
              Accepter
            </div>
            <div
              id="cookiescript_reject"
              role="button"
              onClick={handleOpenParametre}
            >
              Parametrer
            </div>
          </div>
          <div
            id="cookiescript_close"
            role="button"
            onClick={() => {
              setCookiesValues('_acceptedCookie', false);
            }}
          >
            Continuer sans accepter
          </div>
        </div>
      )}
      <Parametre
        open={openParametre}
        onClose={onCloseParametre}
        saveParamsData={saveParamsData}
      />
    </>
  );
}
