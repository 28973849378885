import dureeImg from '../../assets/images/faq/duree-img.png';
import dureeImg2 from '../../assets/images/faq/duree-img2.png';
import dureeImg3 from '../../assets/images/faq/duree-img3.png';

const data = {
  illustratorCollapse: [
    {
      title: 'Délais/ durée',
      image: dureeImg,
      Content: [
        {
          titlefaq: (
            <>Quels sont les délais d’attente pour une Prothèse Capillaire ?</>
          ),
          content: (
            <>
              Veuillez noter que ces délais sont donnés à titre indicatif et
              sont parfois variables en raison de la situation sanitaire. <br />{' '}
              Commandes sur mesure : 8 à 10 semaines <br />
              Commandes personnalisées : 2 à 3 semaines <br />
              Modèles en stock disponibles sur place à l’institut : la pose est
              immédiate. Si vous commandez à distance, l’expédition se fait sous
              24 à 72h selon les personnalisations souhaitées.
            </>
          ),
        },
        {
          titlefaq: (
            <>
              Que faire si, une fois la Prothèse Capillaire / Lace Wig reçue, la
              couleur d’un modèle sur-mesure ne correspond pas à mes attentes ?
            </>
          ),
          content: (
            <>
              L’appréciation des couleurs, tons, nuances, reflets varie souvent
              d’une personne à l’autre. <br /> Nous recevons parfois de nos
              clientes 4 à 5 photos de modèles pour une même demande, mais pour
              nos coiffeuses les couleurs sont diamétralement opposées. Nous
              essayons donc en amont d’échanger le plus possible, par mail,
              téléphone ou au salon afin d’être en phase avec vos envies et
              attentes. Si la couleur ne correspondait pas à vos souhaits, nous
              reprendrons à notre charge la 1ère retouche.{' '}
            </>
          ),
        },
        {
          titlefaq: (
            <>Quel est le délai d’attente pour une commande passée en ligne ?</>
          ),
          content: (
            <>
              Comme pour les commandes passées en salon.
              <br />
              Commandes sur mesure = 8 semaines. <br />
              Commandes personnalisées = 3 semaines. <br />
              <br />
              Les modèles en stock sont expédiés sous 24 à 72h (jours ouvrables)
              en fonction des personnalisations souhaitées.
            </>
          ),
        },
        {
          titlefaq: <>Combien de temps vais-je pouvoir conserver ma Lace ?</>,
          content: (
            <>
              Cela dépend de la coloration, de la fréquence d’utilisation, et de
              l’ entretien de votre Lace. <br /> Les délais d’utilisation
              varient en moyenne entre 6 mois et 2 ans. <br />
              - Cheveux blonds : port quotidien ( tous les jours + la nuit) = 6
              mois. <br /> - Cheveux vierges : port quotidien ( tous les jours +
              la nuit) = 1 an. <br />- Cheveux vierges : port en alternance avec
              une autre coiffure = 2 à 5 ans.
            </>
          ),
        },
      ],
    },
    {
      title: (
        <>
          Laces disponibles <br /> en salon
        </>
      ),
      image: dureeImg2,
      Content: [
        {
          titlefaq: <>Avez-vous des Laces en vente en salon ?</>,
          content: (
            <>
              Oui nous avons des Laces en salon disponibles à la vente et pour
              l’essayage.
            </>
          ),
        },
        {
          titlefaq: (
            <>
              Tous les modèles de la institut en ligne sont-ils disponibles en
              salon ?
            </>
          ),
          content: (
            <>
              Non tous les modèles de la institut en ligne ne sont pas toujours
              disponibles en salon dans la mesure où nous les recréons selon la
              demande, les tendances et selon notre inspiration. <br /> Notre
              salon fonctionne comme un atelier qui met ses créations de la
              semaine à votre disposition. Nous avons des modèles similaires et
              parfois identiques à ceux proposés sur notre institut en ligne.
              N’hésitez pas à nous appeler au préalable pour connaitre les
              disponibilités au 0983662422.
            </>
          ),
        },
      ],
    },
    {
      title: (
        <>
          Mise en forme et <br /> coloration
        </>
      ),
      image: dureeImg3,
      Content: [
        {
          titlefaq: (
            <>Qui réalise la couleur de ma Prothèse Capillaire / Lace Wig ?</>
          ),
          content: (
            <>
              Nos Laces Wigs sont mises en coupes et colorées au sein de notre
              salon, par une équipe de coloristes et de coiffeuses expérimentées
              titulaires d’un CAP et BP coiffure.
            </>
          ),
        },
        {
          titlefaq: (
            <>
              Est-il possible de recolorer sa Prothèse Capillaire / Lace Wig ?
            </>
          ),
          content: (
            <>
              OUI. Nos Laces sont conçues à partir de cheveux vierges Indiens
              100% naturels. Il est donc possible de pratiquer tout type
              coloration ou une décoloration. <br /> Cependant une coloration
              répétée du cheveu risque de le fragiliser. C’est pourquoi nous
              conseillons à nos clientes de ne pas excéder plus de 2 colorations
              sur une Lace.
            </>
          ),
        },
        {
          titlefaq: (
            <>
              Que faire si, une fois ma Prothèse Capillaire / Lace Wig reçue, la
              couleur d’un modèle du site ne correspond pas à mes attentes ?
            </>
          ),
          content: (
            <>
              Tous les modèles mis en ligne sur notre site sont pensés, conçus
              et crées par nos coiffeuses. <br /> Les modèles sont recréés à
              chaque commande, c’est donc un travail artistique et chaque
              produit qui quitte nos locaux est unique. Il n’est donc pas
              impossible que la nuance diffère du modèle présenté sur le site.
              Nous mettons toutefois tout en œuvre afin que les modèles soient
              toujours similaires. <br /> Dans ce cas, nous reprendrons la
              couleur à nos frais.
            </>
          ),
        },
      ],
    },
    {
      title: (
        <>
          Fabrication de Lace <br /> Wig
        </>
      ),
      image: dureeImg,
      Content: [
        {
          titlefaq: <>Quels sont les principaux types de Laces ?</>,
          content: (
            <>
              Les principaux types de Laces sont : <br /> Full Lace – cheveux
              implantés sur l’ensemble du bonnet, possibilité de la porter
              uniquement avec de la colle <br /> G Lace – cheveux implantés sur
              l’ensemble du bonnet et plusieurs méthodes de poses <br /> Front
              Lace – cheveux implantés sur la partie avant + bandes de tissages
              à l’arrière tête <br /> Full Lace Silicone – destinée aux
              personnes qui n’ont plus de cheveux, le silicone posé sur
              l’ensemble du bonnet permet une meilleure adhérassions sur le
              crâne.
            </>
          ),
        },
        {
          titlefaq: <>En quoi consiste le sur-mesure ?</>,
          content: (
            <>
              Le sur-mesure permet d’avoir une Lace personnalisée et
              indétectable qui s’adapte parfaitement à vos attentes. (Longueur,
              densité, dentelle du bonnet adaptée à votre couleur de peau,
              texture, taille, effet faux cuir chevelu… etc.) <br /> 6 mesures
              de votre tête doivent être prises pour un meilleur ajustement.
            </>
          ),
        },
        {
          titlefaq: (
            <>
              Comment les Prothèses Capillaires / Lace Wigs sont-elles
              confectionnées ?
            </>
          ),
          content: (
            <>
              Les Laces sont confectionnées avec soin dans nos ateliers, le
              cheveu est implanté à la main. Le temps de fabrication d’une seule
              Prothèse Capillaire / Lace Wig est d’environ deux mois.
            </>
          ),
        },
        {
          titlefaq: (
            <>
              Les cheveux implantés sur les Prothèses Capillaires / Lace Wigs
              sont-ils naturels ?
            </>
          ),
          content: (
            <>
              OUI. Les cheveux de nos Prothèses Capillaires / Lace Wigs sont
              100% naturels.
            </>
          ),
        },
        {
          titlefaq: (
            <>
              Quel type de cheveux utilisez-vous pour la fabrication de vos
              Prothèses Capillaires / Lace Wigs?
            </>
          ),
          content: (
            <>
              Parce que la qualité et la satisfaction de nos client(e)s est au
              centre de nos préoccupations, nous utilisons pour la fabrication
              de nos Laces des cheveux exclusivement Indiens et 100% naturels.
              Nous utilisons aussi sur demande des cheveux malaisiens,
              brésiliens et européens.
            </>
          ),
        },
        {
          titlefaq: <>Quelles textures de cheveux proposez-vous ?</>,
          content: (
            <>
              Toutes les textures de cheveux sont réalisables et reproductibles
              chez L'ARTISAN DU SUBLIME. Frisés, raides, crépus, ondulés…
            </>
          ),
        },
        {
          titlefaq: (
            <>
              La Prothèse Capillaire / Lace Wig est -elle
              complètement indétectable ?
            </>
          ),
          content: (
            <>
              Nous mettons tout en œuvre pour que vos Laces soient les plus
              indétectables possible. <br /> Pour favoriser un rendu naturel,
              des options telles que le silk top (faux crane) , les nœuds
              blanchis (Ultra Lace) ou encore un voile adapté à la couleur de
              votre carnation sont mises à votre disposition. <br /> La Prothèse
              Capillaire / Lace Wig reste toutefois un accessoire capillaire à
              porter. Il ne pourra jamais avoir le rendu d’un implant
              chirurgical mais sera invisible à l’œil nu pour la vie courante.
            </>
          ),
        },
      ],
    },
  ],
  textualCollapse: [
    {
      title1: <>Horaires d’Ouverture / RDV / Tarifs</>,
      title2: <>Garanties et agrégations</>,
      Content: [
        {
          titlefaq1: <>Quels sont vos jours et vos horaires d’ouverture ?</>,
          content1: (
            <>
              Notre Institut est ouvert sur rendez-vous du mercredi au samedi de
              10h à 18h30.
            </>
          ),
          titlefaq2: <>Votre salon est-il agréé par la sécurité sociale ?</>,
          content2: (
            <>
              Oui, le salon L'ARTISAN DU SUBLIME est agréé par l’assurance
              maladie pour le remboursement de vos prothèses capillaires (cas de
              chimiothérapie, pelade ou alopécie) en présence d’une ordonnance
              adressée par votre médecin traitant, dermatologue ou oncologue.
            </>
          ),
        },

        {
          titlefaq1: <> Peut-on venir chez vous sans rendez-vous ? </>,
          content1: (
            <>
              Actuellement ce n’est pas possible compte tenu des restrictions
              sanitaires. <br /> Une fois les restrictions levées, vous pourrez
              venir nous rencontrer sans RDV tous les premiers samedis de chaque
              mois.
            </>
          ),
          titlefaq2: (
            <>
              A quelle hauteur la sécurité sociale prend-elle en charge le
              remboursement de ma Lace ?
            </>
          ),
          content2: (
            <>
              L'ARTISAN DU SUBLIME est agréé par la sécurité sociale pour le
              remboursement de vos prothèses capillaires. <br /> Depuis la loi
              du 03 Avril 2019, la sécurité sociale a un nouveau régime de
              remboursement des prothèses capillaires. <br /> En effet les
              prothèses capillaires sont classées par classes. Il existe la
              Classe I et Classe II. <br /> La Classe I correspond aux laces
              dont le prix de vente n’excède pas 350€. Les laces de la Classe I
              sont prises en charge à 100% par la sécurité sociale. <br /> La
              Classe II correspond aux laces dont le prix est compris entre 350€
              et 700€. Les laces de la classe II sont prises en charge à hauteur
              de 250€ par la sécurité sociale. <br /> Le reste de la prise en
              charge dépend de votre mutuelle.
            </>
          ),
        },
        {
          titlefaq1: <>Quels sont vos tarifs ?</>,
          content1: (
            <>
              Nous proposons 5 gammes de prix afin de nous adapter à chaque
              besoin et chaque budget. <br /> Les tarifs de nos Laces commencent
              à partir de 350€ pour la gamme HARMONY et à partir de 799€ pour
              notre gamme SUBLIME. Découvrez ici Nos COFFRETS <br /> N’hésitez
              pas à nous contacter pour un devis ou un essayage gratuit et sans
              obligation d’achat.
            </>
          ),
          titlefaq2: (
            <>Vos Prothèses Capillaires / Lace Wigs sont-elles garanties ?</>
          ),
          content2: (
            <>
              Toutes nos Laces sont garanties durant 3 mois à compter de la date
              d’achat et 2 ans pour les Coffrets SUBLIME.
            </>
          ),
        },
      ],
    },
    {
      title1: <>Réparation / entretien</>,
      title2: <>Paiements</>,
      Content: [
        {
          titlefaq1: (
            <>
              Est-il possible de faire réparer ma Prothèse Capillaire / Lace Wig
              chez vous ? AZ
            </>
          ),
          content1: (
            <>
              Oui, nous réalisons la réparation de vos Prothèses Capillaires /
              Lace Wigs L'ARTISAN DU SUBLIME. Les petites réparations sont
              réalisées directement dans notre salon. Les autres réparations qui
              nécessitent plus de travail sont envoyées dans nos ateliers.
            </>
          ),
          titlefaq2: <>Est-il possible de payer en plusieurs fois ?</>,
          content2: (
            <>
              Oui, vous avez la possibilité de régler jusqu’à 4 fois sans frais
              par carte bleue en Institut et sur notre institut en ligne. <br />{' '}
              Pour passer une commande vous devez régler 50% de la somme, et le
              reste à la réception de la commande.
            </>
          ),
        },

        {
          titlefaq1: (
            <>Comment entretenir ma Prothèse Capillaire / Lace Wig ?</>
          ),
          content1: (
            <>
              Pour l’entretien de vos Laces des fiches explicatives sont mises à
              votre disposition au salon et sur notre site internet ou encore
              sur simple demande par mail. <br /> Nous vous conseillons de
              procéder au nettoyage de vos Laces 1 à 2 fois par mois. <br />{' '}
              Nous proposons aussi des prestations de nettoyage et de remise en
              forme pour vos Laces.
            </>
          ),
          titlefaq2: <>Quels moyens de paiement acceptez-vous ?</>,
          content2: (
            <>
              Nous acceptons tous les paiements (CB, espèces, chèque, virement,
              PayPal, mandat postalCode.. ). <br /> Pour les paiements en
              plusieurs fois (jusqu’à 3 fois sans frais) nous demandons un
              acompte de 50% sur le total de la somme avec la possibilité de
              payer la somme restante par chèque(s).
            </>
          ),
        },
      ],
    },

    {
      title1: <>Expédition et commande en ligne</>,
      title2: <>Méthodes de poses</>,
      Content: [
        {
          titlefaq1: <>Peut-on commander en ligne ?</>,
          content1: (
            <>
              Oui, absolument, vous pouvez passer votre commande sur notre
              institut en ligne.
            </>
          ),
          titlefaq2: (
            <>
              Quelles-sont les méthodes de poses proposées par L'ARTISAN DU
              SUBLIME ?
            </>
          ),
          content2: (
            <>
              L'ARTISAN DU SUBLIME vous propose 8 méthodes de pose selon vos
              besoins et votre mode de vie. <br /> Nous pouvons vous conseiller
              afin de vous aider à choisir. (voir fiche récapitulative) <br /> -
              Sans colle <br /> - Avec Gel à base d’eau: tenue une journée{' '}
              <br /> - Avec des bandes adhésives : tenue entre une à deux
              semaines <br /> - Colle médicale <br /> - Colle forte : tenue 4 à
              6 semaines <br />- Lace Bande <br /> - Pose cousue : tenue 4 à 6
              semaines
            </>
          ),
        },

        {
          titlefaq1: (
            <>Quel est le délai d’attente pour une commande passée en ligne ?</>
          ),
          content1: (
            <>
              Comme pour les commandes passées en salon. <br /> Commandes sur
              mesure = 8 semaines. <br /> Commandes personnalisées = 3 semaines.{' '}
              <br /> Les modèles en stock sont expédiés sous 24 à 72h (jours
              ouvrables) en fonction des personnalisations souhaitées.
            </>
          ),
        },
        {
          titlefaq1: <>Ma Lace peut-elle être expédiée ?</>,
          content1: (
            <>
              Votre Lace peut être expédiée sur toute la France et en Europe.{' '}
              <br /> Les frais d’expédition s’élèvent à 8.90 pour la France et
              13.90 pour l’Europe. <br /> Nous expédions aussi nos Laces
              à l’international via UPS, dans ce cas le prix de l’expédition
              dépend du poids et de la destination du colis.
            </>
          ),
        },
      ],
    },
  ],
};

export default data;
