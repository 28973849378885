import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import HTMLReactParser from "html-react-parser";

const Intro = ({data}) => {
  return (
    <>
      <div className="introContent">
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <div className="textIntro">
              {HTMLReactParser(`${data.leftDescription}`)}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <div className="textIntro">
              {HTMLReactParser(`${data.rigthDescription}`)}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Intro;
