import { Row, Col, Radio, Form, Skeleton } from 'antd';
import { getFileUrl } from '../../../../utils';

import '../../style.less';
import './style.less';

const LivraisonPaiement = ({ rules = {}, shProviders }) => {
  const showItem = (item) => {
    const isFree = !item.price || item.price === null || item.price === 0;

    return (
      <Radio className="transporter" value={item.id}>
        <Row className="livraison__choice">
          <Col sm={5} xs={5}>
            <span for="fedex">{item.name}</span>
          </Col>

          <Col sm={4} xs={4}>
            {!isFree ? `+ ${item.price}€` : 'Gratuit'}
          </Col>

          <Col sm={10} xs={10}>
            {!isFree && 'Frais additionnels'}
          </Col>

          <Col sm={5} xs={5} className="livraison__detail detail__logo">
            {item.logo && (
              <img src={getFileUrl(item.logo)} alt={(item.logo || {}).name} />
            )}
          </Col>
        </Row>
      </Radio>
    );
  };

  const isLoading = !shProviders;

  let defaultValue;

  if (shProviders && shProviders.length > 0) defaultValue = shProviders[0].id;

  return (
    <div id="livraison-paiement" className="livraison-paiement">
      <div className="checkout-section">
        <h2 className="section__title">Méthode de livraison</h2>
        <p className="section__subtitle">
          Veuillez choisir votre mode de livraison.
        </p>
        {/* <p className="section__subtitle">
          Veuillez choisir votre mode de livraison. ou contactez-nous au <a href="tel:+330983662422">09 83 66 24 22</a> si vous hésitez dans le choix de la méthode de livraison qui conviendrait à vos besoins.
        </p> */}

        {isLoading ? (
          <Skeleton.Button active={true} size="default" shape="square" />
        ) : (
          <Form.Item
            name={['shipping', 'provider']}
            // getValueFromEvent={onProviderChange}
          >
            <Radio.Group defaultValue={defaultValue} style={{ width: '100%' }}>
              {shProviders.map((item) => showItem(item))}
            </Radio.Group>
          </Form.Item>
        )}
      </div>
    </div>
  );
};

export default LivraisonPaiement;
