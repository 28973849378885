import React from 'react';
// 56232

const Clickrdv = () => {

    var style = document.createElement('style');
    style.textContent =
      'body {' +
      '  width:100%;'+
      '  background-color: some-color;' +
      '  background-image: some-image;' +
      '}' +
      'group-name {'+
      'color : red;'+
      '}'
    ;

    return (

        <iframe 
            title='clickrdvJs'
            src="https://www.clicrdv.com/soyez-sublime?popin=1&nologo=1&nofooter=1styles=transparent&colors[page_bg]=f7f3ea&colors[content_text]=000&colors[default_button_bg]=4c282e&colors[default_button_text]=fff&colors[main_bg]=4c282e&styles=transparent" id="myiFrame"
            style={{ "width": "100%", "height": "900px", "border": " 0", "color": "red" }}>
            <p>Your browser doesn't support iframes.
                To book an appointment, please follow this link :
                <a href="https://www.clicrdv.com/soyez-sublime">https://www.clicrdv.com/soyez-sublime</a>
            </p>
        </iframe>
    )
}

export default Clickrdv;
