import { Row, Col } from 'antd';
import Img from 'react-cool-img';
import logo from '../../assets/images/confiance/logo.png';
import HTMLReactParser from "html-react-parser";
import { getFileUrl } from '../../utils';

const EventDetailCard = (props) => {

    const {activeEvent} = props

    return (
        <div className="evenement">
            <Row>
                <Col sm={18} xs={18}>
                    { HTMLReactParser(`${activeEvent.description}`).props.children.length >= 70 ?
                        <h1 className="event-title">{HTMLReactParser(`${activeEvent.description}`).props.children.slice(0,70)}..</h1> :
                        <h1 className="event-title">{HTMLReactParser(`${activeEvent.description}`)}</h1>
                    }
                </Col>

                {/* {date && <Col sm={6} xs={6} className="dateEvents">
                    <p>{date}</p>
                </Col>} */}
            </Row>
                    
            <Row>
                <Col md={14} sm={24} xs={24}>
                        <Row className="doubleImage">
                            <Col sm={10} xs={10}>
                                <Img className="logoEvents" src={logo} alt="" />
                            </Col>
                            <Col sm={14} xs={14}>
                                <Img className="imagelog" src={getFileUrl(activeEvent.desktop_media)} alt="" />
                            </Col>
                        </Row>
                </Col>


                    <Col md={10} sm={24} xs={24} className="right-column-events">
                        <Img className="imgEvents" src={getFileUrl(activeEvent.mobile_media)} alt="" />
                    </Col>
                    
            </Row>
        </div>
    )
}

export default EventDetailCard;
