import { Row, Col, Form, Input, Button } from 'antd';

import { resetpassword } from '../../../api/profile';

// POST Request to /auth/forgot-password
// Receive an E-Mail with a code
// Post Request to /auth/change-password with the new password and the code

const ChangePassword = (props) => {

  const { token } = props;

  const onFinish = async (values) => {
    

    try {
      await resetpassword({
        code: token,
        password: values.password,
        passwordConfirmation: values.confirmPassword,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div id="changePassword" className="changePassword">
      <h2 className="details__title">Changement de mot de passe</h2>

      <Form
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col lg={8} md={12} sm={24} xs={24} className="">
            <Form.Item
              name="password"
              label="Mot de passe"
              className="form__abel"
              rules={[
                {
                  required: true,
                  message: 'Veuillez renseigner ce champ !',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} className="">
            <Form.Item
              name="confirmPassword"
              label="Confirmer le mot de passe"
              className="form__abel"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <div className="switch-password__submit">
          <Button className="details__button">APPLIQUER</Button>
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
