import React, { Fragment } from 'react';
import useVitrinFetcher2 from '../../../stores/vitrin/hooks2';
import { isValidValue } from '../../../utils';

const ProductFeatures = ({
  data = {},
  value,
  onCHange: handleSetCurrentVariation,
}) => {
  const { variations, attributJSON } = data;

  let filters = useVitrinFetcher2({ endpoints: 'filters', name: 'filters' });

  const [state, setState] = React.useState({});

  const { selections, selected } = state;

  React.useEffect(() => {
    let val =
      value ||
      variations.find((item) => isValidValue(item) && item.isDefault) ||
      variations[0];

    if (val && filters) setDefaultSelected(val);
    // 
  }, [filters]);

  React.useEffect(() => {
    if (selected)
      handleSetCurrentVariation && handleSetCurrentVariation(selected);
    // 
  }, [state.selected]);

  if (!variations || !filters) return '';

  const setDefaultSelected = (defaultVal) => {
    if (filters) {
      const selections = defaultVal.content;
      if (selections.slugs) delete selections.slugs;

      setState((state) => ({ ...state, selected: defaultVal, selections }));
    }
  };

  const getSelectedValue = async (selects) => {
    let possibilities = [...variations];
    const keys = Object.keys(selects);

    for (let index = 0; index < keys.length; index++) {
      const element = selects[keys[index]];

      if (element)
        possibilities = possibilities.filter((item) =>
          (item.slugs || []).includes(element.slug)
        );
    }

    const selected =
      possibilities.length === 1 ? possibilities[0] : state.selected;
    return selected;
  };

  const onSelectAttribut = async (filterName, value) => {
    if (!filterName) return
    const val = {
      ...selections,
      [filterName]: value,
    };

    const selected = await getSelectedValue(val);

    setState((state) => ({ ...state, selections: val, selected }));
  };

  const selectedClassName = (filterName, item) => {
    if (!selected || !filterName) return '';

    if (selected.content[filterName]?.slug === item?.slug) return ' selected';
    return '';
  };

  const displayColors = ({ id, name }, values) => {
    return (
      <div key={id} className="product-section-colors">
        {values.map((item, id) => {
          return (
            <li key={id} className="product-section-color-item">
              <button
                className={selectedClassName(name, item)}
                onClick={() => onSelectAttribut(name, item)}
                style={{ backgroundColor: `${item.colorCode}` }}
              ></button>
            </li>
          );
        })}
      </div>
    );
  };

  const displayAttributItem = (filterItem, item) => {
    return (
      <li key={item.id}>
        <button
          onClick={() => onSelectAttribut(filterItem.name, item)}
          className={
            'product-section-detail-item' +
            selectedClassName(filterItem.name, item)
          }
        > 
          {item.label?.split(" - ").pop()}
        </button>
      </li>
    );
  };

  const displayAttributs = (values, filterItem) => {
    const { isColor } = filterItem;
    if (!values) return <EmptyCom key={filterItem.id} />;
    if (isColor ) return displayColors(filterItem, values);

    return (
      <div className="product-section-detail-list">
        {values.map((item) => displayAttributItem(filterItem, item))}
      </div>
    );
  };

  const displayVariation = (filterItem) => {
    if (!isValidValue(filterItem) || !attributJSON)
      return <EmptyCom key={filterItem.id} />;

    const values = attributJSON[filterItem.name || ''];
    if (!values) return <EmptyCom key={filterItem.id} />;

    return (
      <>
        <h4 className="product-section-detail-title">{filterItem.title}</h4>
        {displayAttributs(values, filterItem)}
      </>
    );
  };
  return <div>{filters.map((item) => displayVariation(item))}</div>;
};

export default ProductFeatures;

const EmptyCom = ({ key }) => <Fragment key={key}></Fragment>;
