import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import BannerSection from '../home/HomeSections/BannerSection';
import WheelIcon from '../../assets/images/wheelIcon.svg';
import HeaderH1TextNormal from '../home/HeaderText/HeaderH1TextNormal';
import HeaderH1TextItalic from '../home/HeaderText/HeaderH1TextItalic';
import { getFileUrl } from '../../utils';
import { vitrinSelector } from '../../stores/vitrin/selector';
import * as actions from '../../stores/vitrin/actions';
import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import SectionWrapper from '../../components/SectionWrapper';
import VideoPlayer from '../../components/VideoPlayer';
const History = ({ location }) => {
  //

  const data = useVitrinFetcher2({
    endpoints: 'notre-histoire',
    name: NAME,
  });

  useHashScrooler({ location, data });

  if (!data) return <Spiner />;

  return data && (
    <div id="history" className="container">
      <div className="banner-section">
        <BannerSection
          normalHeaderText={data.banniere.titre_normal}
          italicHeadertext={data.banniere.titre_italique}
          banner={[data.banniere]}
        />
      </div>

      <div className="artisan-sublime-chiffre">
        <SectionWrapper hashId={data.section_ads_en_quelques_chiffres?.hashId}>
          <HeaderH1TextNormal
            text={data.section_ads_en_quelques_chiffres?.titre_normal}
            isCenter={false}
            shouldBringUp={false}
          />
          <HeaderH1TextItalic
            text={data.section_ads_en_quelques_chiffres?.titre_italique}
            isCenter={false}
            shouldBringUp={true}
            isRedColor={true}
          />
        </SectionWrapper>

        <div className="img-with-text">
          <div className="section-content-wrapper align-text-at-center">
            <img
              className="section-content-img"
              alt=""
              src={getFileUrl(
                data.section_ads_en_quelques_chiffres?.desktop_media
              )}
            />
            <div className="section-content-main align-text-at-center">
              <div className="section-text-content-wrapper">
                <ul className="section-list-items">
                  <li
                    dangerouslySetInnerHTML={{
                      __html: data.section_ads_en_quelques_chiffres?.description,
                    }}
                  ></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <VideoPlayer url={data.external_video_link} />
        {data.section_image_texte?.map((item, index) =>
          index % 2 === 0 ? (
            <SectionWrapper
              hashId={item.hashId}
              key={index}
              className="accompagnement-with-text"
            >
              <div className="section-content-wrapper align-text-at-center">
                <div className="accompagnement-content-main">
                  <div className="accompagnement-text-content-wrapper">
                    <h3 className="subsection-header">{item.title}</h3>
                    <p
                      className="subsection-text"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></p>
                  </div>
                </div>
                <img
                  className="accompagnement-content-img"
                  alt=""
                  src={getFileUrl(item.desktop_media)}
                />
              </div>
            </SectionWrapper>
          ) : (
            <SectionWrapper
              hashId={data.section_image_texte?.[1]?.hashId}
              className="depuis-treize-ans-section"
            >
              <div className="depuis-13-ans-content-wrapper align-text-at-center">
                <div className="section-content-main align-text-at-center">
                  <div className="section-text-content-wrapper">
                    <h3 className="subsection-header">
                      {data.section_image_texte?.[1]?.title}
                    </h3>
                    <p
                      className="subsection-text"
                      dangerouslySetInnerHTML={{
                        __html: data.section_image_texte?.[1]?.description,
                      }}
                    ></p>
                  </div>
                </div>
                <img
                  className="depuis-treize-ans-content-img"
                  alt=""
                  src={getFileUrl(data.section_image_texte?.[1]?.desktop_media)}
                />
              </div>
            </SectionWrapper>
          )
        )}

        <Row className="advantage-services-garantie">
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            className="section-item-card yellow-bg-color"
          >
            <SectionWrapper
              hashId={data.section_liste_des_cartes?.[0]?.hashId}
              className="header-section"
            >
              <h3 className="header-section-text">
                {data.section_liste_des_cartes?.[0]?.title}
              </h3>
              <img
                className="header-wheel-img"
                src={WheelIcon}
                alt="wheel icon"
              />
            </SectionWrapper>
            <div
              className="content-section"
              dangerouslySetInnerHTML={{
                __html: data.section_liste_des_cartes?.[0]?.description,
              }}
            ></div>
          </Col>

          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            className="section-item-card gray-1-bg-color"
          >
            <SectionWrapper
              hashId={data.section_liste_des_cartes?.[1]?.hashId}
              className="header-section"
            >
              <h3 className="header-section-text">
                {data.section_liste_des_cartes?.[1]?.title}
              </h3>
              <img
                className="header-wheel-img"
                src={WheelIcon}
                alt="wheel icon"
              />
            </SectionWrapper>
            <div
              className="content-section"
              dangerouslySetInnerHTML={{
                __html: data.section_liste_des_cartes?.[1]?.description,
              }}
            ></div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            className="section-item-card gray-2-bg-color positionColum"
          >
            <SectionWrapper
              hashId={data.section_liste_des_cartes?.[2]?.hashId}
              className="header-section"
            >
              <h3 className="header-section-text">
                {data.section_liste_des_cartes?.[2]?.title}
              </h3>

              <img
                className="header-wheel-img"
                src={WheelIcon}
                alt="wheel icon"
              />
            </SectionWrapper>
            <div
              className="content-section"
              dangerouslySetInnerHTML={{
                __html: data.section_liste_des_cartes?.[2]?.description,
              }}
            ></div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const NAME = 'history';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(History);
