import React,{ Fragment }  from 'react';
import './style.less';
import { Row, Col } from 'antd';
import Img from 'react-cool-img';
import { getFileUrl } from '../../utils';
import SectionWrapper from '../../components/SectionWrapper';

const ClientsConfiances = ({ data }) => {
  return (
    <>
      <Row gutter={16}>
        {data[0].sectionImageByClient &&
          data[0].sectionImageByClient.map((item, index) => (
            <Fragment key={index}>
              <Col xs={12} sm={6} md={6} lg={6}>
                <SectionWrapper hashId={item.hashId}>
                  <Img
                    src={getFileUrl(item.mobile_media)}
                    alt=""
                    width="100%"
                  />
                  <div className="img-text">
                    <h1 className="header-h1-text-italic center-text bring-upfront italicSize">
                      Avant
                    </h1>
                  </div>
                </SectionWrapper>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Img src={getFileUrl(item.desktop_media)} alt="" width="100%" />
                <div className="img-text">
                  <h1 className="header-h1-text-italic center-text bring-upfront italicSize">
                    Après
                  </h1>
                </div>
              </Col>
            </Fragment>
          ))}
      </Row>
    </>
  );
};

export default ClientsConfiances;
