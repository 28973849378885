import './style.less';

import SublimeSignatureImg from '../../assets/images/hairmatch/sublimeSignatureInGoldColor.png';

import BannerSection from '../home/HomeSections/BannerSection';
import SublimeSignature from '../home/SublimeSignature/SublimeSignature';
import VideoPlayer from '../../components/VideoPlayer';

import { MOBILE_SIZE } from '../../config';
import useWindowSize from '../../hooks/useWindowSize';

import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import HTMLReactParser from 'html-react-parser';
import * as actions from '../../stores/vitrin/actions';
import { vitrinSelector } from '../../stores/vitrin/selector';
import { getFileUrl } from '../../utils';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import useHashScrooler from '../../hooks/useHashScrooler';
import { Fragment } from 'react';

const Hairmatch = ({ location }) => {
  const data = useVitrinFetcher2({
    endpoints: 'innovation-hairlinematch',
    name: NAME,
  });

  useHashScrooler({ data, location });

  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  const bannerSection = (dat) => {
    return (
      <>
        <div className="banner-section">
          <BannerSection
            normalHeaderText={dat.banniere.banniere.titre_normal}
            italicHeadertext={dat.banniere.banniere.titre_italique}
            banner={[dat.banniere.banniere]}
          />
        </div>
        <div className="hairline-match-section">
          <div className="text-chunk-one">
            {HTMLReactParser(`${dat.banniere.descriptionLeft}`)}
          </div>
          <div className="text-chunk-two">
            {HTMLReactParser(`${dat.banniere.descriptionRight}`)}
          </div>
          <div className="sublime-signature-wrapper">
            <SublimeSignature
              signature_img={SublimeSignatureImg}
              widthValue={isMobile ? 85 : 229}
              heightValue={isMobile ? 85 : 229}
            />
          </div>
        </div>
      </>
    );
  };

  const columnsSection = (d) => {
    return (
      <>
        <div className="two-column-layout-wrapper">
          <div className="row-of-two-columns-layout">
            <div className="two-columns-layout">
              {data.sectionContentElement.map((item,idx)=> {
                  return <Fragment key={idx}>
                    <div className="first-column">
                      <div>
                        <div className="header-section-text">
                          {item.title}
                        </div>
                        {item.description && <div className="section-content-text">
                          {HTMLReactParser(`${item.description}`)}
                        </div>}
                      </div>
                    </div>
                    {item.desktop_media && 
                      <div className='first-column'>
                        <div className="image-wrapper">
                          {isMobile ? 
                            <img src={getFileUrl(item.mobile_media || item.desktop_media)} alt="" /> : 
                            <img src={getFileUrl(item.desktop_media)} alt=""/>}
                        </div>
                      </div>}
                    {item.external_video_link && 
                      <div className='first-column'>
                          <div className="youtube-wrapper-section">
                            <VideoPlayer url={  item.external_video_link || 'https://www.youtube.com/embed/G68qhTWfaJ4' }/>
                          </div>
                      </div>}
                  </Fragment>
                })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const bottomSection = (dat) => {
    return (
      <div className="hailine-match-section">
        <span className="hairline-match-text">Hairlinematch</span>
        <div className="ultra-lace-block">
          <div className="ultra-lace-text">
            {HTMLReactParser(`${dat.sectionCarteEndPage.description}`)}
          </div>
          <img className="sublime-logo" src={getFileUrl(dat.sectionCarteEndPage.logo)} alt=""
          />
        </div>
      </div>
    );
  };

  if (!data) return <Spiner />;

  return (
    <div id="hairmatch" className="container">
      {bannerSection(data)}
      {columnsSection(data)}
      {bottomSection(data)}
    </div>
  );
};

const NAME = 'innovation-hairlinematch';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Hairmatch);
