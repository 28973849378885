import { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, notification } from 'antd';

import { useHistory } from 'react-router-dom';

import { forgotpassword, resetpassword } from '../../../api/profile';
import './newpassword.less';

// POST Request to /auth/forgot-password
// Receive an E-Mail with a code
// Post Request to /auth/change-password with the new password and the code

const openNotification = (text) => {
  const args = {
    message: '',
    description: text,
    duration: 5,
  };
  notification.open(args);
};

const NewPassword = (props) => {
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [successEmailPasswordSent, setSuccessEmailPasswordSent] =
    useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    extractParamsUrl(window.location.href);
  }, [props]);

  const extractParamsUrl = (chaineGET) => {
    chaineGET = chaineGET.split('&');
    var result = {};

    chaineGET.forEach(function (el) {
      var param = el.split('=');
      param[0] = param[0].replace('?', '');
      result['token'] = param[1];
    });
    setToken(result.token);
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      let res = await resetpassword({
        code: token,
        password: values.password,
        passwordConfirmation: values.confirmPassword,
      });

      if (res) {
        openNotification('Modificattion effectuée avec succès !');

        history.push('/');
      } else {
        openNotification('Une erreur est survenue !');
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onFinishEmail = async (values) => {
    setIsLoading(true);

    let res = await forgotpassword({
      email: values.email,
    });

    if (!res) {
      return setResetErrorMessage('Une erreur est survenue !');
    }

    if (res.error) return performResetOrderErrorNotification(res.error);

    if (res && res.ok === true) {
      openNotification('Demande effectuée avec succès !');
      setSuccessEmailPasswordSent(true);
    } else {
      return setResetErrorMessage('Une erreur est survenue !');
    }
  };

  const performResetOrderErrorNotification = (error) => {
    if (!error) return;
    const { message } = error;

    if (!message || (message && !message[0]))
      return setResetErrorMessage('Une erreur est survenue !');

    if (message[0].messages[0].id === 'Auth.form.error.user.not-exist')
      return setResetErrorMessage("Cet e-mail n'existe pas.");

    setResetErrorMessage('Une erreur est survenue !');
  };

  const setResetErrorMessage = (message) => {
    form.setFields([{ name: 'email', errors: [message] }]);
    setIsLoading(false);
  };

  const onReset = () => {
    history.push('/');
  };

  const onFinishFailed = (errorInfo) => {};

  const displayResetForm = () => (
    <Form
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        name="password"
        label="Veuillez entrer votre Mot de passe"
        className="form__abel"
        rules={[
          {
            required: true,
            message: 'Veuillez renseigner ce champ !',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirmer le mot de passe"
        className="form__abel reset-order"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Merci de confirmer votre mot de passe.',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error(
                  'Les deux mots de passe renseignés ne concordent pas.'
                )
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          size="large"
          className="form-button"
        >
          APPLIQUER
        </Button>
      </Form.Item>
    </Form>
  );

  const displayREsetOrderForm = () => (
    <Form
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinishEmail}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Veuillez renseigner votre Email."
        name="email"
        className="form__abel"
        rules={[
          {
            required: true,
            message: 'Veuillez renseigner ce champ!',
          },
        ]}
      >
        <Input type="email" placeholder="Email" />
      </Form.Item>
      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          size="large"
          className="form-button"
        >
          RECEVOIR
        </Button>
        <Button
          htmlType="button"
          style={{ margin: '0 8px' }}
          onClick={onReset}
          size="large"
          className="form-button"
        >
          ANNULER
        </Button>
      </Form.Item>
    </Form>
  );

  const displayResponse = () => (
    <div className="suc cessResetPassworMsg">
      <p className="reset-order-message">
        Un Email vient de vous être envoyé afin que vous réinitialisez votre mot
        de passe
      </p>
      <Button
        htmlType="button"
        onClick={onReset}
        className="form-button"
        size="large"
      >
        RETOUR
      </Button>
    </div>
  );

  return (
    <div id="newPassword">
      <div className="content-form">
        <p className="content-form-title">Modifier votre mot de passe</p>

        <Row>
          <Col lg={8} md={12} sm={24} xs={24} className="colResetDiv">
            {/* <div
              className="resetDiv"
              style={{ backgroundImage: `url("${BASE_URL + image}")` }}
            /> */}
          </Col>

          <Col lg={8} md={12} sm={24} xs={24} className="myInput">
            {token
              ? displayResetForm()
              : !successEmailPasswordSent
              ? displayREsetOrderForm()
              : displayResponse()}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewPassword;
