import './style.less';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import HTMLReactParser from 'html-react-parser';
import SectionWrapper from '../../../components/SectionWrapper';

const { Panel } = Collapse;

const CollapseTwo = ({ item }) => {
  //

  return (
    <SectionWrapper
      hashId={item.hashId}
      key={item._id}
      className="space-collapse"
    >
      <p className="collapse-title-h1"> {item.normal_title}</p>
      <Collapse
            className="font-collapse-1"
            accordion
            expandIconPosition="right"
            // defaultActiveKey={["0"]}
            expandIcon={({ isActive }) => (
              <DownOutlined rotate={isActive ? -180 : 0} />
            )}
          >
            {item.question_accordeon &&
              item.question_accordeon.map((question) => {
                return (
                  <Panel header={question.tilte} key={question._id}>
                  <div>{HTMLReactParser(`${question.description}`)}</div>
                </Panel>
                );
              })}
          </Collapse>
    </SectionWrapper>
  );
};
{/* <Collapse className="header-h1-text-normal margRight-title" expandIconPosition="right" header=>
                <Panel header={item.tilte} key={item._id}>
                  <p>{HTMLReactParser(`${item.description}`)}</p>
                </Panel>
          </Collapse> */}

export default CollapseTwo;
