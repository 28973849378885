import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';

import './style.less';
import iconFavs from '../../assets/icons/favs.png';
import iconBoard from '../../assets/icons/board.png';
import iconDetails from '../../assets/icons/details.png';

import Board from './board/board';
import Favs from './favs/favs';
import UserDetails from './userDetails/userDetails';
import { userDataSelector } from '../../stores/auth/selector';
import { logoutAction } from '../../stores/auth/actions';

const UserProfile = () => {
  //

  const dispatch = useDispatch();
  const [navState, setNavState] = useState('board');
  const [navToggle, setNavToggle] = useState(false);

  const user = useSelector(userDataSelector);

  const history = useHistory();

  const logOut = (e) => {
    dispatch(logoutAction());
    history.push('/');
  };

  const switchPassword = () => {
    setNavState('details');
  };

  const toggleNavbar = () => {
    setNavToggle(!navToggle);

    if (navToggle === false) {
      document.getElementById('user-profile__nav').style.height = '240px';
    } else {
      document.getElementById('user-profile__nav').style.height = '0';
    }
  };

  const displayContent = () => {
    if (navState === 'board')
      return (
        <Board
          logOut={logOut}
          switchPassword={switchPassword}
          toggleNavbar={toggleNavbar}
          navToggle={navToggle}
        />
      );

    if (navState === 'favs')
      return (
        <Favs
          logOut={logOut}
          switchPassword={switchPassword}
          toggleNavbar={toggleNavbar}
          navToggle={navToggle}
        />
      );

    if (navState === 'details')
      return (
        <UserDetails
          logOut={logOut}
          userData={user}
          navToggle={navToggle}
          toggleNavbar={toggleNavbar}
        />
      );

    return '';
  };

  return (
    <div id="user-profile" className="container user-profile">
      <Row>
        <Col
          md={6}
          sm={7}
          xs={24}
          id="user-profile__nav"
          className={navToggle ? 'nav__border-on' : 'nav__border-off'}
        >
          <ul className="user-profile__list">
            <li className="user-profile__items user-profile__name">
              <span className="hello">
                Hello, <br />
              </span>
              {user && user.username}
            </li>

            <li className="user-profile__items">
              <img src={iconBoard} alt="board.png" />
              <button
                onClick={() => {
                  setNavState('board');
                  setNavToggle(!navToggle);
                  document.getElementById('user-profile__nav').style.height =
                    '0';
                }}
                className={
                  navState === 'board'
                    ? 'user-profile__switch switch--active'
                    : 'user-profile__switch'
                }
              >
                Tableau de bord
              </button>
            </li>

            <li className="user-profile__items">
              <img src={iconFavs} alt="favs.png" />
              <button
                onClick={() => {
                  setNavState('favs');
                  setNavToggle(!navToggle);
                  document.getElementById('user-profile__nav').style.height =
                    '0';
                }}
                className={
                  navState === 'favs'
                    ? 'user-profile__switch switch--active'
                    : 'user-profile__switch'
                }
              >
                Favoris
              </button>
            </li>

            <li className="user-profile__items">
              <img src={iconDetails} alt="details.png" />
              <button
                onClick={() => {
                  setNavState('details');
                  setNavToggle(!navToggle);
                  document.getElementById('user-profile__nav').style.height =
                    '0';
                }}
                className={
                  navState === 'details'
                    ? 'user-profile__switch switch--active'
                    : 'user-profile__switch'
                }
              >
                Détails du compte
              </button>
            </li>
          </ul>
        </Col>

        <Col md={18} sm={17}>
          {displayContent()}
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;
