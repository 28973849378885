import { Col, Row } from 'antd';
import { NavHashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.less';
import { CHECKOUT_LINK, CATEGORIES_LINK } from '../../Routes/links';
import { openModal } from '../../stores/app/actions';
import { isAuthSelector } from '../../stores/auth/selector';
import AdsButton from '../../pages/home/Button/AdsButton';
import { MODALPAGES } from '../../stores/app/reducer';

export default function Footer({ onClose, totalPrices }) {
  const dispatch = useDispatch();

  const isAuth = useSelector(isAuthSelector);
  const history = useHistory();

  const handlePlaceOrder = async (product) => {
    if (!isAuth)
      dispatch(
        openModal({
          type: MODALPAGES.SIGNIN,
          nextUrl: CHECKOUT_LINK,
        })
      );
    else {
      history.push(CHECKOUT_LINK);
      onClose && onClose();
    }
  };

  const handleClose = () => {
    history.push(CATEGORIES_LINK);
    onClose && onClose();
  };

  const expeditionFees =
    "Les frais d'expédition et les taxes sont calculés au moment du paiement.";

  return (
    <div className="cart__footer">
      <Row>
        <Col span={24}>
          <div className="cart__recap">
            <div className="recap__total-price">
              Sous-total:{' '}
              <span className="recap__total-price--price">
                &nbsp;
                {totalPrices.toFixed(2).replace('.', ',').replace(',00', '')} €
              </span>
            </div>

            <div className="recap__expedition">{expeditionFees}</div>
          </div>
        </Col>
      </Row>

      <Row className="recap__choice">
        <Col span={12} className="recap__column recap__column--left">
          <NavHashLink onClick={handleClose} to={`${CATEGORIES_LINK}#top`}>
            <button onClick={handleClose} className="recap__button-continue">
              POURSUIVRE MES ACHATS
            </button>
          </NavHashLink>
        </Col>

        <Col span={12} className="recap__column recap__column--right">
          <AdsButton
            onClick={handlePlaceOrder}
            className="recap__button-command"
            text="COMMANDER"
          />

          {/* </NavHashLink> */}
        </Col>
      </Row>
    </div>
  );
}
