import './style.less';
import { Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as actions from '../../stores/cart/cartActions';
import { getProductInfos, openCartNotification } from '../Cart/utils';
import { PAYMENT_LINK } from '../../Routes/links';

const QuantitySetting = ({ product, qte, setItemQte, variation }) => {
  //
  // Desturing product
  const { id } = product || {};

  const { stock, manageStock, isSoldOut } = getProductInfos(product, variation);
  const [inlineQte, setInlineQte] = React.useState(qte);
  const [loading, setLoading] = React.useState(false);
  const [displayQteSetter, setDisplayQteSetter] = React.useState(true);

  const location = useLocation();

  React.useEffect(() => {
    setInlineQte(qte);

    const { pathname } = location;
    if (pathname === PAYMENT_LINK) setDisplayQteSetter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [qte]);

  const submitQuantity = async (val) => {
    setLoading(true);
    const data = await setItemQte({ id, qte: val, variation });

    if (data) {
      const { name, image, sale, price } = product;
      let vsale = sale === null ? price : sale;

      if (data.error || data.message) {
        openCartNotification({
          name,
          image,
          price: vsale,
          error: data.error,
          errorMessage: data.message,
        });
      }

      setTimeout(() => setLoading(false), [100]);
    }
  };

  const onAddQty = async () => {
    // AUGMENTATION DE QUANTITE VIA BOUTON +
    const newQte = inlineQte + 1;
    await submitQuantity(newQte);
  };

  const onRemoveQty = () => {
    // DIMINUTION DE QUANTITE VIA BOUTON -
    const newQte = inlineQte - 1;
    submitQuantity(newQte);
  };

  const mustDisableMore = () => {
    if (!manageStock) return false;
    if (isSoldOut === true) return true;
    if (qte >= stock) return true;
  };

  const mustDisableLess = () => {
    if (inlineQte === 1) return true;
    if (!manageStock) return false;
    if (isSoldOut === true) return true;
    if (inlineQte === 1) return true;
  };

  const showQteSetter = () => (
    <div className="info-item--qty-handle">
      {loading && (
        <div className="spiner-wrapper">
          <Spin />
        </div>
      )}

      <button
        onClick={() => onRemoveQty()}
        className="qty--minus qty--element info-item--small"
        disabled={mustDisableLess()}
      >
        -
      </button>

      <span>
        <input
          type="number"
          value={inlineQte}
          min={1}
          readOnly={true}
          // onChange={onInputChanged}
          className="qty--number qty--element info-item--small"
        />
      </span>

      <button
        onClick={() => onAddQty()}
        className="qty--plus qty--element info-item--small"
        disabled={mustDisableMore()}
      >
        +
      </button>
    </div>
  );

  return (
    <div className="qty-container info-item--detail info-item--small">
      Quantités :{displayQteSetter ? showQteSetter() : ` ${inlineQte}`}
    </div>
  );
};

const Wrapper = connect(
  (state) => ({
    //  cart: cartSelector(state),
  }),

  (dispatch) => {
    return {
      setItemQte: (data) => dispatch(actions.setItemQte(data)),
    };
  }
);

export default Wrapper(QuantitySetting);
