import { combineReducers } from 'redux';
import shop from './shop/reducer';
import user from './user';
import app from './app/reducer';
import cartReducer from './cart/cartReducer';
import wishlistReducer from './wishList/reducer';
import order from './order/reducer';
import products from './product/reducers';
import vitrin from './vitrin/reducer';
import auth from './auth/reducer';

export default combineReducers({
  app,
  auth,
  shop,
  user,
  cart: cartReducer,
  wishlist: wishlistReducer,
  order,
  vitrin,
  products,
});
