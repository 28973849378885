import React from 'react';
import { Form, Input } from 'antd';

const SupplementInfo = ({ rules }) => {
  return (
    <div className="checkout-section">
      <h2 className="section__title">Informations complémentaires</h2>
      <p className="section__subtitle">
        Besoin d'autre chose ? Nous le réaliserons pour vous !
      </p>

      <Form.Item
        name="details"
        rules={rules.firstName}
        className="infos-form-input"
      >
        <Input.TextArea
          rows="4"
          className="infos-form-input"
          placeholder="Vous avez besoin d'un jour de livraison spécifique ? Vous envoyez un cadeau ?"
        />
      </Form.Item>
    </div>
  );
};

export default SupplementInfo;
