import React from 'react';
import { Button } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const StripePaymentField = ({
  submitOrder,
  order,
  isOnly = false,
  postOrderCallBack,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const tokenId = await performStripePayment();
    if (!tokenId) {
      setLoading(false);
      return;
    }

    let data = { ...order, method: 'stripe', token: tokenId };

    if (!submitOrder) return;
    
    const response = await submitOrder(data);

    postOrderCallBack && postOrderCallBack(response);

    setLoading(false);
  };

  const performStripePayment = async () => {
    const cardElement = elements.getElement(CardElement);
    const { billing } = order;

    const stripToken = await stripe.createToken(cardElement, {
      email: billing.email,
      country: billing.country,
      address_city: billing.city,
      first_name: billing.firstName,
      last_name: billing.lastName,
      phone: billing.phone,
      company: billing.email,
    });

    if (!stripToken || !stripToken.token) return;
    return stripToken.token.id;
  };

  const cardElementOPtionsOptions = {
    style: { width: '100%', base: { fontSize: '18px' } },
    hidePostalCode: true,
  };

  const additionnalClass = isOnly ? 'is-only' : '';

  return (
    <div className={`stripe-form ${additionnalClass}`}>
      <div className="content">
        <CardElement options={cardElementOPtionsOptions} />

        <Button
          className="stripe-button"
          block
          onClick={handleSubmit}
          type="primary"
          size="large"
          loading={loading}
        >
          PAYER
        </Button>
      </div>
    </div>
  );
};

export default StripePaymentField;
