import { key } from './reducer';
export const tokenSelector = (state) => state[key].token;
export const userDataSelector = (state) => state[key].user;
export const typeModalSelector = (state) => state[key].typeModal;
export const isAuthSelector = (state) =>
  state[key].isAuth !== undefined && state[key].isAuth === true;

export const nextedAuthSelector = (state) => ({
  mustAuth: state[key].mustAuth,
  nextAuthedLink: state[key].nextAuthedLink,
  typeModal: state[key].typeModal,
});
