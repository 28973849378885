import './style.less';

import BannerSection from './HomeSections/BannerSection';
import MosaicSection from './HomeSections/MosaicSection';
import ProductsSection from './HomeSections/ProductsSection';
import AdvantagesSection from './HomeSections/AdvantagesSection';
import PartenairesSection from './HomeSections/PartenairesSection';
import NousJoindreSection from './HomeSections/NousJoindreSection';
import NotreMissionSection from './HomeSections/NotreMissionSection';
import ArticlesDeBlogSection from './HomeSections/ArticlesDeBlogSection';
import PriseEnChargeMedicaleSection from './HomeSections/PriseEnChargeMedicaleSection';

import { connect } from 'react-redux';
import Spiner from '../../components/Spiner';
import * as actions from '../../stores/vitrin/actions';
import { vitrinSelector } from '../../stores/vitrin/selector';

import useHashScrooler from '../../hooks/useHashScrooler';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import SavoirFaire from './SavoirFaire';

const Home = ({ location }) => {
  //

  const data = useVitrinFetcher2({ endpoints: 'accueil', name: NAME });

  const firstBlogs = useVitrinFetcher2({
    endpoints: 'blogs?_limit=3&_sort=published_at:DESC',
    name: 'lastBlogs',
  });

  useHashScrooler({ location, data });

  if (!data) return <Spiner />;

  const {
    banniere = [],
    sectionAVosMesure,
    // sectionJeDecouvre,
    produits,
    sectionSavoirFaire,
    composantFiveAvantages,
    sectinPriseEnChargeMedicale,
    sectionConfiance,
    sectionNousJoindre,
    mosaique,
    mosaicHashId,
    sectionConfianceHashId,
    blogHashId,
  } = data;

  const showProducts = (items) => {
    if (!items || items === null || items.length === 0) return;
    return (
      <ProductsSection
        data={items}
        hashId={produits.hashId}
        title={produits.title}
      />
    );
  };

  return (
    <div id="home">
      <div className="banner-section container">
        {banniere.length > 0 && (
          <BannerSection
            normalHeaderText={banniere[0].titre_normal}
            italicHeadertext={banniere[0].titre_italique}
            banner={banniere}
          />
        )}
      </div>

      <div className="container">
        <NotreMissionSection section={sectionAVosMesure} />
        <MosaicSection data={mosaique} hashId={mosaicHashId} />

        {produits && produits !== null && showProducts(produits.products)}

        {sectionSavoirFaire && <SavoirFaire data={sectionSavoirFaire} />}

        <AdvantagesSection section={composantFiveAvantages} />
        <PriseEnChargeMedicaleSection section={sectinPriseEnChargeMedicale} />
        <PartenairesSection
          section={sectionConfiance}
          hashId={sectionConfianceHashId}
        />
        <NousJoindreSection section={sectionNousJoindre} />
        {firstBlogs && (
          <ArticlesDeBlogSection data={firstBlogs} hashId={blogHashId} />
        )}
      </div>
    </div>
  );
};

const NAME = 'accueil';
const Wrapper = connect(
  (state) => ({
    data: vitrinSelector(state, NAME),
  }),

  (dispatch) => {
    return {
      setData: (val) => dispatch(actions.setVitrinData(val)),
    };
  }
);

export default Wrapper(Home);
