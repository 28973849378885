import './style.less';
import React from 'react';
import * as links from '../Routes/links';
import Img from 'react-cool-img';

import { NavHashLink } from 'react-router-hash-link';

import auraLogo from '../assets/logos/appLogo.png';

const AppLogo = ({ width = '83px', height = 'auto', style }) => {
  //

  return (
    <div style={{ margin: 'auto', textAlign: 'center', ...style }}>
      <NavHashLink to={links.HOME_LINK + '#top'}>
        <Img
          width={width}
          height={height}
          src={auraLogo}
          alt="ADS"
          className="appLogo"
          style={{ cursor: 'pointer' }}
        />
      </NavHashLink>
    </div>
  );
};

export default AppLogo;
