import React from 'react';

import Content from './Content';

import { Drawer } from 'antd';

import './style.less';

const AppMenu = ({ children }) => {
  const [visible, setVisible] = React.useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const handleOpen = () => setVisible(true);

  return (
    <>
      {children && children(handleOpen)}

      <Drawer
        placement="left"
        closable={false}
        open={visible}
        onClose={handleClose}
        height="100vh"
        width="100%"
      >
        <Content onClose={handleClose} />
      </Drawer>
    </>
  );
};

export default AppMenu;
