import { useDispatch, useSelector } from 'react-redux';
import { setLinkedAuthAction } from './actions';
import { MODALPAGES } from './reducer';
import { isAuthSelector } from './selector';

const useAuth = () => {
  const dispatch = useDispatch();

  const isAuth = useSelector(isAuthSelector);

  const linkAuthUser = (link = '') => {
    if (!isAuth) {
      dispatch(setLinkedAuthAction(true, link, MODALPAGES.SIGNIN));
    }

    return true;
  };

  return { linkAuthUser, isAuth };
};

export default useAuth;
