import { connect } from 'react-redux';
import React, { useState } from 'react';
import { performAddToCard } from '../Cart/utils';
import { Row, Col, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../stores/cart/cartActions';
import * as wishlistActions from '../../stores/wishList/actions';
import { openModal } from '../../stores/app/actions';
import { isAuthSelector } from '../../stores/auth/selector';

import './productCard.less';

import {
  ShoppingOutlined,
  HeartOutlined,
  HeartFilled,
} from '@ant-design/icons';
import { getFileUrl } from '../../utils';
import { MODALPAGES } from '../../stores/app/reducer';

const HighlightingItem = ({
  item,
  addToCart,
  addToWishlist,
  height = 'auto',
}) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const { id, slug, image, name, price } = item;
  const isAuth = useSelector(isAuthSelector);

  const [toggleFav, setToggleFav] = useState(false);

  const wishArgs = {
    message: !toggleFav ? (
      <p
        className="notif__title"
        style={{ fontSize: '40px', marginBottom: '15px' }}
      >
        Ajout aux favoris
      </p>
    ) : (
      <p
        className="notif__title"
        style={{ fontSize: '40px', marginBottom: '15px' }}
      >
        Suppression des favoris
      </p>
    ),
    placement: 'bottomRight',
    className: 'notif',
    description: (
      <Row
        gutter={15}
        style={{
          fontFamily: 'Frunchy',
          fontSize: '30px',
          color: '#333333',
          lineHeight: '28px',
        }}
      >
        <Col span={7}>
          <div className="notif__image-wrapper">
            <img
              className="notif__image"
              style={{ width: '100%', height: '80px', objectFit: 'cover' }}
              src={getFileUrl(image)}
              alt={name}
            />
          </div>
        </Col>
        <Col span={17}>
          <p
            className="notif__details notif__name"
            style={{ marginBottom: '0px' }}
          >
            {name}
          </p>
          <p
            className="notif__details notif__price"
            style={{ marginBottom: '0px', fontWeight: '600' }}
          >
            {price}€
          </p>
          <p
            onClick={() => {
              history.push(`/favoris#top`);
            }}
            className="notif__details notif_panier"
            style={{
              marginBottom: '0px',
              textAlign: 'right',
              cursor: 'pointer',
            }}
          >
            Favoris
          </p>
        </Col>
      </Row>
    ),
  };

  const openWishlistNotification = () => {
    notification.open(wishArgs);
  };

  const handleAddToCArt = async (e) => {
    e.stopPropagation();
    await performAddToCard(addToCart, item, window);
  };

  const handleAddWishList = (e) => {
    e.stopPropagation();

    if (!isAuth) return dispatch(openModal({ type: MODALPAGES.SIGNIN }));

    const done = addToWishlist({ id, window });

    if (done) {
      openWishlistNotification();
      setToggleFav(!toggleFav);
    }
  };

  const onClick = () => {
    history.push(`/produits/${slug}`);
  };

  return (
    <div className="product-card highlightingItem" style={{ height }}>
      <div onClick={onClick} className="product-card-img-wrapper">
        <img src={getFileUrl(image, { small: true })} alt="" />
        <div className="product-card-icons">
          {!toggleFav ? (
            <HeartOutlined onClick={handleAddWishList} />
          ) : (
            <HeartFilled
              style={{ color: '#B22222' }}
              onClick={handleAddWishList}
            />
          )}
          <ShoppingOutlined onClick={handleAddToCArt} />
        </div>
      </div>

      <div className="product-card-detail">
        <p onClick={onClick} className="product-card-text">
          {name}
        </p>
        <p className="product-card-amount">{price}€</p>
      </div>
    </div>
  );
};

const Wrapper = connect(
  (state) => ({
    //  cart: cartSelector(state),
  }),

  (dispatch) => {
    return {
      addToCart: (data) => dispatch(actions.addItemToCart(data)),
      addToWishlist: (data) =>
        dispatch(wishlistActions.addItemToWishlist(data)),
    };
  }
);

export default Wrapper(HighlightingItem);
