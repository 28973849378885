import http from './http';

const localUserData = localStorage.getItem('user');
let user = localUserData && (localUserData !== 'undefined')  && localUserData !== null ? JSON.parse(localUserData) : undefined;

export async function forgotpassword(value) {
  return http
    .post('/auth/forgot-password', value)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return { error: ((error || {}).response || {}).data };
    });
}

export async function resetpassword(data) {
  return http
    .post('/auth/reset-password', data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log('Erreur :', error.response);
    });
}

export async function getUser(data) {
  //return http.put(`http://localhost:1337/users/${user._id}`, data)60d43e6b405a356a60337e2b
  return http
    .get(`/users/${user.id}`)
    .then((response) => {
      // Handle success.
      return response;
    })
    .catch((error) => {
      // Handle error.
      console.log('Erreur :', error.response);
    });
}
