import useSWR from 'swr';
import { useState, useEffect } from 'react';
import { getToken } from '../api/utils';

function useTokenFetch(url, shouldFetch = true, params = {}) {
  //

  const token = getToken();
  const [value, setValue] = useState();

  const { data, error } = useSWR(
    () => (shouldFetch ? [url, token] : null),
    fetcherWithTooken,
    {
      ...params,
    }
  );

  useEffect(() => {
    if (!error && data && !data.error) {
      if (!isEqual(value, data)) setValue(data);
    }

    return () => {};
    // 
  }, [data]);

  return value;
}

export default useTokenFetch;

const fetcherWithTooken = async (url, token) => {
  if(!url) return
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((res) => res.json());
};

const isEqual = (data, newData) => {
  return JSON.stringify(data) === JSON.stringify(newData);
};
