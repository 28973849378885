import React from 'react';

function useAuthenticated() {
  const token = localStorage.getItem('token');

  const isValidToken = () => token !== null;

  const [isAuth, setIsAuth] = React.useState(isValidToken());

  React.useEffect(() => {
    setIsAuth(isValidToken());

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return isAuth;
}

export default useAuthenticated;
