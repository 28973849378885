import React from 'react';
import { Row, Col } from 'antd';
import { NavHashLink } from 'react-router-hash-link';
import removeIcon from '../../../assets/icons/close-icon.svg';
import { getFileUrl } from '../../../utils';
import QuantitySetting from '../../../components/QuantitySetting';
import { getProductInfos } from '../../../components/Cart/utils';
import VariationDetails from '../../../components/Cart/VariationDetails';

export default function Card({ item, removeItem, canSetContent }) {
  //

  const { product, qte, variation } = item;

  const {
    name,
    image,
    price = 0,
    sale = price,
  } = getProductInfos(product, variation);

  const totalItem = qte * (sale === null ? price : sale);

  const showPrice = () => {
    if (
      sale !== undefined &&
      [null, price].findIndex((item) => item === sale) === -1
    ) {
      return (
        <>
          {sale}€
          {price && price !== null && price > 0 && <strike>{price}€</strike>}
        </>
      );
    }
    return <> Prix : {price} € </>;
  };

  return (
    <div className="command-card">
      <Row gutter={20}>
        <Col lg={6} md={8} xs={9} className="command-card__image-column">
          <NavHashLink to={{ pathname: `/produits/${product?.slug}` }}>
            <img
              src={getFileUrl(image)}
              alt={(image || {}).name}
              className="command-card__image"
            />
          </NavHashLink>
        </Col>
        <Col lg={18} md={16} xs={15}>
          <ul className="command-card__details">
            <li className="command-card__name">{name}</li>
            <li className="command-card__qte">{showPrice()}</li>

            {variation && <VariationDetails variation={variation} />}

            <QuantitySetting
              product={product}
              qte={qte}
              variation={variation}
            />
            <li className="command-card__price">
              {totalItem.toFixed(2).replace('.', ',').replace(',00', '')} €
            </li>
            <li className="command-card__remove">
              {canSetContent && (
                <buttton className="remove-button" onClick={removeItem}>
                  <img src={removeIcon} alt="close-icon.svg" />
                  <span className="remove-text">SUPPRIMER</span>
                </buttton>
              )}
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
