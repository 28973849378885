import React from 'react';
import './articleBlogCard.less';
import Ellipse_right_arrow from '../../../assets/images/ellipse-right-arrow.svg';
import Img from 'react-cool-img';

const ArticleBlogCard = ({ headerText, image, footerText }) => {
  return (
    <div className="article-blog-item">
      <div className="article-blog-header">
        <div className="article-blog-header-wrapper"> {headerText} </div>
        <Img
          className="ellipse-right-arrow"
          alt="blog"
          src={Ellipse_right_arrow}
        />
      </div>
      <div>
        <Img className="article-img" alt="blog" src={image} />
      </div>
      {footerText && <div className="article-text">{footerText}</div>}
    </div>
  );
};

export default ArticleBlogCard;
