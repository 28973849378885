import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, setAuthedAction } from '../../../stores/app/actions';
import image from '../../../assets/images/login.png';

import { notification } from 'antd';

import './style.less';

import { BASE_URL } from '../../../api/config';
import { mergeCart } from '../../../stores/cart/cartActions';
import { refreshWishlist } from '../../../stores/wishList/actions';
import SignInForm from './Form';
import {
  setLinkedAuthAction,
  signinAction,
} from '../../../stores/auth/actions';
import useVitrinFetcher2 from '../../../stores/vitrin/hooks2';
import { getFileUrl } from '../../../utils';
import { MODALPAGES } from '../../../stores/app/reducer';
import { nextUrlSelector } from '../../../stores/app/selector';

const Signin = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const url = useSelector(nextUrlSelector);

  const { signFormImage } =
    useVitrinFetcher2({
      endpoints: 'menu-config',
      name: 'menuData',
    }) || {};

  const [state, setState] = React.useState({
    keepalive: false,
    identifier: '',
    password: '',
    credError: false,
    loading: false,
  });

  const { loading } = state;

  const connectFb = () => {
    setTimeout(function () {
      window.location = BASE_URL + '/connect/facebook';
    }, 100);
  };

  const connectGo = () => {
    setTimeout(function () {
      window.location = BASE_URL + '/connect/google';
    }, 100);
  };

  const onForgotPassword = () => {
    setLinkedAuthAction({ nextAuthedLink: url, typeModal: MODALPAGES.NONE });

    dispatch(openModal({ type: MODALPAGES.NONE }));
    url && history.push('/reset-password');
  };

  const performSinginSuccess = async (data) => {
    setState({ ...state, loading: false });
    dispatch(setLinkedAuthAction());
    dispatch(openModal({ type: MODALPAGES.NONE }));
    dispatch(setAuthedAction(data));
    dispatch(mergeCart());
    dispatch(refreshWishlist());

    openNotification('Connexion éffectuée avec succès !');

    url && history.push(url);
  };

  const performSinginError = async (error) => {
    console.log({ error });
    setState({
      ...state,
      loading: false,
      error: 'Vos identifiants sont incorrects',
    });
  };

  const onSubmit = async (values) => {
    setState({ ...state, loading: true });

    const data = {
      identifier: values.email,
      password: values.password,
    };

    setState({ ...state, loading: true });

    await dispatch(
      signinAction(data, performSinginSuccess, performSinginError)
    );
  };

  const onNotSignin = () =>
    dispatch(openModal({ type: MODALPAGES.SIGINUP, nextUrl: url }));

  return (
    <div id="signin">
      <div>
        <img
          className="illustration-img"
          src={signFormImage ? getFileUrl(signFormImage) : image}
          alt=""
        />
      </div>
      <div className="content-form right-column">
        <p className="form-title">Se connecter</p>
        <SignInForm
          onConnectFb={connectFb}
          onConnectGo={connectGo}
          onNotSignin={onNotSignin}
          onSubmit={onSubmit}
          onForgotPassword={onForgotPassword}
          error={state.error}
          loading={loading}
        />
      </div>
    </div>
  );
};

/*TODO: Refactoring to make it global*/
const openNotification = (text) => {
  const args = {
    message: '',
    description: text,
    duration: 5,
  };
  notification.open(args);
};

export default Signin;
