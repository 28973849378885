import { MOBILE_SIZE } from '../../config';

import useWindowSize from '../../hooks/useWindowSize';
import useVitrinFetcher2 from '../../stores/vitrin/hooks2';
import LgHeader from './LgFooter';
import MobileHeader from './MobileFooter';

const Footer = () => {
  const screen = useWindowSize({ width: window.innerWidth });
  const isMobile = (screen.width || 0) < MOBILE_SIZE;

  const data =
    useVitrinFetcher2({
      endpoints: 'footer-config',
      name: 'footerData',
    }) || {};

  return isMobile ? <MobileHeader data={data} /> : <LgHeader data={data} />;
};

export default Footer;
