import { Col, Input, Row, Form } from 'antd';
import { NavHashLink } from 'react-router-hash-link';

import { EyeFilled } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { CONDITION_LINK } from '../../../Routes/links';
import AdsButton from '../../home/Button/AdsButton';

const SignupForm = ({
  onSubmit,
  onSignForm,
  error,
  loading,
  subscribe
}) => {
  //

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    subscribe && onSubmit && onSubmit(values,subscribe);
  };

  const initialValues = {
    keepalive: false,
    identifier: '',
    password: '',
    credError: false,
    loading: false,
    email: '',
  };

  const notSinginForm = (
    <div className="mobile-align-center">
      <a href="/" className="content-form-flatbtn-btn" onClick={onSignForm}>
        Vous avez déjà un compte? Se connecter
      </a>
    </div>
  );

  const subForm = (
    <>
      <Form.Item name="newsletter" valuePropName="checked" noStyle>
        <Checkbox className="content-form-savepwd">
          Inscrivez-vous à la newsletter pour bénéficier d'un accès exclusif et
          privilégié aux soldes, aux nouveaux arrivages et aux promotions.
        </Checkbox>
      </Form.Item>

      <Form.Item name="policy" rules={rules.policy} valuePropName="checked">
        <Checkbox className="content-form-savepwd">
          En vous inscrivant, vous acceptez les&nbsp;
          <span className="terms-condition">
            <NavHashLink to={`${CONDITION_LINK}#top`} target="_blank">
              termes et conditions et notre politique de confidentialité.
            </NavHashLink>
          </span>
        </Checkbox>
      </Form.Item>
    </>
  );

  return (
    <Form
      form={form}
      size="large"
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <Form.Item name="username" required rules={rules.name}>
        <Input className="form-input" placeholder="Nom" />
      </Form.Item>

      <Form.Item name="email" required rules={rules.email}>
        <Input className="form-input" placeholder="Email" />
      </Form.Item>

      <Form.Item name="password" required rules={rules.password}>
        <Input.Password
          className="form-input"
          placeholder="Mot de passe"
          suffix={<EyeFilled />}
        />
      </Form.Item>

      {subForm}

      <Row gutter={[0, 20]}>
        <Col sm={{ span: 16, order: 1 }} xs={{ span: 24, order: 1 }}>
          {error && (
            <div className="signin-error">
              <p className="signin-error__text mobile-align-center">{error} </p>
            </div>
          )}
        </Col>

        <Col sm={{ span: 8, order: 2 }} xs={{ span: 24, order: 2 }}>
          <Form.Item noStyle>
            <div className="content-form-button">
              <AdsButton
                htmlType="submit"
                className="submit-button signin-buton"
                id="adz-signup-button"
                loading={loading}
                text=" S'inscrire"
                sm
                isSubmitButton
              />
            </div>
          </Form.Item>
        </Col>

        <Col sm={{ span: 12, order: 3 }} xs={{ span: 24, order: 4 }}>
          {notSinginForm}
        </Col>

        <Col sm={{ span: 12, order: 4 }} xs={{ span: 24, order: 3 }}>
          {/* {socialButton} */}
        </Col>
      </Row>
    </Form>
  );
};

export default SignupForm;

const rules = {
  username: [
    {
      required: true,
      message: "Entrez un nom d'utilisateur!",
    },
    {
      min: 3,
      message: "Entrez un nom d'utilisateur!",
    },
  ],
  password: [
    {
      required: true,
      message: 'Veuillez entrer un mot de passe',
    },
  ],

  email: [
    {
      required: true,
      message: 'Veuillez entrer une adresse e-mail',
    },
    {
      type: 'email',
      message: 'Veuillez entrer une adresse e-mail valide',
    },
  ],

  policy: [
    {
      validator: (_, value) =>
        value
          ? Promise.resolve()
          : Promise.reject(
              new Error(
                'Vous devez accepter les termes et conditions de notre politique de confidentialité'
              )
            ),
    },
  ],
};
