import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import HTMLReactParser from 'html-react-parser';
import SectionWrapper from '../../components/SectionWrapper';

const IntroVideo = ({ data }) => {
  const { hashId } = data;

  return (
    <SectionWrapper hashId={hashId}>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div className="textIntro">
            {HTMLReactParser(`${data.leftDescription}`)}
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div className="textIntro">
            {HTMLReactParser(`${data.rigthDescription}`)}
          </div>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default IntroVideo;
