import ReactPlayer from 'react-player';
import './style.less';

const VideoPlayer = ({ url, title }) => {
  return (
    <div className="video-content-area">
      <ReactPlayer
        id="video1"
        url={url}
        title={title}
        volume={1}
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: { 
              controls: 2,
              showinfo: 0 
            },
          },
        }}
      />
    </div>
  );
};

export default VideoPlayer;
