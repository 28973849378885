import React from 'react';
import './style.less';
import HeaderH1TextItalic from '../../HeaderText/HeaderH1TextItalic';
import HeaderH1TextNormal from '../../HeaderText/HeaderH1TextNormal';
import ImgWithCaptionCard from '../../ImgWithCaptionCard/ImgWithCaptionCard';
import ProductAdvantageCard from './ProductAdvantageCard';
import { CONFIANCE_LINK } from '../../../../Routes/links';
import HTMLReactParser from 'html-react-parser';
import Slider from 'react-slick';

import ItemsNavigation from '../../ItemsNavigation/ItemsNavigation';
import { getFileUrl } from '../../../../utils';
import SectionWrapper from '../../../../components/SectionWrapper';

const AdvantagesSection = ({ section }) => {
  const { hashId } = section || {};

  let slider;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const moveNext = () => {
    slider.slickNext();
  };
  const movePrev = () => {
    slider.slickPrev();
  };

  return section && (
    <SectionWrapper hashId={hashId}>
      <div className="max-width-800-div avantages-area">
        <HeaderH1TextItalic
          text={section?.SEctionTitle?.normalTitle}
          isCenter={false}
          shouldBringUp={false}
          isRedColor={true}
        />
        <HeaderH1TextNormal
          text={section?.SEctionTitle?.italicTitle}
          isCenter={false}
          shouldBringUp={false}
        />
      </div>

      <div className="produit-advantage-wrapper">
        {section.listeAdvantage.map((ad,index) => {
          return (
            <ProductAdvantageCard
              key={index}
              advantageNum={ad.sectionTitle}
              text={HTMLReactParser(`${ad.sectionDescription}`)}
            />
          );
        })}
      </div>

      {section.sectionBeforeAfter?.length && <div className="avant-apres-img-wrapper">
        <Slider ref={(c) => (slider = c)} {...settings}>
          {section.sectionBeforeAfter.map((slide) => {
            return (
              <div key={slide._id}>
                <div className="avant-apres-img">
                  <ImgWithCaptionCard
                    image={getFileUrl(slide.desktop_media)}
                    caption="Avant"
                  />
                  <ImgWithCaptionCard
                    image={getFileUrl(slide.mobile_media)}
                    caption="Après"
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>}

      <div className="navigation-wrapper">
        <ItemsNavigation
          handleLeftArrow={movePrev}
          handleRightArrow={moveNext}
          showMore={true}
          href={CONFIANCE_LINK}
        />
      </div>
    </SectionWrapper>
  );
};

export default AdvantagesSection;
