import React from 'react';

import PaymentContent from './Content';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Spiner from '../../../components/Spiner';
import './style.less';
import '../style.less';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { cartSelector } from '../../../stores/cart/selector';
import * as cartActions from '../../../stores/cart/cartActions';
import * as orderActions from '../../../stores/order/actions';
import { CATEGORIES_LINK } from '../../../Routes/links';
import CheckoutBreadcrumb from '../CheckoutBreadcrumb';

const key = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(key);

const CheckoutPayment = ({
  cart,
  removeFromCart,
  submitOrder,
  clearCart,
  location,
  tempOrderToStore,
  buildOrderTotals,
  // tempOrder,
}) => {
  //

  const [tempOrder, setTempOrder] = React.useState();

  // const isAuth = useSelector(isAuthSelector);

  const history = useHistory();

  React.useEffect(() => {
    initializeOrder();
    document.body.scroll(0, 0);

    // 
  }, []);

  const initializeOrder = async () => {
    const temp = await tempOrderToStore();

    if (!temp) {
      return history.push(CATEGORIES_LINK);
    }

    const { shipping, billing, promoCode } = temp;

    const updatedOrder = await buildOrderTotals({
      shipping,
      promoCode,
      billing,
    });

    if (!updatedOrder) {
      console.log('cannot build order from server ');
    }

    setTempOrder({ ...temp, ...updatedOrder });
  };

  const canDisplay = () => {
    if (!tempOrder) return false;
    return true;
  };

  return (
    <Elements stripe={stripePromise}>
      <div id="checkout" className="container checkout">
        <div className="checkout-breadcrumb">
          <CheckoutBreadcrumb location={location} />
        </div>

        <div>
          {canDisplay() ? (
            <PaymentContent
              cart={cart}
              removeFromCart={removeFromCart}
              submitOrder={submitOrder}
              clearCart={clearCart}
              location={location}
              tempOrder={tempOrder}
            />
          ) : (
            <Spiner />
          )}
        </div>
      </div>
    </Elements>
  );
};

const Wrapper = connect(
  (state) => ({
    cart: cartSelector(state),
  }),

  (dispatch) => {
    return {
      removeFromCart: (val) => dispatch(cartActions.removeItemFromCart(val)),
      clearCart: (val) => dispatch(cartActions.clearCart()),
      submitOrder: (val) => dispatch(orderActions.submitOrder(val)),
      tempOrderToStore: (val) => dispatch(orderActions.tempOrderToStore(val)),
      buildOrderTotals: (val) => dispatch(orderActions.buildOrderTotals(val)),
    };
  }
);

export default Wrapper(CheckoutPayment);
