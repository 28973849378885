import { Col, Row } from 'antd';
import closeIcon from '../../../src/assets/icons/close-icon.svg';

import './style.less';

export default function Header({ onClose }) {
  return (
    <Row className="cart__header">
      <Col span={10} className="cart__title">
        Panier
      </Col>

      <Col span={10} className="cart__button-div">
        <button
          onClick={() => {
            onClose && onClose();
          }}
          className="cart__close-button"
        >
          <img
            className="cart__close-icon"
            src={closeIcon}
            alt="close-icon.svg"
          />
        </button>
      </Col>
    </Row>
  );
}
