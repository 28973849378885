import React, { useState, useEffect } from 'react';
import Spiner from '../../components/Spiner';
import { ORDER_CONFIRM_LINK } from '../../Routes/links';
import { getOrderById } from '../../stores/order/api';
import Content from './Content';
import './style.less';

const OrderDetail = ({ match, computedMatch }) => {
  const [state, setState] = useState({ isNewOrder: false });

  const { data, isNewOrder } = state;

  useEffect(() => {
    intitState();
    // 
  }, [match, computedMatch]);

  const intitState = async () => {
    const { params, path } = match || computedMatch;

    const isNewOrder = path === ORDER_CONFIRM_LINK;

    let id = params.id;

    try {
      let results = await getOrderById(id);
      setState({ ...state, data: results, isNewOrder });

      //
    } catch (error) {
      console.log({ error });
    }
  };

  if (!data) return <Spiner />;

  return (
    <div id="achat">
      <Content data={state.data} isNewOrder={isNewOrder} />
    </div>
  );
};

export default OrderDetail;
