import './style.less';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HeaderH3TextNormal from '../../HeaderText/HeaderH3TextNormal';
import ProductCard from '../../ProductCard/ProductCard';

import ItemsNavigation from '../../ItemsNavigation/ItemsNavigation';
import Slider from 'react-slick';
import SectionWrapper from '../../../../components/SectionWrapper';

const ProductsSection = ({
  data = [],
  showMoreButton = true,
  showSectionTitle = true,
  hashId,
  title = 'NOS INCONTOURNABLES',
}) => {
  //

  let slider;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const moveNext = () => {
    slider.slickNext();
  };
  const movePrev = () => {
    slider.slickPrev();
  };

  const productCards = (dat) => {
    return data.map((article, index) => {
      return (
        <div key={index}>
          <ProductCard product={article} showPrice={false} />
        </div>
      );
    });
  };

  if (!data || data === null) return;

  return (
    <SectionWrapper hashId={hashId}>
      {showSectionTitle && (
        <HeaderH3TextNormal text={title} customStyle={{ marginTop: 40 }} />
      )}

      <div className="produits-container">
        <Slider ref={(c) => (slider = c)} {...settings}>
          {productCards(data)}
        </Slider>
      </div>

      {showMoreButton === true && (
        <ItemsNavigation
          handleLeftArrow={movePrev}
          handleRightArrow={moveNext}
          href="/produits#top"
          textButton="Je découvre"
        />
      )}
    </SectionWrapper>
  );
};

export default ProductsSection;
